import { FileDownload } from '@cais-group/equity/labs/file-download'
import { Modal, useModal } from '@cais-group/equity/organisms/modal'
import { TermsPdf } from '@cais-group/shared/domain/contentful/legal'

export const TermsAndConditionsModal = (props: {
  modal: ReturnType<typeof useModal>
  onDecline: () => void
  onAccept: () => void
  pdf: TermsPdf | null
}) => {
  const { modal, onDecline, onAccept, pdf } = props

  return (
    <Modal
      isDismissible={false}
      control={modal}
      onClose={onDecline}
      actions={[
        {
          onClick: onDecline,
          children: 'I do not agree',
          variant: 'secondary',
        },
        {
          onClick: onAccept,
          children: 'I agree',
          variant: 'primary',
        },
      ]}
      title="Terms and Conditions of Use"
      transparentBackdrop
    >
      <div
        tabIndex={0}
        className="body space-y-16 pl-0 pr-16 text-neutral-600 outline-0"
      >
        <p>
          By clicking "I agree" upon your first entry to the platform, you
          acknowledge that you have read, understood and agree that:
        </p>
        <ul className="list-disc space-y-8 pl-24">
          <li>
            The CAIS platform is available only to persons who are investment
            professionals and may not be accessed for any retail customer use
            whatsoever; you are either a registered representative of a FINRA
            member broker-dealer, a professional investment adviser registered
            with the Securities Exchange Commission under Section 203 of the
            Investment Advisers Act of 1940 (or with a state securities
            commission or any agency or office performing like functions), or
            the representative of another category of "institutional account" as
            described in FINRA Rule 4512(c); access to the platform is being
            provided to you in reliance on this confirmation and the continuance
            of this status and you agree to notify us and cease using the
            platform should that status change;
          </li>
          <li>
            Access to portions of the CAIS platform is restricted and requires
            possession of a valid password or entry from an authorized
            password-protected collaborating site; you shall preserve the
            confidentiality of your user name and password and shall not
            facilitate entry of any unauthorized person to the platform; you
            shall contact CAIS immediately if you believe that an unauthorized
            person has obtained access to your password or the platform; CAIS
            reserves all rights in its sole discretion to change, restrict,
            suspend or terminate access or use of the platform at any time
            without prior notice or liability;
          </li>
          <li>
            You will have access to confidential information concerning private
            securities products, as well as regarding CAIS, its affiliates,
            securities product providers and managers on the platform, and
            associated vendors (together, and together with CAIS and these other
            parties respective affiliates, officers, directors, interest
            holders, employees and agents, "CAIS Parties"); this information may
            include, without limitation, the names of managers and products on
            the platform, performance data, portfolio information and
            valuations, financial information, structural information,
            investment techniques, trade secrets and other confidential
            proprietary information (collectively, "Confidential Information").
            You agree that you shall at all times keep, and shall not, directly
            or indirectly, disclose, divulge, furnish or make accessible to
            anyone, especially any person that does not meet the threshold
            requirements of FINRA Rule 4512(c), any Confidential Information,
            except in the case of (i) your bona fide provision of investment
            services solely to those of your clients that you reasonably believe
            are qualified to invest in the relevant products, (ii) information
            that is otherwise publicly available, or (iii) information required
            to be disclosed by law;
          </li>
          <li>
            Neither you nor any party acting on your behalf, or in concert with
            you, will offer any private securities product available on the
            platform by any form of general solicitation or general advertising,
            as provided in Rule 502(c) of Regulation D promulgated under the
            U.S. Securities Act of 1933, as amended, or engage in any
            solicitation or other activity in violation of any statute, law,
            ordinance, rule, public administrative interpretation, regulation,
            order, writ, injunction, directive, judgment, decree or other
            requirement of any governmental authority;
          </li>
          <li>
            You agree that prior to any investment being facilitated, you shall
            deliver, or cause to be delivered, a copy of the relevant investment
            materials and/or offering materials to each prospective investor
            with respect to to which an offer of investment is made;
          </li>
          <li>
            You agree that, prior to providing private product materials to a
            prospective investor that is a “U.S. Person,” as defined in Rule
            902(k) promulgated under the Securities Act, you shall have a
            reasonable belief that each such prospective Investor is eligible to
            receive such offer and make such private investment, including but
            not limited that such prospective Investor is an “accredited
            investor,” as such term is defined in Regulation D promulgated under
            the Securities Act or, if required to be eligible to invest in such
            Private Product, is a “qualified purchaser,” as such term is defined
            under Section 2(a)(51) of the U.S. Investment Company Act of 1940,
            as amended, and the rules and regulations promulgated thereunder;
          </li>
          <li>
            You agree that, prior to providing any product materials to a
            prospective investor you shall have a reasonable belief that (1)
            such prospective investor is eligible to receive such product
            materials and otherwise to be offered such product in the
            jurisdiction in which such product materials are provided to such
            prospective investor; (3) is eligible to purchase such product in
            the jurisdiction in which you reasonably expect, after due inquiry,
            that such prospective Investor may purchase such product; and (4)
            has sufficient financial knowledge and experience to be capable of
            evaluating the risks and merits of an investment in such product;
          </li>
          <li>
            You agree to provide prospective investors any amendments,
            supplements or revisions to the investment materials initially
            provided by you to such prospective Investor as promptly as
            practicable after receipt thereof through the portal or otherwise;
          </li>
          <li>
            You represent that you have obtained any and all registrations,
            licenses and governmental approvals required to be obtained by it in
            order to perform you obligations as an investment professional, and
            undertakes to maintain such registrations, licenses and governmental
            approvals in full force and effect for the term of you ruse of this
            portal, and to conduct your activities under this Agreement in
            compliance with all law applicable to such activities;
          </li>
          <li>
            You acknowledge and agree that you will not, and will not authorize
            any agent, sub-agent or representative, to make any representations,
            whether written or oral, regarding any investment that are
            inconsistent with the relevant investment materials;
          </li>
          <li>
            You agree that in engaging in any activities in connection with this
            portal you shall comply in all respects with all applicable law and
            shall not take any action that would place any CAIS person or any
            prospective or current Investor in violation of any applicable law;
          </li>
          <li>
            You agree to engage in any activities in connection with this portal
            solely in accordance with any reasonable additional restrictions
            requested by CAIS or CAIS Capital;
          </li>
          <li>
            You are aware of and shall at all relevant times comply with, in
            connection with the offering of investments, law imposed by the U.S.
            Treasury Department&#8217;s Office of Foreign Assets Control,
            including the Uniting and Strengthening America by Providing
            Appropriate Tools Required to Intercept and Obstruct Terrorism (USA
            PATRIOT ACT) Act of 2001, which prohibits, among other things, the
            engagement in transactions with and the provision of services to
            certain embargoed non-U.S. countries and specially designated
            nationals, specially designated narcotics traffickers, terrorist
            sanctions and other blocked parties. With respect to the foregoing,
            you agree to provide, from time to time, as reasonably requested by
            CAIS, such information (to the extent reasonably available to you)
            to enable CAIS or other service provider to an issuer, vehicle,
            sponsor or manager of an investment product, to comply with such
            Person&#8217;s obligations with respect to applicable anti-money
            laundering Laws;
          </li>
          <li>
            The information provided on the platform is for informational
            purposes only and does not constitute a solicitation or offer or an
            invitation to make an offer to buy or sell any securities products;
            neither CAIS nor any of its affiliates are a fiduciary or advisor
            with respect to any financial services or products and neither CAIS
            nor any of its affiliates provides investment planning, legal,
            regulatory, tax or compliance advice; no information on the platform
            shall be construed as a recommendation, endorsement, or sponsorship
            by any CAIS Party unless otherwise expressly stated; no CAIS Party
            represents that the securities or services described on the platform
            are suitable for any specific investor;
          </li>
          <li>
            Investment professionals using the platform must independently
            ascertain the legal, financial, tax and regulatory consequences of
            any investments they consider, as they can involve significant risks
            and no assurance can be given that investment objectives of any
            product will be achieved or that substantial losses will not be
            suffered; past performance is not indicative of future results;
          </li>
          <li>
            The platform is not directed at, nor intended for, any party
            prohibited from accessing it by laws and regulations prohibiting
            publication or access to the information it contains by virtue of
            such party&#8217;s nationality, place of residence, domicile or
            registered office, or on other grounds; users who access the
            platform from outside of the United States of America do so at their
            own initiative and risk and are responsible for compliance with all
            applicable laws;
          </li>
          <li>
            The platform and its content, including but not limited to
            information, text, data, news, reports, graphics, video, software,
            logos, and other materials, including third party materials
            ("Content") are protected by copyrights, trademarks, trade secret,
            patent and other proprietary rights. You acknowledge and agree that
            (i) these rights are valid and protected in all forms, media, and
            technologies existing now and hereinafter developed, and that all
            Content is and shall remain the property of CAIS or its licensors,
            (ii) you shall not encumber any interest in, or assert any rights
            to, the Content, and (iii) you may not modify, transmit, participate
            in the sale or transfer of, or create derivative works based on any
            Content, in whole or in part, (iv) you may not establish a link to
            the platform from any other website without our prior written
            consent, and (v) you may not frame any elements of the platform with
            any other website;
          </li>
          <li>
            Platform links to third party websites or advertisements do not
            constitute endorsement or adoption of the content or services in
            those sites or from such parties and your access of those links or
            dealings with such parties are at your own risk; no CAIS Party shall
            be responsible or liable for the statements or conduct of any third
            party, nor for any loss or damage incurred as a result of any such
            dealings or the presence of such links or advertisements on the
            platform;
          </li>
          <li>
            Google Analytics Demographics and Interests Reporting features have
            been implemented on the platform. These features will be used to
            review anonymous data regarding the gender, age and interests of the
            platform visitors to better develop the platform and its content
            around your needs. You may opt-out of the Google Analytics
            Demographics and Interests Reporting feature by using the Google Ads
            Settings or through the Google Analytics Opt-out Tool;
          </li>

          <li>
            Google Analytics User ID Tracking Reporting features have been
            implemented on the platform. These features will allow the platform
            to identify and review interactions per user to better develop the
            platform and content around your needs. Google Analytics will not
            receive any other information other than your user id. Your user id
            will consist of a random set of numbers assigned to your account.
            You may opt-out of the Google Analytics Demographics and Interests
            Reporting feature by using the Google Ads Settings or through the
            Google Analytics Opt-out Tool;
          </li>
          <li>
            The platform may engage a third party to process and/or to analyze
            your activities on the platform. Such third party&#8217;s authorized
            use of cookies and other tracking technologies enable it to have
            access to some of your personal information provided on the
            platform, however all sensitive data will remain private. This
            feature may be used to review activity on the platform and to
            develop the platform and content around your needs. Such access and
            use of personal information by any such third party is governed by
            such third party&#8217;s privacy policy, which can be provided upon
            request;
          </li>
          <li>
            Google AdWords features have been implemented on the platform. The
            platform uses Google AdWords to collect data about your activities
            that does not personally or directly identify you when you visit the
            platform, or websites and online services where we display
            advertisements. This information may include the content you view,
            the date and time that you view this content, or your location
            information associated with your IP address. The Platform will use
            the information collected to serve you relevant CAIS advertisements
            in 3rd party sites that use Google AdWords.
          </li>
          <li>
            The platform is provided on an "as is" and "as available" basis and
            no CAIS Party makes any warranty, express or implied or otherwise
            regarding its accessibility, accuracy, completeness, timeliness,
            suitability or merchantability and fitness for any purpose; to the
            full extent permissible by law, no CAIS Party shall have any
            liability or responsibility relating to use of the platform, or any
            errors or omissions in, or decisions made in reliance on, any aspect
            of the platform; and these terms and conditions and your use of the
            platform shall be governed by the laws of the State of New York
            without regard to the conflict of law provisions thereof; in
            connection with your use of the platform, you shall comply with all
            applicable law and shall not take any action that would place any
            CAIS Party in violation of any applicable law.
          </li>
        </ul>

        <p className="pt-16">
          Please download and print this agreement for your records.
        </p>

        <p className="relative pb-16 pt-16">
          <FileDownload
            source={pdf?.source ?? ''}
            title={pdf?.title ?? ''}
            fileType="PDF"
          />
        </p>
      </div>
    </Modal>
  )
}
