import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields } from './common'

const externalLinkClicked = (
  screen: string,
  linkText: string,
  linkUrl: string
) => {
  trackingService.track(trackIds.externalLinkClicked, {
    ...commonFields(),
    screen,
    link_text: linkText,
    link_url: linkUrl,
  })
}

export const linkTracking = {
  externalLinkClicked,
}
