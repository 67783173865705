import type { LinkProps } from '@cais-group/equity/atoms/link'
import {
  HOMEPAGE_SITE_CONFIG as SITE_CONFIG,
  type CTALink,
  type PageTypes,
} from '@cais-group/shared/util/contentful/types'

export function prepareLinkProps(
  appUrl: string,
  link?: CTALink
): (LinkProps & { isBookmark?: boolean; text: string }) | undefined {
  if (!link) return undefined

  let href = appUrl
  const cmsLink = link?.cmsLink
  const internalLink = link?.internalLink
  const externalLink = link?.externalLink
  let isBookmark

  if (cmsLink?.__typename) {
    const pageType = cmsLink.__typename as PageTypes
    const basePath = SITE_CONFIG.pages[pageType].path
    href = `${href}${basePath}/${cmsLink.slug}`
  } else if (internalLink) {
    isBookmark = Boolean(internalLink.startsWith('#'))
    href = isBookmark ? internalLink : `${href}${internalLink}`
  } else if (externalLink) {
    href = externalLink
  }

  return {
    href: href.startsWith('//') ? href.replace('//', '/') : href,
    openInNewWindow: Boolean(link?.openInNewWindow),
    text: link?.text || '',
    ...(isBookmark && { isBookmark }),
  }
}
