import type { PropsWithChildren } from 'react'

import { useOnClickOutside } from '@cais-group/equity/utils/event'

import { ExpandedNodeModal } from './components/expanded-node-modal'
import { NonExpandedNode } from './components/non-expanded-node'
import { useLightbox } from './use-lightbox'

/**
 * @description
 * Lightbox component that wraps around the content that needs to be displayed in a lightbox.
 * The lightbox can be expanded to full screen and collapsed back to its original size.
 * This will eventually be extended and moved to Equity.
 */
export type LightboxProps = {
  children: PropsWithChildren<React.ReactNode>
  control: ReturnType<typeof useLightbox>
  width: number
  alignment?: 'center'
  expandedOk?: boolean
  isLoading?: boolean
  resizeRef?: (node: HTMLDivElement) => void
  title?: React.ReactNode
}

export function Lightbox(props: LightboxProps) {
  const { expandedOk = true, control } = props

  useOnClickOutside<HTMLDivElement>({
    callback: () => {
      if (control.isOpen) {
        control.closeLightbox()
      }
    },
    ref: control.lightboxRef as unknown as React.MutableRefObject<HTMLDivElement>,
  })

  return expandedOk && control.isOpen ? (
    <ExpandedNodeModal {...props} control={control} />
  ) : (
    <NonExpandedNode {...props} control={control} />
  )
}

export default Lightbox
