import { omitBy, isNil } from 'lodash-es'

import { trackIds } from '@cais-group/caisiq/domain/segment'
import { experienceService } from '@cais-group/caisiq/util/experience-service'

import { trackingService } from '..'

import { commonFields, getPageFromLocation, appName } from './common'

const getUtmTagsFromQueryParams = (params: URLSearchParams) => {
  // Omit tags that are undefined or nil.
  return omitBy(
    {
      utm_campaign: params.get('utm_campaign'),
      utm_content: params.get('utm_content'),
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_adgroup: params.get('utm_adgroup'),
      utm_term: params.get('utm_term'),
    },
    isNil
  )
}
const signUpFreeClicked = () => {
  trackingService.track(trackIds.signUpFreeClicked, {
    ...commonFields(),
    screen: getPageFromLocation(window.location),
  })
}

const signUpRedirect = (params: URLSearchParams) => {
  trackingService.track(trackIds.redirectToSignup, {
    app: appName,
    experience_name: experienceService.getExperience(),
    ...getUtmTagsFromQueryParams(params),
  })
}

export const signupTracking = {
  signUpFreeClicked,
  signUpRedirect,
}
