import {
  createSerialisedExpirableValue,
  readSerialisedExpirableValue,
} from '@cais-group/caisiq/util/expirable-session'
import { User } from '@cais-group/shared/util/type/caisiq-be'
import { Experience } from '@cais-group/shared/util/type/experience'

const SESSION_KEY_FIRM = 'experienceService.firm'
const SESSION_KEY_OVERRIDE = 'experienceService.override'
const SESSION_KEY_EXPERIENCE = 'experienceService.experience'

let serviceExperience: Experience | undefined

const DIRECT_EXPERIENCES = [
  Experience.CAIS_IQ_DIRECT,
  Experience.PERSHING_DIRECT,
]

const EXPERIENCES_WITH_CUSTOM_LOGIN = [Experience.CAIS_IQ_DIRECT]

const EXPERIENCES_WITH_IDP_INITIATED_SSO = [Experience.PERSHING_DIRECT]

export type AuthType = 'direct' | 'member' | undefined

const getExperience = () => {
  if (serviceExperience === undefined) {
    _setFromUrl()
  }
  if (serviceExperience === undefined) {
    _setFromSession()
  }
  return serviceExperience
}

const setIsOverridden = (overridden: boolean) =>
  sessionStorage.setItem(SESSION_KEY_OVERRIDE, JSON.stringify(overridden))

const isOverridden = () =>
  sessionStorage &&
  JSON.parse(sessionStorage.getItem(SESSION_KEY_OVERRIDE) ?? 'false')

const getExperienceAuth = (): AuthType =>
  !isOverridden() && DIRECT_EXPERIENCES.includes(getExperience() as Experience)
    ? 'direct'
    : 'member'

const isDirect = () => getExperienceAuth() === 'direct'

const hasCustomLogin = () =>
  EXPERIENCES_WITH_CUSTOM_LOGIN.includes(getExperience() as Experience)

/**
 * Two ways that we can set from the URL
 */
const _setFromUrl = () => {
  // Path
  const basepath = window.location.pathname.split('/')
  if (DIRECT_EXPERIENCES.includes(basepath[1] as Experience)) {
    setExperience(basepath[1] as Experience)
  }

  // Params
  const params = new URLSearchParams(window.location.search)
  if (params.has('experience')) {
    setExperience(params.get('experience') as Experience)
  }
}

const setExperience = (experience: Experience) => {
  serviceExperience = experience
  if (sessionStorage && serviceExperience) {
    sessionStorage.setItem(
      SESSION_KEY_EXPERIENCE,
      createSerialisedExpirableValue(serviceExperience, 8 * 60) // 8 hour expiry
    )
  }
}

const _setFromSession = () => {
  if (sessionStorage) {
    const rawKey = sessionStorage.getItem(SESSION_KEY_EXPERIENCE)
    const sessionExperience = rawKey
      ? readSerialisedExpirableValue<string>(rawKey)
      : undefined
    if (sessionExperience !== undefined) {
      setExperience(sessionExperience as Experience)
    }
  }
}

const reset = () => {
  serviceExperience = undefined
  sessionStorage.removeItem(SESSION_KEY_FIRM)
  sessionStorage.removeItem(SESSION_KEY_EXPERIENCE)
}

const setUser = async (user: User) => {
  // Save the firm
  if (sessionStorage && user.firm) {
    sessionStorage.setItem(SESSION_KEY_FIRM, user.firm)
  }

  // Save whether user is overrideing experience
  setIsOverridden(user.experience.overridden || false)
  setExperience(
    (user.experience.label
      ? user.experience.label
      : Experience.UNSPECIFIED) as Experience
  )
}

const isExperienceOverride = () => {
  const experience = getExperience()

  // If experience is not set then it cannot be overridden
  if (Boolean(experience) && experience !== Experience.UNSPECIFIED) {
    return true
  }

  return false
}

/**
 * @todo: This is currently determined by whether the experience is a IdP initiated
 * SSO, from array EXPERIENCES_WITH_IDP_INITIATED_SSO. We may well need to support
 * custom logout links in the future, but these should be retireved from the BE
 */
const hasLogout = () => {
  return !EXPERIENCES_WITH_IDP_INITIATED_SSO.includes(
    getExperience() as Experience
  )
}

export const experienceService = {
  getExperience,
  getExperienceAuth,
  setExperience,
  isDirect,
  hasCustomLogin,
  hasLogout,
  reset,
  setUser,
  isExperienceOverride,
}
