import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields, getPageFromLocation } from './common'

export interface CeCredentialEnteredPayload {
  credentialType: 'cima' | 'cfp'
  credentialValue: string
}

export interface CeCredentialsPayload {
  cfpNumber?: string
  cimaNumber?: string
  cfpNumberOnFile: string
  cimaNumberOnFile: string
}

const ceInterstitialsPageViewed = () => {
  trackingService.page(trackIds.ceInterstitialsPageViewed, {
    ...commonFields(),
  })
}

const ceContinuingEducationLinkClicked = () => {
  trackingService.track(trackIds.ceContinuingEducationLinkClicked, {
    ...commonFields(),
  })
}

const ceEnterCredentialNumbersLinkClicked = () => {
  trackingService.track(trackIds.ceEnterCredentialNumbersLinkClicked, {
    ...commonFields(),
  })
}

const ceLearnMoreAboutCeCreditsLinkClicked = () => {
  trackingService.track(trackIds.ceLearnMoreAboutCeCreditsLinkClicked, {
    ...commonFields(),
  })
}

const ceCredentialsEntered = (payload: CeCredentialEnteredPayload) => {
  trackingService.track(trackIds.ceCredentialsEntered, {
    ...commonFields(),
    credential_type: payload.credentialType,
    credential_value: payload.credentialValue,
  })
}

const ceCredentialsSubmitted = (payload: CeCredentialsPayload) => {
  trackingService.track(trackIds.ceCredentialsSubmitted, {
    ...commonFields(),
    cfp_number_submitted: payload.cfpNumber,
    cima_number_submitted: payload.cimaNumber,
    cfp_number_on_file: payload?.cfpNumberOnFile,
    cima_number_on_file: payload?.cimaNumberOnFile,
  })
}

const ceCredentialsAbandoned = (
  payload?: CeCredentialsPayload,
  primaryTabOverride?: Location
) => {
  trackingService.track(trackIds.ceCredentialsAbandoned, {
    ...commonFields(),
    ...(primaryTabOverride && {
      primary_tab: getPageFromLocation(primaryTabOverride),
    }),
    cfp_number_abandoned: payload?.cfpNumber,
    cima_number_abandoned: payload?.cimaNumber,
    cfp_number_on_file: payload?.cfpNumberOnFile,
    cima_number_on_file: payload?.cimaNumberOnFile,
  })
}

export const ceTracking = {
  ceInterstitialsPageViewed,
  ceContinuingEducationLinkClicked,
  ceEnterCredentialNumbersLinkClicked,
  ceLearnMoreAboutCeCreditsLinkClicked,
  ceCredentialsEntered,
  ceCredentialsSubmitted,
  ceCredentialsAbandoned,
}
