import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields } from './common'

const scheduleDownloadClicked = () => {
  trackingService.track(trackIds.scheduleDownloadClicked, {
    ...commonFields(),
  })
}

const presenterClicked = (presenterName: string) => {
  trackingService.track(trackIds.presenterClicked, {
    ...commonFields(),
    presenter_name: presenterName,
  })
}

const presenterModalVideoClicked = (
  videoTitle: string,
  presenterName: string
) => {
  trackingService.track(trackIds.presenterModalVideoClicked, {
    ...commonFields(),
    presenter_name: presenterName,
    video_title: videoTitle,
  })
}

export const conferenceTracking = {
  scheduleDownloadClicked,
  presenterClicked,
  presenterModalVideoClicked,
}
