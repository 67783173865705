import { components } from './generated'

export const UserRole = {
  CaisiqAdmin: 'portal:ROLE_CAISIQ_ADMIN',
  CaisiqReporting: 'caisiq:reporting:read',
  CaisiqManageWrite: 'caisiq:manage:write',
  CaisiqManageRead: 'caisiq:manage:read',
} as const

export type TUserRole = typeof UserRole[keyof typeof UserRole]

export type UserExperience = components['schemas']['UserExperience']

export type UserFeatures = components['schemas']['UserProfileFeature']

export type User = components['schemas']['Me']

export type UserCapabilities = components['schemas']['Capabilities']

export type Role = components['schemas']['Role']

export type AlmToken = components['schemas']['AccessToken']
