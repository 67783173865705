import { useNavigate } from 'react-router-dom'

import { Button } from '@cais-group/equity/atoms/button'
import { AttentionBox } from '@cais-group/equity/molecules/attention-box'

export const getRedirectOnNotFoundRouterPath = (friendlyAppName: string) => ({
  path: '*',
  Component: () => {
    if (__NX_DEVELOPMENT__) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const navigate = useNavigate()

      return (
        <div className="p-56">
          <AttentionBox
            title="This app is running in standalone mode"
            type="warning"
          >
            <div className="flex max-w-[80ch] flex-col gap-16 p-8">
              <p className="body">
                This route is not handled by{' '}
                <span className="whitespace-nowrap font-bold">
                  {friendlyAppName}
                </span>
                .{' '}
                <span className="italic">
                  Production apps will perform a hard reload.
                </span>
              </p>
              <p className="body">
                If you want to test multiple apps locally, you should use the
                container app as a reverse proxy.
              </p>
              <Button
                color="primary"
                size="regular"
                variant="tertiary"
                onClick={() => navigate(-1)}
              >
                Go back to {friendlyAppName}
              </Button>
            </div>
          </AttentionBox>
        </div>
      )
    }

    globalThis.location.assign(globalThis.location.href)

    return <p>Redirecting to {globalThis.location.pathname}</p>
  },
})
