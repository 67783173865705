import { RichTextBase } from '@cais-group/shared/ui/contentful/rich-text-base'
import type { RichTextOptionalProps } from '@cais-group/shared/ui/contentful/rich-text-base'

import { richTextRenderOptions } from './rich-text-render-options'
/**
 * Optional overrides can be passed to the RichText component to customize the rendering of specific HTML tags by including custom props.
 * if a customTag is provided, it will be used to render that type of HTML Element instead of the overriden HTML tags .
 * if a component is provided, it will be used to render the renderNode instead of the default renderNode for the the given HTML tag.
 */
export function RichText(props: RichTextOptionalProps) {
  const { renderOptions, ...rest } = props
  return (
    <RichTextBase
      renderOptions={renderOptions || richTextRenderOptions}
      {...rest}
    />
  )
}
