import { useEffect } from 'react'

import { APP_IDS } from '@cais-group/shared/domain/apps'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'

import { userContentPermissionsService } from '../content-permissions.service'
import { useFundProductsAdvisorReadPermission } from '../hooks'

export const checkShouldFetchPermissions = (appId: `${APP_IDS}`) => {
  return appId === APP_IDS.HOMEPAGE
}

type ConfigType = {
  shouldFetchPermissions: boolean
}

export const UserContentPermissionsManager = ({
  config,
}: {
  config: ConfigType
}) => {
  const userProfile = useUserProfile()
  const { hasFundProductsAdvisorReadPermission } =
    useFundProductsAdvisorReadPermission(userProfile)

  useEffect(() => {
    if (userProfile.userProfileState.status === 'loaded') {
      // prevent ids-names from being called outside of homepage as it is not needed
      userContentPermissionsService.init(
        userProfile,
        hasFundProductsAdvisorReadPermission && config.shouldFetchPermissions
      )
    }

    return () => {
      userContentPermissionsService.cleanup()
    }
  }, [
    config.shouldFetchPermissions,
    hasFundProductsAdvisorReadPermission,
    userProfile,
  ])

  return null
}
