import { EventProperties } from '@segment/analytics-next'

import { TrackingId } from '@cais-group/caisiq/domain/segment'
import { userSettingsService } from '@cais-group/caisiq/util/user-settings-service'

import { segment } from '../events/common'

type SegmentAnalyticsType = (E: TrackingId, P: EventProperties) => void

const track: SegmentAnalyticsType = (eventName, properties) => {
  if (userSettingsService.user.switchedUser) {
    return
  }
  segment.track(eventName, properties)
}

const page: SegmentAnalyticsType = (eventName, properties) => {
  if (userSettingsService.user.switchedUser) {
    return
  }
  segment.page(eventName, properties)
}

export const segmentWrappers = { page, track }
