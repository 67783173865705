import { useEffect } from 'react'

import { RoleV1, UserProfileV1 } from '@cais-group/access-manager/domain/api'

const MAX_RETRIES = 10

export const usePendo = (
  userProfile?: UserProfileV1 | null,
  userRoles?: RoleV1[] | undefined
) => {
  useEffect(() => {
    if (window.IS_PLAYWRIGHT === true) return

    const visitorId = userProfile?.id

    if (visitorId && userRoles && !userProfile.isSwitched) {
      const firmId = userProfile?.firm?.id
      const firmName = userProfile?.firm?.name
      const firstName = userProfile?.firstName
      const lastName = userProfile?.lastName
      const email = userProfile?.emailAddress

      // Break apart the role name and archetype into two Sets
      // A Set is chosen to easily deduplicate strings.
      const [roles, archetypes] = userRoles.reduce(
        ([roles, archetypes], role) => {
          return [roles.add(role.name), archetypes.add(role.archetype)]
        },
        [new Set<string>(), new Set<string>()]
      )

      const pendoInitConfig = {
        visitor: {
          id: visitorId, // Required if user is logged in
          full_name: `${firstName} ${lastName}`, // Recommended if using Pendo Feedback
          email: email || '', // Recommended if using Pendo Feedback, or NPS Email
          roles: Array.from(roles),
          archetypes: Array.from(archetypes),

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
          name: firmName || 'CAIS Employee',
        },
        account: {
          id: firmId || 'CAIS Employee',
          name: firmName || 'CAIS Employee',
          is_paying: true,
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      }

      if (window.pendo) {
        window.pendo.initialize(pendoInitConfig)
      } else {
        let retries = 0
        const interval = setInterval(() => {
          if (retries > MAX_RETRIES) {
            clearInterval(interval)
          } else {
            retries = retries + 1
            if (window.pendo) {
              window.pendo.initialize(pendoInitConfig)
              clearInterval(interval)
            }
          }
        }, 1000)
      }
    }
  }, [userProfile, userRoles])
}
