import {
  getTime,
  publishedDateFormat,
} from '@cais-group/shared/util/time/date-time-contentful'

export function DateTime(props: {
  date?: string
  includeTime?: boolean
  timezone?: string
}) {
  const { date, includeTime, timezone = '' } = props
  if (!date) {
    return null
  }
  return (
    <time className="small inline-block text-neutral-600" dateTime={date}>
      {publishedDateFormat(date)}
      {includeTime ? ` • ${getTime(date)} ${timezone}` : ''}
    </time>
  )
}
