interface Expirable<T = unknown> {
  value: T
  expires: string
}

/**
 * Use to create a value which will expire, returning undefined
 * @param v Expirable value
 * @param expirationInMin
 */
export const createSerialisedExpirableValue = (
  v: unknown,
  expiresInMin = 15
): string =>
  JSON.stringify({
    value: v,
    expires: new Date(
      new Date().getTime() + 60000 * expiresInMin
    ).toISOString(),
  })

export const readSerialisedExpirableValue = <T = unknown>(
  serialised: string
): T | undefined => {
  try {
    const expirable = JSON.parse(serialised)

    if (!isExpirable<T>(expirable) || isExpired(expirable)) {
      return undefined
    }
    return expirable.value
  } catch (e) {
    return undefined
  }
}

const isExpirable = <T>(e: Expirable<T>): e is Expirable<T> =>
  e instanceof Object &&
  Object.prototype.hasOwnProperty.call(e, 'value') &&
  Object.prototype.hasOwnProperty.call(e, 'expires')

const isExpired = (expirable: Expirable) =>
  new Date(expirable.expires) < new Date()
