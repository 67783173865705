import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'
import { ContentfulLightboxWrapper } from '@cais-group/shared/ui/lightbox'
import type { ChartType } from '@cais-group/shared/util/type/article-data'

import { RichText } from './rich-text'

export const ChartContentful = ({
  chartImage,
  longSource,
  title,
  longDescription,
  footnote,
}: ChartType) => {
  const { image, altText } = chartImage
  return (
    <>
      <figure>
        {chartImage.cloudinaryImage ? (
          <ContentfulLightboxWrapper
            title={
              <figcaption className="body-strong text-neutral-900">
                {title}
              </figcaption>
            }
            cloudinaryImage={chartImage.cloudinaryImage}
            name={chartImage.name}
          />
        ) : (
          <>
            <figcaption className="body-strong mb-16 text-neutral-900">
              {title}
            </figcaption>
            {longDescription && (
              <div className="sr-only" aria-label="chart long description">
                <RichText field={longDescription} />
              </div>
            )}
            <ContentfulImage
              src={image.url}
              width={image.width}
              height={image.height}
              background="auto"
              alt={altText || ''}
              className="h-auto max-w-full"
            />
          </>
        )}
      </figure>
      {longSource && (
        <p className="caption mt-16 text-neutral-600">Source: {longSource}</p>
      )}
      {footnote && (
        <div
          data-testid={`chart-footnotes-${title}`}
          className="[&>p]:caption [&>a]:text-primary-600 [&>a]:text-bold !mt-24 space-y-24 break-words border-x-0 border-y-0 border-t border-solid border-neutral-200 text-neutral-600"
        >
          <h2 className="sr-only">Footnotes</h2>
          <RichText field={footnote} />
        </div>
      )}
    </>
  )
}
