import '@cais-group/shared/ui/css'
import '@cais-group/shared/ui/fonts'
import '@cais-group/shared/ui/theme/primary'
import '@cais-group/shared/util/tailwind'
import './modernizr'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { enableMapSet } from 'immer'
import { useEffect } from 'react'
import { focusManager, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'
import { assert } from 'ts-essentials'

import {
  useGetUserByIdV1,
  useUnSwitchUserV1,
} from '@cais-group/access-manager/domain/api'
import { AlertBanner } from '@cais-group/equity/atoms/alert-banner'
import {
  NotificationsManager,
  showToast,
} from '@cais-group/equity/organisms/notifications'
import { APPS, APP_IDS } from '@cais-group/shared/domain/apps'
import {
  UserContentPermissionsManager,
  checkShouldFetchPermissions,
} from '@cais-group/shared/domain/contentful/api'
import {
  setBrowserTheme,
  useGetFirmBrowserExperience,
} from '@cais-group/shared/domain/theme-manager'
import { AppErrorBoundary } from '@cais-group/shared/ui/app-error-boundary'
import {
  Auth0ProviderWithHistory,
  AuthProtected,
} from '@cais-group/shared/ui/auth-protected'
import {
  EnvConfigContainerProvider,
  getEnvConfig,
  type MfeEnv,
} from '@cais-group/shared/ui/env'
import { globalNavManager, MainNav } from '@cais-group/shared/ui/main-nav'
import {
  redirectRulesToRunOnceAfterLogin,
  redirectRulesToRunOnEachLocationChange,
  useRedirect,
} from '@cais-group/shared/ui/route-redirect'
import { useSetAuthTokenGetter } from '@cais-group/shared/util/auth-service'
import {
  UserProfileProvider,
  useUserProfile,
} from '@cais-group/shared/util/hook/use-user-profile'
import { useSetGlobalContext } from '@cais-group/shared/util/logging'
import {
  EquityTracking,
  LocationTrackingValuesProvider,
  SegmentProvider,
} from '@cais-group/shared/util/segment'
import { ThemeProviders } from '@cais-group/shared/util/theme-providers'

import { AnnouncementBanner } from './announcement-banner'
import { getRedirectOnNotFoundRouterPath } from './get-redirect-on-not-found-router-path'
import { usePendo } from './hooks/usePendo'
import { focusListener } from './reactQueryFocusRefetch'
import { SessionModal } from './session-modal'
import {
  shouldShowTerms,
  TermsAndConditionsIndex,
} from './terms-and-conditions-container'
import { TestBrowserSupport } from './test-browser-support'
import './styles.css'

focusManager.setEventListener(focusListener)
const defaultQueryClient = new QueryClient()

export type AppWrapperProps = {
  children: React.ReactNode
  queryClient?: QueryClient
  appName: APPS
  appBasePath: `${APP_IDS}`
  extraRoutes?: RouteObject[]
}

const getRouter = ({
  appBasePath,
  appName,
  children,
  queryClient,
  extraRoutes = [],
}: AppWrapperProps) => {
  return createBrowserRouter([
    {
      path: '/spa-health',
      element: <div className="p-24">All Systems go!</div>,
    },
    {
      path: `/${appBasePath}/*`,
      element: (
        <Root
          appBasePath={appBasePath}
          appName={appName}
          queryClient={queryClient}
        />
      ),
      children: [
        {
          path: '*',
          element: children,
        },
      ],
    },
    ...extraRoutes,
    getRedirectOnNotFoundRouterPath(appName),
  ])
}

// Allows us to use Sets and Maps in immer https://immerjs.github.io/immer/installation/
enableMapSet()

const SessionModalAndNav = (props: { envConfig: MfeEnv }) => {
  useSetAuthTokenGetter()

  const userProfileValue = useUserProfile()
  const {
    userProfileState: { userProfile },
  } = userProfileValue

  const shouldItShowTerms = shouldShowTerms(userProfile)

  const { mutate: unSwitchUser, isLoading: isUnSwitching } = useUnSwitchUserV1({
    mutation: {
      onError: () => {
        showToast({
          type: 'error',
          title:
            'We apologize for the inconvenience, we encountered an issue when attempting to exit the user.',
        })
      },
      onSuccess: async () => {
        sessionStorage.removeItem('sessionId')
        const switchStartLocation = sessionStorage.getItem(
          'switchStartLocation'
        )
        if (switchStartLocation) {
          window.location.assign(switchStartLocation)
        }
      },
    },
  })

  const { data: experience } = useGetFirmBrowserExperience(userProfile)

  const globalNav = globalNavManager({
    userProfile,
    experience,
    config: props.envConfig,
  })

  useEffect(() => {
    setBrowserTheme({ experience: experience || null, userProfile })
  }, [experience, userProfile])

  // Check for any redirect rules (HP-625 Fund Manager)
  useRedirect(
    userProfile,
    redirectRulesToRunOnceAfterLogin,
    redirectRulesToRunOnEachLocationChange
  )

  return (
    <div className="min-h-[theme(constants.mainMenu.height)px]">
      {userProfile ? (
        <>
          {userProfileValue?.userProfileState?.userProfile?.isSwitched ? (
            <AlertBanner
              text={`Logged in as ${userProfile?.firstName || ''} ${
                userProfile?.lastName || ''
              } `}
              action={{
                text: 'Exit User',
                handler: () => unSwitchUser(),
                loading: isUnSwitching,
              }}
            />
          ) : null}
          {shouldItShowTerms ? null : (
            <MainNav
              navItems={globalNav.navItems}
              isContainerNav
              profile={{
                firstName: userProfile?.firstName || '',
                lastName: userProfile?.lastName || '',
                companyName: userProfile?.firm?.name || '',
                menu: globalNav.profileMenu,
              }}
              {...(globalNav.logos && {
                logos: globalNav.logos,
              })}
              enabledFeatureFlags={globalNav.enabledFeatureFlags}
            />
          )}
          <SessionModal />
        </>
      ) : null}
    </div>
  )
}

const Analytics = (props: React.PropsWithChildren) => {
  const {
    userProfileState: { userProfile },
  } = useUserProfile()
  const { id, firstName, lastName, emailAddress, firm } = userProfile || {}

  return (
    <SegmentProvider
      identity={{
        id: id,
        name: `${firstName} ${lastName}`,
        email: emailAddress,
        firmId: firm?.id,
        firmName: firm?.name,
      }}
    >
      {props.children}
    </SegmentProvider>
  )
}

const WithUserProfileAndPendo = ({
  children,
}: {
  children: React.ReactNode
}) => {
  useSetAuthTokenGetter()
  const userProfileValue = useUserProfile()
  const {
    userProfileState: { userProfile },
  } = userProfileValue

  const { data: userRoles } = useGetUserByIdV1(userProfile?.id || '', {
    query: {
      enabled: typeof userProfile?.id === 'string',
    },
  })

  usePendo(userProfile, userRoles?.roles)

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

const TermsOrApp = ({ children }: { children: React.ReactNode }) => {
  const {
    userProfileState: { userProfile },
  } = useUserProfile()
  const shouldItShowTerms = shouldShowTerms(userProfile)
  return shouldItShowTerms ? <TermsAndConditionsIndex /> : children
}

const AuthenticatedContainerWithNavAndRoutes = ({
  appBasePath,
  appName,
  children,
}: {
  appBasePath: `${APP_IDS}`
  appName: APPS
  children: React.ReactNode
}) => {
  useSetGlobalContext({ appName })

  const envConfig = getEnvConfig()
  assert(envConfig, 'envConfig must be defined')

  return (
    <Auth0ProviderWithHistory>
      <AuthProtected>
        <UserProfileProvider>
          <Analytics>
            <LocationTrackingValuesProvider appName={appName}>
              {EquityTracking.enabledApps.includes(appName) && (
                <EquityTracking.Setup />
              )}

              <SessionModalAndNav envConfig={envConfig} />
              <WithUserProfileAndPendo>
                <UserContentPermissionsManager
                  config={{
                    shouldFetchPermissions:
                      checkShouldFetchPermissions(appBasePath),
                  }}
                />
                <div id="authenticated-wrapper">
                  <TermsOrApp>
                    <AnnouncementBanner />
                    {children}
                  </TermsOrApp>
                </div>
              </WithUserProfileAndPendo>
            </LocationTrackingValuesProvider>
          </Analytics>
        </UserProfileProvider>
      </AuthProtected>
    </Auth0ProviderWithHistory>
  )
}

type RootProps = {
  queryClient?: QueryClient
  appBasePath: `${APP_IDS}`
  appName: APPS
}

export const Root = ({ queryClient, appBasePath, appName }: RootProps) => (
  <AppErrorBoundary appName={appName}>
    <QueryClientProvider client={queryClient ?? defaultQueryClient}>
      <EnvConfigContainerProvider
        initializers={(config) => {
          const appVersion = __NX_BUILD_VERSION__ ?? 'unknown'
          if (__NX_BUILD_MODE__ === 'production') {
            datadogRum.init({
              trackUserInteractions: true,
              trackResources: true,
              trackLongTasks: true,
              applicationId: config.DATADOG_APPLICATION_ID,
              clientToken: config.DATADOG_CLIENT_TOKEN,
              site: 'datadoghq.com',
              service: 'caisapp',
              version: appVersion,
              env: config.ENVIRONMENT,
              // Specify a version number to identify the deployed version of your application in Datadog
              // version: '1.0.0',
              sessionSampleRate: 100,
              // Suggested default, not available in our version of sdk (at least types); we are on 2.8.1, latest is 4.4.something
              // defaultPrivacyLevel: 'mask-user-input',
              allowedTracingUrls: window.location.origin
                .toString()
                .includes('localhost')
                ? undefined
                : [window.location.origin],
            })
          }

          const ignoredErrorMessages = ['Login required']

          datadogLogs.init({
            clientToken: config.DATADOG_CLIENT_TOKEN,
            forwardErrorsToLogs: false,
            site: 'datadoghq.com',
            version: appVersion,
            service: 'caisapp',
            env: config.ENVIRONMENT,
            beforeSend: (log) => {
              if (
                typeof log.error?.['message'] === 'string' &&
                ignoredErrorMessages.includes(log.error['message'])
              ) {
                return false
              }
              return true
            },
          })

          datadogLogs.setGlobalContext({ appName })

          // log to console in localhost
          // log to datadog in all other envs
          // do not log anywhere in unit tests
          if (config.ENVIRONMENT === 'localhost') {
            datadogLogs.logger.setHandler('console')
          } else if (__NX_BUILD_MODE__ === 'test') {
            datadogLogs.logger.setHandler('silent')
          } else {
            datadogLogs.logger.setHandler('http')
          }
        }}
      >
        <div
          id="tt-widget"
          style={{
            display: 'none',
          }}
        ></div>
        <TestBrowserSupport />
        <ThemeProviders>
          <NotificationsManager />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthenticatedContainerWithNavAndRoutes
              appBasePath={appBasePath}
              appName={appName}
            >
              <Outlet />
            </AuthenticatedContainerWithNavAndRoutes>
          </LocalizationProvider>
        </ThemeProviders>
      </EnvConfigContainerProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </AppErrorBoundary>
)

/**
 * Wraps children in app level providers, error boundary and strict mode
 */
export const AppWrapper = ({
  children,
  queryClient,
  appName,
  appBasePath,
  extraRoutes,
}: AppWrapperProps) => {
  return (
    <RouterProvider
      router={getRouter({
        appName,
        appBasePath,
        queryClient,
        children,
        extraRoutes,
      })}
    />
  )
}
