import { trackIds } from '@cais-group/caisiq/domain/segment'
import { CourseMetadata } from '@cais-group/shared/util/type/caisiq-be'

import { trackingService } from '..'

import { commonFields } from './common'

export type ExperienceProperties = {
  name: string
  label: string
  contentfulName: string
  almCatalog: string
  coursesEnabled: boolean
  ceCreditsEnabled: boolean
  type: 'DIRECT' | 'FIRM'
  firmIds: Array<string>
  courses?: Array<CourseMetadata>
}

export type FirmProperties = {
  firmId: string
  firmName: string
  firmActive: boolean
  firmExperienceId: string
}

export type FirmEnableProperties = FirmProperties &
  Omit<ExperienceProperties, 'type' | 'firmIds'>

const managePageViewed = () => {
  trackingService.page(trackIds.managePageViewed, {
    ...commonFields(),
  })
}

const manageFirmEnabled = (payload: FirmEnableProperties) => {
  trackingService.track(trackIds.manageFirmEnabled, {
    ...commonFields(),
    firm_id: payload.firmId,
    firm_active: payload.firmActive,
    firm_name: payload.firmName,
    firm_experience_id: payload.firmExperienceId,
    firm_experience_name: payload.name,
    firm_experience_label: payload.label,
    firm_experience_contentful_name: payload.contentfulName,
    firm_experience_alm_catalog: payload.almCatalog,
    firm_experience_courses_enabled: payload.coursesEnabled,
    firm_experience_ce_credits_enabled: payload.ceCreditsEnabled,
  })
}

const manageFirmDisabled = (payload: FirmProperties) => {
  trackingService.track(trackIds.manageFirmDisabled, {
    ...commonFields(),
    firm_id: payload.firmId,
    firm_active: payload.firmActive,
    firm_name: payload.firmName,
    firm_experience_id: payload.firmExperienceId,
  })
}

const manageNewExperienceStarted = () => {
  trackingService.track(trackIds.manageNewExperienceStarted, {
    ...commonFields(),
  })
}

const manageNewExperienceNameEntered = (
  payload: Pick<ExperienceProperties, 'name' | 'label' | 'contentfulName'>
) => {
  trackingService.track(trackIds.manageNewExperienceNameEntered, {
    ...commonFields(),
    name: payload.name,
    label: payload.label,
    contentful_name: payload.contentfulName,
  })
}

const manageNewExperienceCapabilitiesEntered = (
  payload: Pick<
    ExperienceProperties,
    'almCatalog' | 'coursesEnabled' | 'ceCreditsEnabled'
  >
) => {
  trackingService.track(trackIds.manageNewExperienceCapabilitiesEntered, {
    ...commonFields(),
    alm_catalog: payload.almCatalog,
    courses_enabled: payload.coursesEnabled,
    ce_credits_enabled: payload.ceCreditsEnabled,
  })
}

/**
 *
 * @todo don't add until set up on segment
 */

// const manageNewExperienceCoursesEntered = (
//   payload: Pick<
//     ExperienceProperties,
//     'almCatalog' | 'coursesEnabled' | 'courses'
//   >
// ) => {
//   trackingService.track(trackIds.manageNewExperienceCapabilitiesEntered, {
//     ...commonFields(),
//     alm_catalog: payload.almCatalog,
//     courses_enabled: payload.coursesEnabled,
//     experience_courses: payload.courses,
//   })
// }

const manageNewExperienceTypeEntered = (
  payload: Pick<ExperienceProperties, 'type'>
) => {
  trackingService.track(trackIds.manageNewExperienceTypeEntered, {
    ...commonFields(),
    type: payload.type,
  })
}

const manageNewExperienceSubmitted = (payload: ExperienceProperties) => {
  trackingService.track(trackIds.manageNewExperienceSubmitted, {
    ...commonFields(),
    name: payload.name,
    label: payload.label,
    contentful_name: payload.contentfulName,
    alm_catalog: payload.almCatalog,
    courses_enabled: payload.coursesEnabled,
    ce_credits_enabled: payload.ceCreditsEnabled,
    type: payload.type,
    firm_ids: payload.firmIds,
  })
}

const manageExperienceUpdated = (payload: ExperienceProperties) => {
  trackingService.track(trackIds.manageExperienceUpdated, {
    ...commonFields(),
    name: payload.name,
    label: payload.label,
    contentful_name: payload.contentfulName,
    alm_catalog: payload.almCatalog,
    courses_enabled: payload.coursesEnabled,
    ce_credits_enabled: payload.ceCreditsEnabled,
    type: payload.type,
    firm_ids: payload.firmIds,
  })
}

const manageFirmUpdated = (payload: FirmProperties) => {
  trackingService.track(trackIds.manageFirmUpdated, {
    ...commonFields(),
    firm_id: payload.firmId,
    firm_active: payload.firmActive,
    firm_name: payload.firmName,
    firm_experience_id: payload.firmExperienceId,
  })
}

export const manageTracking = {
  managePageViewed,
  manageFirmEnabled,
  manageFirmDisabled,
  manageFirmUpdated,
  manageNewExperienceStarted,
  manageNewExperienceNameEntered,
  manageNewExperienceCapabilitiesEntered,
  manageNewExperienceTypeEntered,
  manageNewExperienceSubmitted,
  manageExperienceUpdated,
  // manageNewExperienceCoursesEntered,
}
