import { trackIds } from '@cais-group/caisiq/domain/segment'
import { Course } from '@cais-group/shared/util/type/caisiq-be'
import { isLibraryCourse } from '@cais-group/shared/util/type/library-course'

import { trackingService } from '..'

import { commonCourseFields, commonFields } from './common'

const fundCourseClicked = (course: Course, buttonText: string) => {
  trackingService.track(trackIds.fundCourseClicked, {
    ...commonFields(),
    ...commonCourseFields(course),
    button_text: buttonText,
  })
}

const courseCardClicked = (course: Course, buttonText: string) => {
  trackingService.track(trackIds.courseCardClicked, {
    ...commonFields(),
    ...commonCourseFields(course as Course),
    // Set state to 'Enroll' if elective library course.
    // See https://caisgroup.atlassian.net/browse/CIQ-4048 for context.
    state: isLibraryCourse(course) ? 'Enroll' : course.type,
    button_text: buttonText,
  })
}

export const courseTracking = {
  fundCourseClicked,
  courseCardClicked,
}
