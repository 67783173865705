type TrackObject =
  | 'Article'
  | 'Course Card'
  | 'External Link'
  | 'Fund Course'
  | 'Logout'
  | 'Presenter'
  | 'Presenter Modal Video'
  | 'Redirect To'
  | 'Schedule Download'
  | 'Sign Up Free'
  | 'Video Playback'
  | 'Whitepaper'
  | 'Whitepaper Download'
  | 'View Reporting Button'
  | 'View Learning Center Button'
  | 'Manage CAIS IQ Page'
  | 'Manage CAIS IQ Firm'
  | 'Manage CAIS IQ New Experience'
  | 'Manage CAIS IQ New Experience Name'
  | 'Manage CAIS IQ New Experience Capabilities'
  | 'Manage CAIS IQ New Experience Courses'
  | 'Manage CAIS IQ New Experience Type'
  | 'Manage CAIS IQ Experience'
  | 'Manage CAIS IQ Button'
  | 'CE Credits Interstitials Page'
  | 'CE Credits Continuing Education Link'
  | 'CE Credits Enter Credential Numbers Link'
  | 'CE Credits Learn More About CE Credits Link'
  | 'CE Credits Credentials'

type Action =
  | 'Cancelled'
  | 'Clicked'
  | 'Completed'
  | 'Started'
  | 'Loaded'
  | 'Resumed'
  | 'Paused'
  | 'Signup'
  | 'Viewed'
  | 'Enabled'
  | 'Disabled'
  | 'Entered'
  | 'Submitted'
  | 'Updated'
  | 'Abandoned'

type Tracking = Record<string, `${TrackObject} ${Action}`>

const trackings = {
  articleClicked: 'Article Clicked',
  courseCardClicked: 'Course Card Clicked',
  externalLinkClicked: 'External Link Clicked',
  fundCourseClicked: 'Fund Course Clicked',
  logoutClicked: 'Logout Clicked',
  presenterClicked: 'Presenter Clicked',
  presenterModalVideoClicked: 'Presenter Modal Video Clicked',
  scheduleDownloadClicked: 'Schedule Download Clicked',
  signUpFreeClicked: 'Sign Up Free Clicked',
  redirectToSignup: 'Redirect To Signup',
  videoPlaybackCancelled: 'Video Playback Cancelled',
  videoPlaybackCompleted: 'Video Playback Completed',
  videoPlaybackStarted: 'Video Playback Started',
  videoPlaybackLoaded: 'Video Playback Loaded',
  videoPlaybackResumed: 'Video Playback Resumed',
  videoPlaybackPaused: 'Video Playback Paused',
  viewReporting: 'View Reporting Button Clicked',
  viewLearningCenter: 'View Learning Center Button Clicked',
  whitepaperClicked: 'Whitepaper Clicked',
  whitepaperDownloadClicked: 'Whitepaper Download Clicked',
  manageCaisiqButtonClicked: 'Manage CAIS IQ Button Clicked',
  managePageViewed: 'Manage CAIS IQ Page Viewed',
  manageFirmEnabled: 'Manage CAIS IQ Firm Enabled',
  manageFirmUpdated: 'Manage CAIS IQ Firm Updated',
  manageFirmDisabled: 'Manage CAIS IQ Firm Disabled',
  manageNewExperienceStarted: 'Manage CAIS IQ New Experience Started',
  manageNewExperienceNameEntered: 'Manage CAIS IQ New Experience Name Entered',
  manageNewExperienceCapabilitiesEntered:
    'Manage CAIS IQ New Experience Capabilities Entered',
  manageNewExperienceCoursesEntered:
    'Manage CAIS IQ New Experience Courses Entered',
  manageNewExperienceTypeEntered: 'Manage CAIS IQ New Experience Type Entered',
  manageNewExperienceSubmitted: 'Manage CAIS IQ New Experience Submitted',
  manageExperienceUpdated: 'Manage CAIS IQ Experience Updated',
  ceInterstitialsPageViewed: 'CE Credits Interstitials Page Viewed',
  ceContinuingEducationLinkClicked:
    'CE Credits Continuing Education Link Clicked',
  ceEnterCredentialNumbersLinkClicked:
    'CE Credits Enter Credential Numbers Link Clicked',
  ceLearnMoreAboutCeCreditsLinkClicked:
    'CE Credits Learn More About CE Credits Link Clicked',
  ceCredentialsEntered: 'CE Credits Credentials Entered',
  ceCredentialsSubmitted: 'CE Credits Credentials Submitted',
  ceCredentialsAbandoned: 'CE Credits Credentials Abandoned',
} as const

type Check<T> = T extends Tracking ? T : never

export const trackIds: Check<typeof trackings> = trackings

type GetObjValues<T> = T extends Record<string, infer V> ? V : never

export type TrackingId = GetObjValues<typeof trackings>
