import { Outlet, useRouteError } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { ErrorGeneral } from '@cais-group/approved/ui/error/general'
import { Footer } from '@cais-group/equity/molecules/footer'
import { ScrollToTop } from '@cais-group/shared/ui/scroll-to-top'
import {
  previewService,
  usePreviewService,
} from '@cais-group/shared/util/contentful/preview-service'
import { useUserProfile } from '@cais-group/shared/util/hook/use-user-profile'
import { logError } from '@cais-group/shared/util/logging'
import {
  usePageTracking,
  useRouterTrackingAttributes,
} from '@cais-group/shared/util/segment'

import { userSettingsService } from '../screens/home'

import { PreviewBannerContainer } from './previewBannerContainer'

export function HomepageLayout() {
  const trackingAttributes = useRouterTrackingAttributes()
  usePageTracking()
  usePreviewService()

  const { userProfileState } = useUserProfile()
  userSettingsService.initiateUser(userProfileState.userProfile)
  previewService.setResolver(userSettingsService)

  const isAdmin = userSettingsService.hasRole('portal:ROLE_CAISIQ_ADMIN')

  return (
    <div
      className="bg-neutral-0 flex min-h-[calc(100vh-theme(constants.mainMenu.height)px)] flex-col"
      {...trackingAttributes}
    >
      <RecoilRoot>
        <ScrollToTop />
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          {isAdmin && <PreviewBannerContainer />}
          <Outlet />
        </QueryParamProvider>
      </RecoilRoot>
      <Footer />
    </div>
  )
}

export function HomepageErrorBoundary() {
  const error = useRouteError()

  logError({
    message: 'Fatal Error Occurred',
    error,
  })

  console.error('Fatal Error Occurred', error)

  return <ErrorGeneral />
}
