import { trackIds } from '@cais-group/caisiq/domain/segment'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

import { trackingService } from '..'

import { commonFields } from './common'

const articleClicked = (article: TypeArticleData, isFeatured: boolean) => {
  trackingService.track(trackIds.articleClicked, {
    ...commonFields(),
    article_title: article.title,
    featured: isFeatured,
  })
}

const whitepaperClicked = (whitepaper: WhitepaperData) => {
  trackingService.track(trackIds.whitepaperClicked, {
    ...commonFields(),
    whitepaper_title: whitepaper.title,
  })
}

const whitepaperDownloadClicked = (title: string, pdf_title: string | null) => {
  trackingService.track(trackIds.whitepaperDownloadClicked, {
    ...commonFields(),
    pdf_title,
    whitepaper_title: title,
  })
}

export const articleTracking = {
  articleClicked,
  whitepaperClicked,
  whitepaperDownloadClicked,
}
