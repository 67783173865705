import type { MuxPlayerRefAttributes } from '@mux/mux-player-react'
import BigNumber from 'bignumber.js'

import { moreExactPercentageFormatter } from '@cais-group/shared/util/number'

import { trackingService } from './index'

export type Video = MuxPlayerRefAttributes

type Metadata = {
  video_id: string
  duration?: number
  video_title: string
  url: string
  watchId: string
}

// TODO: HP-253 Remove once CAIS IQ is ready to switch to the new 'Video Engaged' event.
export const getPlaybackTracker = () => {
  const cancelled = (
    metadata: Metadata & { currentTime: number },
    reason: 'switch video' | 'switch page' | 'close window or tab'
  ) => {
    const timeElapsed = metadata.currentTime
    const totalLength = metadata.duration || 0

    if (timeElapsed < totalLength) {
      const percentWatched = moreExactPercentageFormatter({
        value: timeElapsed / totalLength,
        decimalPlaces: 0,
        appendSymbol: false,
        roundingMode: BigNumber.ROUND_UP,
      })

      trackingService.videoPlaybackCancelled(
        metadata,
        reason,
        timeElapsed,
        totalLength,
        percentWatched
      )
    }
  }
  return {
    loaded: trackingService.videoPlaybackLoaded,
    started: trackingService.videoPlaybackStarted,
    paused: trackingService.videoPlaybackPaused,
    resumed: trackingService.videoPlaybackResumed,
    cancelled: cancelled,
    completed: trackingService.videoPlaybackCompleted,
  }
}
