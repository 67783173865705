import { getEnvConfig } from '@cais-group/shared/ui/env'

type PWSDomain = 'dev' | 'qa' | ''

const makePwsDomain = (subdomain: 'dev' | 'qa' | '') =>
  `https://www${subdomain ? `.${subdomain}` : ''}.caisgroup.com`

function getContentfulEnv() {
  const mapping: Record<string, PWSDomain> = {
    development: 'dev',
    qa: 'qa',
  }
  const { CONTENTFUL_URL = '' } = getEnvConfig()
  const urlParts = CONTENTFUL_URL.split('/')
  const env = urlParts[urlParts.length - 1]
  return mapping[env] || ''
}

export function getPwsDomain() {
  const contentfulEnv = getContentfulEnv()
  return makePwsDomain(contentfulEnv)
}
