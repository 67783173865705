import type { Node } from '@contentful/rich-text-types'
import { Suspense } from 'react'

import { Markdown } from '@cais-group/equity/atoms/markdown'
import { FileDownload } from '@cais-group/equity/labs/file-download'
import { ChartContentful } from '@cais-group/shared/ui/contentful/chart'
import { ContentfulLightboxWrapper } from '@cais-group/shared/ui/lightbox'
import { MuxVideoPlayer } from '@cais-group/shared/ui/video/mux-video-player'
import { getFileExtension } from '@cais-group/shared/util/js/common'
import { millisecondsToSeconds } from '@cais-group/shared/util/js/format-length'
import { EntryMap } from '@cais-group/shared/util/type/rich-text-json'

export function embeddedEntry(node: Node, entryMap: EntryMap) {
  const id = node.data['target'].sys.id
  const entry = entryMap.get(id)

  if (!entry?.__typename) {
    return null
  }
  switch (entry.__typename) {
    case 'Chart': {
      return <ChartContentful {...entry} />
    }
    case 'CaisiqVideo': {
      const durationInSeconds = entry.muxVideo
        ? entry.muxVideo.duration
        : millisecondsToSeconds(entry.duration ?? 0, 'round')
      const videoSource = entry?.muxVideo
        ? { playbackId: entry.muxVideo.playbackId }
        : { src: entry?.video?.url }
      return (
        videoSource &&
        entry && (
          <Suspense fallback={null}>
            <MuxVideoPlayer
              {...videoSource}
              variant="inPageComponent"
              metadata={{
                url: window.location.href,
                video_title: entry.title || '',
                duration: durationInSeconds,
                watchId: (Math.random() * 10).toString(36).replace('.', ''),
                video_id: entry.sys.id,
                viewer_user_id: 'Unknown content entry user',
              }}
            />
          </Suspense>
        )
      )
    }
    case 'Pdf': {
      return (
        <FileDownload
          fileType={getFileExtension(entry.pdf?.fileName?.toUpperCase() ?? '')}
          key={entry.title}
          title={entry.title ?? 'Download'}
          source={entry.pdf?.url ?? ''}
        />
      )
    }
    case 'Markdown': {
      return entry.type === 'footnote' ? (
        <Markdown
          options={{ overrides: { span: { props: { className: 'caption' } } } }}
        >
          {entry.text as string}
        </Markdown>
      ) : null
    }
    case 'ComponentImage': {
      return <ContentfulLightboxWrapper {...entry} />
    }
    default:
      return null
  }
}
