import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import { UserProfileFirmV1 } from '@cais-group/access-manager/domain/api'
import {
  PLACEHOLDER_SHORT_FIRM_NAME,
  PLACEHOLDER_LONG_FIRM_NAME,
  PLACEHOLDER_USERS_FIRM_NAME,
} from '@cais-group/shared/util/constants'
import type {
  ContentDataType,
  CuratedContentDataType,
} from '@cais-group/shared/util/contentful/types'
import type {
  CallToActionFieldsFragment,
  Disclaimer,
  MemberFirm,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export const checkIfDisclaimerNeededAndReturnFirms = (
  items:
    | ((CuratedContentDataType | ContentDataType) & {
        firm?: CallToActionFieldsFragment['firm']
      })[]
    | null
) => {
  const firms: MemberFirm[] = []
  const uniqueFirmIds: Record<string, boolean> = {} // Avoid duplicate firms in the disclaimer

  for (const item of items ?? []) {
    if (item.byCais) {
      continue
    }
    //
    const firmPermissions = item.firmsCollection?.items || [item.firm]
    // Support multiple firm permissions for when requirements expand
    for (const firm of firmPermissions) {
      if (firm?.id && !uniqueFirmIds[firm.id]) {
        firms.push(firm as MemberFirm)
        uniqueFirmIds[firm.id] = true
      }
    }
  }

  return firms.length > 0 ? firms : undefined
}

export const transformDisclaimer = ({
  disclaimer,
  userProfileFirms,
  contentfulFirms,
}: {
  disclaimer: Pick<Disclaimer, 'body' | 'title'> | null
  userProfileFirms: Partial<UserProfileFirmV1[]>
  contentfulFirms?: MemberFirm[] | null
}) => {
  const hasDisclaimer = disclaimer?.body?.json
  const shortNames = []
  const longNames = []
  const usersFirmName =
    userProfileFirms?.length > 0 ? `${userProfileFirms?.[0]?.name}` : ''

  for (const contentfulFirm of contentfulFirms ?? []) {
    const firmNameLong = userProfileFirms.find(
      (f) => f?.id === contentfulFirm?.['id']
    )?.name
    shortNames.push(`"By ${contentfulFirm?.['name']?.trim()}"`)
    longNames.push(firmNameLong?.trim())
  }

  return hasDisclaimer && shortNames.length > 0 && longNames.length > 0
    ? documentToPlainTextString(disclaimer?.body?.json)
        .replace(PLACEHOLDER_SHORT_FIRM_NAME, shortNames.join(' and '))
        .replace(PLACEHOLDER_LONG_FIRM_NAME, longNames.join(' and '))
        .replace(PLACEHOLDER_USERS_FIRM_NAME, usersFirmName)
    : undefined
}
