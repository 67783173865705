/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Funds Product Master API
 * API for Funds Product Master within CAIS
 * OpenAPI spec version: v1
 */
import { useInfiniteQuery, useMutation, useQuery } from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'

import {
  fetchInstanceAxios,
  formDataForDocumentUpload,
} from '@cais-group/product-master/util/form-data-document-upload'
import {
  fetchInstance,
  ErrorType,
  fetchResponse,
} from '@cais-group/shared/util/fetch'

import type {
  AcceptClickThroughAgreementHeaders,
  AccessPolicyPatchAggregateV2,
  BenchmarkV1,
  BulkPatchFundFirmConfigurationsHeaders,
  BulkPatchFundProductsHeaders,
  BulkPatchResponse,
  BulkPublishRequest,
  BulkUploadFundsBody,
  BulkUploadFundsHeaders,
  CalculateCloseDatesAndDueDatesHeaders,
  CheckFundProductUserAccessPolicyRequestV1,
  CheckRequiredCoursesHeaders,
  ClickThroughAgreement,
  ClickThroughAgreementAcceptance,
  CountFundProductsDeprecatedHeaders,
  CountFundProductsDeprecatedParams,
  CountFundProductsHeaders,
  CountResult,
  CreateFundProductDocumentBody,
  CreateFundProductDocumentHeaders,
  CreateFundProductHeaders,
  CreateFundProductVariantDocumentBody,
  CreateFundProductVariantDocumentHeaders,
  CreateParentFundHeaders,
  CustodianPageV1,
  DeleteFundDocumentHeaders,
  DeleteFundFirmConfigurationHeaders,
  DeleteFundProductVariantHeaders,
  DeleteFundVariantDocumentHeaders,
  DocumentCategoryV1,
  DocumentDeliveryWorkflowV1,
  DocumentDownloadLink,
  DownloadFundBulkUploadTemplateHeaders,
  DownloadFundBulkUploadTemplateParams,
  DownloadFundProductDocumentHeaders,
  DownloadFundProductDocumentParams,
  DownloadFundProductVariantDocumentHeaders,
  DownloadFundProductVariantDocumentParams,
  DownloadMercerDocumentHeaders,
  DownloadMercerDocumentParams,
  DraftAllFundsInBulkUploadHeaders,
  ErrorResponse,
  FeatureTogglesV1,
  FetchFundProductCoursesHeaders,
  FirmPageV1,
  FirmRequestV1,
  FundAdministratorV1,
  FundBulkUploadResponseV1,
  FundBulkUploadSearchRequestV1,
  FundBulkUploadSummaryPageV1,
  FundBulkUploadV1,
  FundCloseAndDueDateBlock,
  FundCloseAndDueDateCalculationRequest,
  FundCloseAndDueDateRecalculationRequest,
  FundDocumentRequestV1,
  FundFirmConfigurationConfigV1,
  FundFirmConfigurationV1,
  FundManagerV1,
  FundProductAccessPolicies,
  FundProductAccessPolicySearchRequestV1,
  FundProductAdminPageV1,
  FundProductAdminRequestV1,
  FundProductAdminV1,
  FundProductAggregationsV1,
  FundProductCourses,
  FundProductDocumentPageV1,
  FundProductDocumentV1,
  FundProductDocumentVersionV1,
  FundProductFilterV1,
  FundProductIdsAndNames,
  FundProductIngestionRequest,
  FundProductPageV1,
  FundProductRequiredCoursesCheckRequest,
  FundProductRequiredCoursesCompletedUsers,
  FundProductShareClassPolicyStatusRequestV1,
  FundProductUserAllowedActions,
  FundProductV1,
  FundProductsAdminSearchRequestV1,
  FundProductsSearchRequestV1,
  FundVariantOverridesV1,
  GetBenchmarksHeaders,
  GetClickThroughAgreementHeaders,
  GetCurrentUserHeaders,
  GetCustodiansHeaders,
  GetCustodiansParams,
  GetDocumentCategoriesHeaders,
  GetDocumentDeliveryWorkflowsHeaders,
  GetFeatureTogglesHeaders,
  GetFundAdministratorsHeaders,
  GetFundBulkUploadHeaders,
  GetFundDocumentHeaders,
  GetFundDocumentsHeaders,
  GetFundDocumentsParams,
  GetFundFirmConfigurationHeaders,
  GetFundFirmConfigurationParams,
  GetFundFirmConfigurationsHeaders,
  GetFundFirmConfigurationsParams,
  GetFundManagersHeaders,
  GetFundManagersParams,
  GetFundProductByUserIdHeaders,
  GetFundProductByUserIdParams,
  GetFundProductDocumentVersionsHeaders,
  GetFundProductHeaders,
  GetFundProductIdsAndNamesHeaders,
  GetFundProductParams,
  GetFundProductPoliciesHeaders,
  GetFundProductPoliciesParams,
  GetFundProductUserAllowedActionsHeaders,
  GetFundProductVariantDocumentVersionsHeaders,
  GetFundProductVariantOverridesHeaders,
  GetFundProductsByUserIdHeaders,
  GetFundProductsByUserIdParams,
  GetFundRanksHeaders,
  GetFundRanksParams,
  GetFundVariantDocumentHeaders,
  GetFundVariantDocumentsHeaders,
  GetFundVariantDocumentsParams,
  GetLatestAcceptedClickThroughAgreementHeaders,
  GetMercerDocumentHeaders,
  GetMercerDocumentsHeaders,
  GetPerformanceReportHeaders,
  GetPerformanceReportParams,
  GetShareClassAccessPoliciesByFundProductAndSubjectHeaders,
  GetShareClassAccessPoliciesByFundProductAndSubjectParams,
  GetShareClassApprovalsByFundProductHeaders,
  GetShareClassApprovalsByFundProductParams,
  GetSubjectAccessPolicyHeaders,
  GetSubjectAccessPolicyParams,
  GetUserAllowedActionsByFundProductIdHeaders,
  GetUserExperienceHeaders,
  GetUserExperienceParams,
  MercerServicesDocumentV1,
  ParentFundPageV1,
  ParentFundRequestV1,
  ParentFundSearchRequestV1,
  ParentFundV1,
  PatchAccessPoliciesHeaders,
  PatchDocument,
  PatchFundFirmConfigurationHeaders,
  PatchFundProductHeaders,
  PatchFundRanksHeaders,
  PatchRequest,
  PreviewAdminFundProductsHeaders,
  PublishAllFundsInBulkUploadHeaders,
  PublishFundProductAdminEventHeaders,
  PublishFundProductAdminEventsBulkHeaders,
  PublishFundProductHeaders,
  PublishFundProductParams,
  PublishProgress,
  PublishShareClassHeaders,
  RecalculateAndSaveCloseDatesAndDueDatesHeaders,
  RunValidation200,
  RunValidationHeaders,
  SearchAccessPoliciesHeaders,
  SearchAdminFundProductsHeaders,
  SearchAdminFundProductsParams,
  SearchFirmsHeaders,
  SearchFundBulkUploadsHeaders,
  SearchFundProductAggregationsHeaders,
  SearchFundProductAggregationsListHeaders,
  SearchFundProductIdsAndNamesHeaders,
  SearchFundProductIdsAndNamesRequestV1,
  SearchFundProductsHeaders,
  SearchParentFundsHeaders,
  SearchSubjectFirstAccessPoliciesHeaders,
  ShareClassAccessPolicies,
  ShareClassesApprovals,
  SubjectFirstAccessPolicySearchRequest,
  SubjectFirstAccessPolicySetting,
  SubjectFirstAccessPolicyShareClassPage,
  SubjectFirstFundRankPatch,
  SubjectFirstFundRanks,
  SubjectShareClassAccessPolicyPageV1,
  TriggerReIngestionHeaders,
  UpdateFundDocumentFileBody,
  UpdateFundDocumentFileHeaders,
  UpdateFundDocumentMetadataHeaders,
  UpdateFundFirmConfigurationHeaders,
  UpdateFundProductHeaders,
  UpdateFundProductVariantOverridesHeaders,
  UpdateFundVariantDocumentFileBody,
  UpdateFundVariantDocumentFileHeaders,
  UpdateFundVariantDocumentMetadataHeaders,
  UpdateShareClassPolicyStatusHeaders,
  UpsertMercerServicesDocumentBody,
  UpsertMercerServicesDocumentHeaders,
  UpsertMercerServicesDocumentParams,
  UpsertUserExperienceHeaders,
  UpsertUserExperienceParams,
  UserContextV1,
  UserExperienceV1,
  ValidateAllFundsInBulkUploadHeaders,
} from './funds-product-master-api.schemas'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never

/**
 * **Permission:** `funds:products:admin-write`

Create a parent fund
 * @summary Create a parent fund
 */
export const createParentFund = (
  parentFundRequestV1: ParentFundRequestV1,
  headers?: CreateParentFundHeaders
) => {
  return fetchInstance<ParentFundV1>({
    url: `/fund-products/v1/parent-funds`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: parentFundRequestV1,
  })
}

export const getCreateParentFundMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createParentFund>>,
    TError,
    { data: ParentFundRequestV1; headers?: CreateParentFundHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createParentFund>>,
  TError,
  { data: ParentFundRequestV1; headers?: CreateParentFundHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createParentFund>>,
    { data: ParentFundRequestV1; headers?: CreateParentFundHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return createParentFund(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateParentFundMutationResult = NonNullable<
  Awaited<ReturnType<typeof createParentFund>>
>
export type CreateParentFundMutationBody = ParentFundRequestV1
export type CreateParentFundMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a parent fund
 */
export const useCreateParentFund = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createParentFund>>,
    TError,
    { data: ParentFundRequestV1; headers?: CreateParentFundHeaders },
    TContext
  >
}) => {
  const mutationOptions = getCreateParentFundMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Search parent funds
 * @summary Search parent funds
 */
export const searchParentFunds = (
  parentFundSearchRequestV1: ParentFundSearchRequestV1,
  headers?: SearchParentFundsHeaders
) => {
  return fetchInstance<ParentFundPageV1>({
    url: `/fund-products/v1/parent-funds/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: parentFundSearchRequestV1,
  })
}

export const getSearchParentFundsQueryKey = (
  parentFundSearchRequestV1: ParentFundSearchRequestV1
) => {
  return [
    `/fund-products/v1/parent-funds/search`,
    parentFundSearchRequestV1,
  ] as const
}

export const getSearchParentFundsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchParentFunds>>,
  TError = ErrorType<ErrorResponse>
>(
  parentFundSearchRequestV1: ParentFundSearchRequestV1,
  headers?: SearchParentFundsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchParentFunds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchParentFundsQueryKey(parentFundSearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchParentFunds>>
  > = () => searchParentFunds(parentFundSearchRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchParentFunds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchParentFundsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchParentFunds>>
>
export type SearchParentFundsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search parent funds
 */
export const useSearchParentFundsInfinite = <
  TData = Awaited<ReturnType<typeof searchParentFunds>>,
  TError = ErrorType<ErrorResponse>
>(
  parentFundSearchRequestV1: ParentFundSearchRequestV1,
  headers?: SearchParentFundsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchParentFunds>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchParentFundsInfiniteQueryOptions(
    parentFundSearchRequestV1,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchParentFundsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchParentFunds>>,
  TError = ErrorType<ErrorResponse>
>(
  parentFundSearchRequestV1: ParentFundSearchRequestV1,
  headers?: SearchParentFundsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchParentFunds>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchParentFundsQueryKey(parentFundSearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchParentFunds>>
  > = () => searchParentFunds(parentFundSearchRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchParentFunds>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchParentFundsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchParentFunds>>
>
export type SearchParentFundsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search parent funds
 */
export const useSearchParentFunds = <
  TData = Awaited<ReturnType<typeof searchParentFunds>>,
  TError = ErrorType<ErrorResponse>
>(
  parentFundSearchRequestV1: ParentFundSearchRequestV1,
  headers?: SearchParentFundsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchParentFunds>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchParentFundsQueryOptions(
    parentFundSearchRequestV1,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:advisor-read`

Gets context information for the currently logged in user
 * @summary Gets context information for the currently logged in user
 */
export const getCurrentUser = (
  headers?: GetCurrentUserHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<UserContextV1>({
    url: `/fund-products/v1/user/current`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetCurrentUserQueryKey = () => {
  return [`/fund-products/v1/user/current`] as const
}

export const getGetCurrentUserQueryOptions = <
  TData = Awaited<ReturnType<typeof getCurrentUser>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetCurrentUserHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUser>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCurrentUserQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCurrentUser>>> = ({
    signal,
  }) => getCurrentUser(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCurrentUser>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCurrentUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCurrentUser>>
>
export type GetCurrentUserQueryError = ErrorType<ErrorResponse>

/**
 * @summary Gets context information for the currently logged in user
 */
export const useGetCurrentUser = <
  TData = Awaited<ReturnType<typeof getCurrentUser>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetCurrentUserHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCurrentUser>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCurrentUserQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`
    
Get user experience
 * @summary Get user experience
 */
export const getUserExperience = (
  params: GetUserExperienceParams,
  headers?: GetUserExperienceHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<UserExperienceV1>({
    url: `/fund-products/v1/user/experience`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetUserExperienceQueryKey = (
  params: GetUserExperienceParams
) => {
  return [
    `/fund-products/v1/user/experience`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetUserExperienceQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserExperience>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetUserExperienceParams,
  headers?: GetUserExperienceHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserExperience>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetUserExperienceQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getUserExperience>>
  > = ({ signal }) => getUserExperience(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserExperience>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetUserExperienceQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserExperience>>
>
export type GetUserExperienceQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get user experience
 */
export const useGetUserExperience = <
  TData = Awaited<ReturnType<typeof getUserExperience>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetUserExperienceParams,
  headers?: GetUserExperienceHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getUserExperience>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserExperienceQueryOptions(
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Upsert user experience
 * @summary Upsert user experience
 */
export const upsertUserExperience = (
  userExperienceV1: UserExperienceV1,
  params: UpsertUserExperienceParams,
  headers?: UpsertUserExperienceHeaders
) => {
  return fetchInstance<UserExperienceV1>({
    url: `/fund-products/v1/user/experience`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: userExperienceV1,
    params,
  })
}

export const getUpsertUserExperienceMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertUserExperience>>,
    TError,
    {
      data: UserExperienceV1
      params: UpsertUserExperienceParams
      headers?: UpsertUserExperienceHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof upsertUserExperience>>,
  TError,
  {
    data: UserExperienceV1
    params: UpsertUserExperienceParams
    headers?: UpsertUserExperienceHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof upsertUserExperience>>,
    {
      data: UserExperienceV1
      params: UpsertUserExperienceParams
      headers?: UpsertUserExperienceHeaders
    }
  > = (props) => {
    const { data, params, headers } = props ?? {}

    return upsertUserExperience(data, params, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpsertUserExperienceMutationResult = NonNullable<
  Awaited<ReturnType<typeof upsertUserExperience>>
>
export type UpsertUserExperienceMutationBody = UserExperienceV1
export type UpsertUserExperienceMutationError = ErrorType<ErrorResponse>

/**
 * @summary Upsert user experience
 */
export const useUpsertUserExperience = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertUserExperience>>,
    TError,
    {
      data: UserExperienceV1
      params: UpsertUserExperienceParams
      headers?: UpsertUserExperienceHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpsertUserExperienceMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get a document for a fund product
 * @summary Get a document for a fund product
 */
export const getFundDocument = (
  id: string,
  documentId: string,
  headers?: GetFundDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/documents/${documentId}`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundDocumentQueryKey = (id: string, documentId: string) => {
  return [
    `/fund-products/v1/fund-products/${id}/documents/${documentId}`,
  ] as const
}

export const getGetFundDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  headers?: GetFundDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundDocumentQueryKey(id, documentId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFundDocument>>> = ({
    signal,
  }) => getFundDocument(id, documentId, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundDocument>>
>
export type GetFundDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a document for a fund product
 */
export const useGetFundDocument = <
  TData = Awaited<ReturnType<typeof getFundDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  headers?: GetFundDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundDocumentQueryOptions(
    id,
    documentId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Delete a document for a fund product
 * @summary Delete a document for a fund product
 */
export const deleteFundDocument = (
  id: string,
  documentId: string,
  headers?: DeleteFundDocumentHeaders
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/documents/${documentId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteFundDocumentMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundDocument>>,
    TError,
    { id: string; documentId: string; headers?: DeleteFundDocumentHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFundDocument>>,
  TError,
  { id: string; documentId: string; headers?: DeleteFundDocumentHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFundDocument>>,
    { id: string; documentId: string; headers?: DeleteFundDocumentHeaders }
  > = (props) => {
    const { id, documentId, headers } = props ?? {}

    return deleteFundDocument(id, documentId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFundDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundDocument>>
>

export type DeleteFundDocumentMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a document for a fund product
 */
export const useDeleteFundDocument = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundDocument>>,
    TError,
    { id: string; documentId: string; headers?: DeleteFundDocumentHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFundDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Update metadata for a fund product document
 * @summary Update metadata for a fund product document
 */
export const updateFundDocumentMetadata = (
  id: string,
  documentId: string,
  fundDocumentRequestV1: FundDocumentRequestV1,
  headers?: UpdateFundDocumentMetadataHeaders
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/documents/${documentId}/metadata`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundDocumentRequestV1,
  })
}

export const getUpdateFundDocumentMetadataMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundDocumentMetadata>>,
    TError,
    {
      id: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundDocumentMetadataHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundDocumentMetadata>>,
  TError,
  {
    id: string
    documentId: string
    data: FundDocumentRequestV1
    headers?: UpdateFundDocumentMetadataHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundDocumentMetadata>>,
    {
      id: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundDocumentMetadataHeaders
    }
  > = (props) => {
    const { id, documentId, data, headers } = props ?? {}

    return updateFundDocumentMetadata(id, documentId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundDocumentMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundDocumentMetadata>>
>
export type UpdateFundDocumentMetadataMutationBody = FundDocumentRequestV1
export type UpdateFundDocumentMetadataMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update metadata for a fund product document
 */
export const useUpdateFundDocumentMetadata = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundDocumentMetadata>>,
    TError,
    {
      id: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundDocumentMetadataHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFundDocumentMetadataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Update file for a fund product document
 * @summary Update file for a fund product document
 */
export const updateFundDocumentFile = (
  id: string,
  documentId: string,
  updateFundDocumentFileBody: UpdateFundDocumentFileBody,
  headers?: UpdateFundDocumentFileHeaders,
  options?: SecondParameter<typeof fetchInstanceAxios>
) => {
  const formData = formDataForDocumentUpload(updateFundDocumentFileBody)
  return fetchInstanceAxios<FundProductDocumentV1>(
    {
      url: `/fund-products/v1/fund-products/${id}/documents/${documentId}/file`,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      data: formData,
    },
    options
  )
}

export const getUpdateFundDocumentFileMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundDocumentFile>>,
    TError,
    {
      id: string
      documentId: string
      data: UpdateFundDocumentFileBody
      headers?: UpdateFundDocumentFileHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundDocumentFile>>,
  TError,
  {
    id: string
    documentId: string
    data: UpdateFundDocumentFileBody
    headers?: UpdateFundDocumentFileHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundDocumentFile>>,
    {
      id: string
      documentId: string
      data: UpdateFundDocumentFileBody
      headers?: UpdateFundDocumentFileHeaders
    }
  > = (props) => {
    const { id, documentId, data, headers } = props ?? {}

    return updateFundDocumentFile(id, documentId, data, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundDocumentFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundDocumentFile>>
>
export type UpdateFundDocumentFileMutationBody = UpdateFundDocumentFileBody
export type UpdateFundDocumentFileMutationError = ErrorResponse

/**
 * @summary Update file for a fund product document
 */
export const useUpdateFundDocumentFile = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundDocumentFile>>,
    TError,
    {
      id: string
      documentId: string
      data: UpdateFundDocumentFileBody
      headers?: UpdateFundDocumentFileHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}) => {
  const mutationOptions = getUpdateFundDocumentFileMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`, `funds:products:advisor-read`

Download a document for a fund product
 * @summary Download a document for a fund product
 */
export const downloadFundProductDocument = (
  id: string,
  documentId: string,
  params?: DownloadFundProductDocumentParams,
  headers?: DownloadFundProductDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<DocumentDownloadLink>({
    url: `/fund-products/v1/fund-products/${id}/documents/${documentId}/download`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getDownloadFundProductDocumentQueryKey = (
  id: string,
  documentId: string,
  params?: DownloadFundProductDocumentParams
) => {
  return [
    `/fund-products/v1/fund-products/${id}/documents/${documentId}/download`,
    ...(params ? [params] : []),
  ] as const
}

export const getDownloadFundProductDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFundProductDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  params?: DownloadFundProductDocumentParams,
  headers?: DownloadFundProductDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundProductDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadFundProductDocumentQueryKey(id, documentId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFundProductDocument>>
  > = ({ signal }) =>
    downloadFundProductDocument(id, documentId, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFundProductDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DownloadFundProductDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFundProductDocument>>
>
export type DownloadFundProductDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Download a document for a fund product
 */
export const useDownloadFundProductDocument = <
  TData = Awaited<ReturnType<typeof downloadFundProductDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  params?: DownloadFundProductDocumentParams,
  headers?: DownloadFundProductDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundProductDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFundProductDocumentQueryOptions(
    id,
    documentId,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Get all versions of a document for a fund product
 * @summary Get all versions of a document for a fund product
 */
export const getFundProductDocumentVersions = (
  id: string,
  documentId: string,
  headers?: GetFundProductDocumentVersionsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductDocumentVersionV1[]>({
    url: `/fund-products/v1/fund-products/${id}/documents/${documentId}/versions`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundProductDocumentVersionsQueryKey = (
  id: string,
  documentId: string
) => {
  return [
    `/fund-products/v1/fund-products/${id}/documents/${documentId}/versions`,
  ] as const
}

export const getGetFundProductDocumentVersionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductDocumentVersions>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  headers?: GetFundProductDocumentVersionsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductDocumentVersions>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundProductDocumentVersionsQueryKey(id, documentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductDocumentVersions>>
  > = ({ signal }) =>
    getFundProductDocumentVersions(id, documentId, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductDocumentVersions>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductDocumentVersionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductDocumentVersions>>
>
export type GetFundProductDocumentVersionsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get all versions of a document for a fund product
 */
export const useGetFundProductDocumentVersions = <
  TData = Awaited<ReturnType<typeof getFundProductDocumentVersions>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  documentId: string,
  headers?: GetFundProductDocumentVersionsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductDocumentVersions>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductDocumentVersionsQueryOptions(
    id,
    documentId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`, `funds:products:advisor-read`

Search for documents for a fund product
 * @summary Search for documents for a fund product
 */
export const getFundDocuments = (
  id: string,
  params?: GetFundDocumentsParams,
  headers?: GetFundDocumentsHeaders
) => {
  return fetchInstance<FundProductDocumentPageV1>({
    url: `/fund-products/v1/fund-products/${id}/documents`,
    method: 'GET',
    headers,
    params,
  })
}

export const getGetFundDocumentsQueryKey = (
  id: string,
  params?: GetFundDocumentsParams
) => {
  return [
    `/fund-products/v1/fund-products/${id}/documents`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundDocumentsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundDocumentsParams,
  headers?: GetFundDocumentsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFundDocuments>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundDocumentsQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundDocuments>>
  > = ({ pageParam }) =>
    getFundDocuments(
      id,
      { ...params, after: pageParam || params?.['after'] },
      headers
    )

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFundDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundDocumentsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundDocuments>>
>
export type GetFundDocumentsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for documents for a fund product
 */
export const useGetFundDocumentsInfinite = <
  TData = Awaited<ReturnType<typeof getFundDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundDocumentsParams,
  headers?: GetFundDocumentsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFundDocuments>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundDocumentsInfiniteQueryOptions(
    id,
    params,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Create a document for a fund product
 * @summary Create a document for a fund product
 */
export const createFundProductDocument = (
  id: string,
  createFundProductDocumentBody: CreateFundProductDocumentBody,
  headers?: CreateFundProductDocumentHeaders,
  options?: SecondParameter<typeof fetchInstanceAxios>
) => {
  const formData = formDataForDocumentUpload(createFundProductDocumentBody)
  return fetchInstanceAxios<FundProductDocumentV1>(
    {
      url: `/fund-products/v1/fund-products/${id}/documents`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      data: formData,
    },
    options
  )
}

export const getCreateFundProductDocumentMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProductDocument>>,
    TError,
    {
      id: string
      data: CreateFundProductDocumentBody
      headers?: CreateFundProductDocumentHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFundProductDocument>>,
  TError,
  {
    id: string
    data: CreateFundProductDocumentBody
    headers?: CreateFundProductDocumentHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFundProductDocument>>,
    {
      id: string
      data: CreateFundProductDocumentBody
      headers?: CreateFundProductDocumentHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return createFundProductDocument(id, data, headers, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFundProductDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFundProductDocument>>
>
export type CreateFundProductDocumentMutationBody =
  CreateFundProductDocumentBody
export type CreateFundProductDocumentMutationError = ErrorResponse

/**
 * @summary Create a document for a fund product
 */
export const useCreateFundProductDocument = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProductDocument>>,
    TError,
    {
      id: string
      data: CreateFundProductDocumentBody
      headers?: CreateFundProductDocumentHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}) => {
  const mutationOptions = getCreateFundProductDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get a document for a fund product variant
 * @summary Get a document for a fund product variant
 */
export const getFundVariantDocument = (
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundVariantDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundVariantDocumentQueryKey = (
  id: string,
  variantId: string,
  documentId: string
) => {
  return [
    `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}`,
  ] as const
}

export const getGetFundVariantDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundVariantDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundVariantDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundVariantDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundVariantDocumentQueryKey(id, variantId, documentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundVariantDocument>>
  > = ({ signal }) =>
    getFundVariantDocument(id, variantId, documentId, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && variantId && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundVariantDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundVariantDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundVariantDocument>>
>
export type GetFundVariantDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get a document for a fund product variant
 */
export const useGetFundVariantDocument = <
  TData = Awaited<ReturnType<typeof getFundVariantDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundVariantDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundVariantDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundVariantDocumentQueryOptions(
    id,
    variantId,
    documentId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Delete a document for a fund product variant
 * @summary Delete a document for a fund product variant
 */
export const deleteFundVariantDocument = (
  id: string,
  variantId: string,
  documentId: string,
  headers?: DeleteFundVariantDocumentHeaders
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteFundVariantDocumentMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundVariantDocument>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      headers?: DeleteFundVariantDocumentHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFundVariantDocument>>,
  TError,
  {
    id: string
    variantId: string
    documentId: string
    headers?: DeleteFundVariantDocumentHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFundVariantDocument>>,
    {
      id: string
      variantId: string
      documentId: string
      headers?: DeleteFundVariantDocumentHeaders
    }
  > = (props) => {
    const { id, variantId, documentId, headers } = props ?? {}

    return deleteFundVariantDocument(id, variantId, documentId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFundVariantDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundVariantDocument>>
>

export type DeleteFundVariantDocumentMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete a document for a fund product variant
 */
export const useDeleteFundVariantDocument = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundVariantDocument>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      headers?: DeleteFundVariantDocumentHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFundVariantDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Update metadata for a fund product variant document
 * @summary Update metadata for a fund product variant document
 */
export const updateFundVariantDocumentMetadata = (
  id: string,
  variantId: string,
  documentId: string,
  fundDocumentRequestV1: FundDocumentRequestV1,
  headers?: UpdateFundVariantDocumentMetadataHeaders
) => {
  return fetchInstance<FundProductDocumentV1>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/metadata`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundDocumentRequestV1,
  })
}

export const getUpdateFundVariantDocumentMetadataMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundVariantDocumentMetadata>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundVariantDocumentMetadataHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundVariantDocumentMetadata>>,
  TError,
  {
    id: string
    variantId: string
    documentId: string
    data: FundDocumentRequestV1
    headers?: UpdateFundVariantDocumentMetadataHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundVariantDocumentMetadata>>,
    {
      id: string
      variantId: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundVariantDocumentMetadataHeaders
    }
  > = (props) => {
    const { id, variantId, documentId, data, headers } = props ?? {}

    return updateFundVariantDocumentMetadata(
      id,
      variantId,
      documentId,
      data,
      headers
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundVariantDocumentMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundVariantDocumentMetadata>>
>
export type UpdateFundVariantDocumentMetadataMutationBody =
  FundDocumentRequestV1
export type UpdateFundVariantDocumentMetadataMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update metadata for a fund product variant document
 */
export const useUpdateFundVariantDocumentMetadata = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundVariantDocumentMetadata>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      data: FundDocumentRequestV1
      headers?: UpdateFundVariantDocumentMetadataHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateFundVariantDocumentMetadataMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Update file for a fund product variant document
 * @summary Update file for a fund product variant document
 */
export const updateFundVariantDocumentFile = (
  id: string,
  variantId: string,
  documentId: string,
  updateFundVariantDocumentFileBody: UpdateFundVariantDocumentFileBody,
  headers?: UpdateFundVariantDocumentFileHeaders,
  options?: SecondParameter<typeof fetchInstanceAxios>
) => {
  const formData = formDataForDocumentUpload(updateFundVariantDocumentFileBody)
  return fetchInstanceAxios<FundProductDocumentV1>(
    {
      url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/file`,
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      data: formData,
    },
    options
  )
}

export const getUpdateFundVariantDocumentFileMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundVariantDocumentFile>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      data: UpdateFundVariantDocumentFileBody
      headers?: UpdateFundVariantDocumentFileHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundVariantDocumentFile>>,
  TError,
  {
    id: string
    variantId: string
    documentId: string
    data: UpdateFundVariantDocumentFileBody
    headers?: UpdateFundVariantDocumentFileHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundVariantDocumentFile>>,
    {
      id: string
      variantId: string
      documentId: string
      data: UpdateFundVariantDocumentFileBody
      headers?: UpdateFundVariantDocumentFileHeaders
    }
  > = (props) => {
    const { id, variantId, documentId, data, headers } = props ?? {}

    return updateFundVariantDocumentFile(
      id,
      variantId,
      documentId,
      data,
      headers,
      requestOptions
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundVariantDocumentFileMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundVariantDocumentFile>>
>
export type UpdateFundVariantDocumentFileMutationBody =
  UpdateFundVariantDocumentFileBody
export type UpdateFundVariantDocumentFileMutationError = ErrorResponse

/**
 * @summary Update file for a fund product variant document
 */
export const useUpdateFundVariantDocumentFile = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundVariantDocumentFile>>,
    TError,
    {
      id: string
      variantId: string
      documentId: string
      data: UpdateFundVariantDocumentFileBody
      headers?: UpdateFundVariantDocumentFileHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}) => {
  const mutationOptions =
    getUpdateFundVariantDocumentFileMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`, `funds:products:advisor-read`

Download a document for a fund product variant
 * @summary Download a document for a fund product variant
 */
export const downloadFundProductVariantDocument = (
  id: string,
  variantId: string,
  documentId: string,
  params?: DownloadFundProductVariantDocumentParams,
  headers?: DownloadFundProductVariantDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<DocumentDownloadLink>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/download`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getDownloadFundProductVariantDocumentQueryKey = (
  id: string,
  variantId: string,
  documentId: string,
  params?: DownloadFundProductVariantDocumentParams
) => {
  return [
    `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/download`,
    ...(params ? [params] : []),
  ] as const
}

export const getDownloadFundProductVariantDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFundProductVariantDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  params?: DownloadFundProductVariantDocumentParams,
  headers?: DownloadFundProductVariantDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundProductVariantDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadFundProductVariantDocumentQueryKey(
      id,
      variantId,
      documentId,
      params
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFundProductVariantDocument>>
  > = ({ signal }) =>
    downloadFundProductVariantDocument(
      id,
      variantId,
      documentId,
      params,
      headers,
      signal
    )

  return {
    queryKey,
    queryFn,
    enabled: !!(id && variantId && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFundProductVariantDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DownloadFundProductVariantDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFundProductVariantDocument>>
>
export type DownloadFundProductVariantDocumentQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Download a document for a fund product variant
 */
export const useDownloadFundProductVariantDocument = <
  TData = Awaited<ReturnType<typeof downloadFundProductVariantDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  params?: DownloadFundProductVariantDocumentParams,
  headers?: DownloadFundProductVariantDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundProductVariantDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFundProductVariantDocumentQueryOptions(
    id,
    variantId,
    documentId,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Get all versions of a document for a fund product variant
 * @summary Get all versions of a document for a fund product variant
 */
export const getFundProductVariantDocumentVersions = (
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundProductVariantDocumentVersionsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductDocumentVersionV1[]>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/versions`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundProductVariantDocumentVersionsQueryKey = (
  id: string,
  variantId: string,
  documentId: string
) => {
  return [
    `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents/${documentId}/versions`,
  ] as const
}

export const getGetFundProductVariantDocumentVersionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundProductVariantDocumentVersionsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundProductVariantDocumentVersionsQueryKey(id, variantId, documentId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>
  > = ({ signal }) =>
    getFundProductVariantDocumentVersions(
      id,
      variantId,
      documentId,
      headers,
      signal
    )

  return {
    queryKey,
    queryFn,
    enabled: !!(id && variantId && documentId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductVariantDocumentVersionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>
>
export type GetFundProductVariantDocumentVersionsQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get all versions of a document for a fund product variant
 */
export const useGetFundProductVariantDocumentVersions = <
  TData = Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  documentId: string,
  headers?: GetFundProductVariantDocumentVersionsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductVariantDocumentVersions>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductVariantDocumentVersionsQueryOptions(
    id,
    variantId,
    documentId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`, `funds:products:advisor-read`

Search for documents for a fund product variant
 * @summary Search for documents for a fund product variant
 */
export const getFundVariantDocuments = (
  id: string,
  variantId: string,
  params?: GetFundVariantDocumentsParams,
  headers?: GetFundVariantDocumentsHeaders
) => {
  return fetchInstance<FundProductDocumentPageV1>({
    url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents`,
    method: 'GET',
    headers,
    params,
  })
}

export const getGetFundVariantDocumentsQueryKey = (
  id: string,
  variantId: string,
  params?: GetFundVariantDocumentsParams
) => {
  return [
    `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundVariantDocumentsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundVariantDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  params?: GetFundVariantDocumentsParams,
  headers?: GetFundVariantDocumentsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFundVariantDocuments>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundVariantDocumentsQueryKey(id, variantId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundVariantDocuments>>
  > = ({ pageParam }) =>
    getFundVariantDocuments(
      id,
      variantId,
      { ...params, after: pageParam || params?.['after'] },
      headers
    )

  return {
    queryKey,
    queryFn,
    enabled: !!(id && variantId),
    ...queryOptions,
  } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getFundVariantDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundVariantDocumentsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundVariantDocuments>>
>
export type GetFundVariantDocumentsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for documents for a fund product variant
 */
export const useGetFundVariantDocumentsInfinite = <
  TData = Awaited<ReturnType<typeof getFundVariantDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  variantId: string,
  params?: GetFundVariantDocumentsParams,
  headers?: GetFundVariantDocumentsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof getFundVariantDocuments>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundVariantDocumentsInfiniteQueryOptions(
    id,
    variantId,
    params,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Create a document for a fund product variant
 * @summary Create a document for a fund product variant
 */
export const createFundProductVariantDocument = (
  id: string,
  variantId: string,
  createFundProductVariantDocumentBody: CreateFundProductVariantDocumentBody,
  headers?: CreateFundProductVariantDocumentHeaders,
  options?: SecondParameter<typeof fetchInstanceAxios>
) => {
  const formData = formDataForDocumentUpload(
    createFundProductVariantDocumentBody
  )
  return fetchInstanceAxios<FundProductDocumentV1>(
    {
      url: `/fund-products/v1/fund-products/${id}/variants/${variantId}/documents`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data', ...headers },
      data: formData,
    },
    options
  )
}

export const getCreateFundProductVariantDocumentMutationOptions = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProductVariantDocument>>,
    TError,
    {
      id: string
      variantId: string
      data: CreateFundProductVariantDocumentBody
      headers?: CreateFundProductVariantDocumentHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFundProductVariantDocument>>,
  TError,
  {
    id: string
    variantId: string
    data: CreateFundProductVariantDocumentBody
    headers?: CreateFundProductVariantDocumentHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFundProductVariantDocument>>,
    {
      id: string
      variantId: string
      data: CreateFundProductVariantDocumentBody
      headers?: CreateFundProductVariantDocumentHeaders
    }
  > = (props) => {
    const { id, variantId, data, headers } = props ?? {}

    return createFundProductVariantDocument(
      id,
      variantId,
      data,
      headers,
      requestOptions
    )
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFundProductVariantDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFundProductVariantDocument>>
>
export type CreateFundProductVariantDocumentMutationBody =
  CreateFundProductVariantDocumentBody
export type CreateFundProductVariantDocumentMutationError = ErrorResponse

/**
 * @summary Create a document for a fund product variant
 */
export const useCreateFundProductVariantDocument = <
  TError = ErrorResponse,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProductVariantDocument>>,
    TError,
    {
      id: string
      variantId: string
      data: CreateFundProductVariantDocumentBody
      headers?: CreateFundProductVariantDocumentHeaders
    },
    TContext
  >
  request?: SecondParameter<typeof fetchInstanceAxios>
}) => {
  const mutationOptions =
    getCreateFundProductVariantDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-read`

Get document categories
 * @summary Get document categories
 */
export const getDocumentCategories = (
  headers?: GetDocumentCategoriesHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<DocumentCategoryV1[]>({
    url: `/fund-products/v1/document-categories`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetDocumentCategoriesQueryKey = () => {
  return [`/fund-products/v1/document-categories`] as const
}

export const getGetDocumentCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentCategories>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetDocumentCategoriesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentCategories>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDocumentCategoriesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentCategories>>
  > = ({ signal }) => getDocumentCategories(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentCategories>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetDocumentCategoriesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentCategories>>
>
export type GetDocumentCategoriesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get document categories
 */
export const useGetDocumentCategories = <
  TData = Awaited<ReturnType<typeof getDocumentCategories>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetDocumentCategoriesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentCategories>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentCategoriesQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Get Mercer service documents
 * @summary Get Mercer service documents
 */
export const getMercerDocuments = (
  headers?: GetMercerDocumentsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<MercerServicesDocumentV1[]>({
    url: `/fund-products/v1/mercer-documents`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetMercerDocumentsQueryKey = () => {
  return [`/fund-products/v1/mercer-documents`] as const
}

export const getGetMercerDocumentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getMercerDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetMercerDocumentsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMercerDocuments>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMercerDocumentsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMercerDocuments>>
  > = ({ signal }) => getMercerDocuments(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getMercerDocuments>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMercerDocumentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMercerDocuments>>
>
export type GetMercerDocumentsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Mercer service documents
 */
export const useGetMercerDocuments = <
  TData = Awaited<ReturnType<typeof getMercerDocuments>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetMercerDocumentsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMercerDocuments>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMercerDocumentsQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Create or replace mercer services document
 * @summary Create or replace mercer services document
 */
export const upsertMercerServicesDocument = (
  upsertMercerServicesDocumentBody: UpsertMercerServicesDocumentBody,
  params: UpsertMercerServicesDocumentParams,
  headers?: UpsertMercerServicesDocumentHeaders
) => {
  const formData = new FormData()
  formData.append('file', upsertMercerServicesDocumentBody.file)

  return fetchInstance<MercerServicesDocumentV1>({
    url: `/fund-products/v1/mercer-documents`,
    method: 'PUT',
    headers: { 'Content-Type': 'multipart/form-data', ...headers },
    data: formData,
    params,
  })
}

export const getUpsertMercerServicesDocumentMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertMercerServicesDocument>>,
    TError,
    {
      data: UpsertMercerServicesDocumentBody
      params: UpsertMercerServicesDocumentParams
      headers?: UpsertMercerServicesDocumentHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof upsertMercerServicesDocument>>,
  TError,
  {
    data: UpsertMercerServicesDocumentBody
    params: UpsertMercerServicesDocumentParams
    headers?: UpsertMercerServicesDocumentHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof upsertMercerServicesDocument>>,
    {
      data: UpsertMercerServicesDocumentBody
      params: UpsertMercerServicesDocumentParams
      headers?: UpsertMercerServicesDocumentHeaders
    }
  > = (props) => {
    const { data, params, headers } = props ?? {}

    return upsertMercerServicesDocument(data, params, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpsertMercerServicesDocumentMutationResult = NonNullable<
  Awaited<ReturnType<typeof upsertMercerServicesDocument>>
>
export type UpsertMercerServicesDocumentMutationBody =
  UpsertMercerServicesDocumentBody
export type UpsertMercerServicesDocumentMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create or replace mercer services document
 */
export const useUpsertMercerServicesDocument = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof upsertMercerServicesDocument>>,
    TError,
    {
      data: UpsertMercerServicesDocumentBody
      params: UpsertMercerServicesDocumentParams
      headers?: UpsertMercerServicesDocumentHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpsertMercerServicesDocumentMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get Mercer service document by type
 * @summary Get Mercer service document by type
 */
export const getMercerDocument = (
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  headers?: GetMercerDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<MercerServicesDocumentV1>({
    url: `/fund-products/v1/mercer-documents/${type}`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetMercerDocumentQueryKey = (
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES'
) => {
  return [`/fund-products/v1/mercer-documents/${type}`] as const
}

export const getGetMercerDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof getMercerDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  headers?: GetMercerDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMercerDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMercerDocumentQueryKey(type)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMercerDocument>>
  > = ({ signal }) => getMercerDocument(type, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!type,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getMercerDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetMercerDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMercerDocument>>
>
export type GetMercerDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get Mercer service document by type
 */
export const useGetMercerDocument = <
  TData = Awaited<ReturnType<typeof getMercerDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  headers?: GetMercerDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMercerDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMercerDocumentQueryOptions(type, headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Download a Mercer service document by type
 * @summary Download a Mercer service document by type
 */
export const downloadMercerDocument = (
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  params?: DownloadMercerDocumentParams,
  headers?: DownloadMercerDocumentHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<DocumentDownloadLink>({
    url: `/fund-products/v1/mercer-documents/${type}/download`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getDownloadMercerDocumentQueryKey = (
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  params?: DownloadMercerDocumentParams
) => {
  return [
    `/fund-products/v1/mercer-documents/${type}/download`,
    ...(params ? [params] : []),
  ] as const
}

export const getDownloadMercerDocumentQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadMercerDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  params?: DownloadMercerDocumentParams,
  headers?: DownloadMercerDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadMercerDocument>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getDownloadMercerDocumentQueryKey(type, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadMercerDocument>>
  > = ({ signal }) => downloadMercerDocument(type, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!type,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadMercerDocument>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DownloadMercerDocumentQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadMercerDocument>>
>
export type DownloadMercerDocumentQueryError = ErrorType<ErrorResponse>

/**
 * @summary Download a Mercer service document by type
 */
export const useDownloadMercerDocument = <
  TData = Awaited<ReturnType<typeof downloadMercerDocument>>,
  TError = ErrorType<ErrorResponse>
>(
  type: 'MERCER_ADVISOR_SERVICES' | 'MERCER_INVESTMENT_SERVICES',
  params?: DownloadMercerDocumentParams,
  headers?: DownloadMercerDocumentHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadMercerDocument>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadMercerDocumentQueryOptions(
    type,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Updates an existing fund product or creates a new one (if the if-match header is not provided)
 * @summary Updates an existing fund product or creates a new one
 */
export const updateFundProduct = (
  id: string,
  fundProductAdminRequestV1: FundProductAdminRequestV1,
  headers?: UpdateFundProductHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products/${id}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductAdminRequestV1,
  })
}

export const getUpdateFundProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundProduct>>,
    TError,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: UpdateFundProductHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundProduct>>,
  TError,
  {
    id: string
    data: FundProductAdminRequestV1
    headers?: UpdateFundProductHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundProduct>>,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: UpdateFundProductHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return updateFundProduct(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundProduct>>
>
export type UpdateFundProductMutationBody = FundProductAdminRequestV1
export type UpdateFundProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Updates an existing fund product or creates a new one
 */
export const useUpdateFundProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundProduct>>,
    TError,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: UpdateFundProductHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFundProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Patch (partially update) a fund product
 * @summary Patch (partially update) a fund product
 */
export const patchFundProduct = (
  id: string,
  patchRequest: PatchRequest,
  headers?: PatchFundProductHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products/${id}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: patchRequest,
  })
}

export const getPatchFundProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundProduct>>,
    TError,
    { id: string; data: PatchRequest; headers?: PatchFundProductHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchFundProduct>>,
  TError,
  { id: string; data: PatchRequest; headers?: PatchFundProductHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchFundProduct>>,
    { id: string; data: PatchRequest; headers?: PatchFundProductHeaders }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return patchFundProduct(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchFundProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchFundProduct>>
>
export type PatchFundProductMutationBody = PatchRequest
export type PatchFundProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch (partially update) a fund product
 */
export const usePatchFundProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundProduct>>,
    TError,
    { id: string; data: PatchRequest; headers?: PatchFundProductHeaders },
    TContext
  >
}) => {
  const mutationOptions = getPatchFundProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Publish a fund product
 * @summary Publish a fund product
 */
export const publishFundProduct = (
  id: string,
  params?: PublishFundProductParams,
  headers?: PublishFundProductHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/publish`,
    method: 'PUT',
    headers,
    params,
  })
}

export const getPublishFundProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProduct>>,
    TError,
    {
      id: string
      params?: PublishFundProductParams
      headers?: PublishFundProductHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishFundProduct>>,
  TError,
  {
    id: string
    params?: PublishFundProductParams
    headers?: PublishFundProductHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishFundProduct>>,
    {
      id: string
      params?: PublishFundProductParams
      headers?: PublishFundProductHeaders
    }
  > = (props) => {
    const { id, params, headers } = props ?? {}

    return publishFundProduct(id, params, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishFundProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishFundProduct>>
>

export type PublishFundProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Publish a fund product
 */
export const usePublishFundProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProduct>>,
    TError,
    {
      id: string
      params?: PublishFundProductParams
      headers?: PublishFundProductHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getPublishFundProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Publish a fund product share class
 * @summary Publish a fund product share class
 */
export const publishShareClass = (
  id: string,
  shareClassId: string,
  headers?: PublishShareClassHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/share-class/${shareClassId}/publish`,
    method: 'PUT',
    headers,
  })
}

export const getPublishShareClassMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishShareClass>>,
    TError,
    { id: string; shareClassId: string; headers?: PublishShareClassHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishShareClass>>,
  TError,
  { id: string; shareClassId: string; headers?: PublishShareClassHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishShareClass>>,
    { id: string; shareClassId: string; headers?: PublishShareClassHeaders }
  > = (props) => {
    const { id, shareClassId, headers } = props ?? {}

    return publishShareClass(id, shareClassId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishShareClassMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishShareClass>>
>

export type PublishShareClassMutationError = ErrorType<ErrorResponse>

/**
 * @summary Publish a fund product share class
 */
export const usePublishShareClass = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishShareClass>>,
    TError,
    { id: string; shareClassId: string; headers?: PublishShareClassHeaders },
    TContext
  >
}) => {
  const mutationOptions = getPublishShareClassMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Calculate close dates and due dates
 * @summary Calculate close dates and due dates
 */
export const calculateCloseDatesAndDueDates = (
  fundCloseAndDueDateCalculationRequest: FundCloseAndDueDateCalculationRequest,
  headers?: CalculateCloseDatesAndDueDatesHeaders
) => {
  return fetchInstance<FundCloseAndDueDateBlock[]>({
    url: `/fund-products/v1/admin/fund-products/close-dates-and-due-dates`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundCloseAndDueDateCalculationRequest,
  })
}

export const getCalculateCloseDatesAndDueDatesQueryKey = (
  fundCloseAndDueDateCalculationRequest: FundCloseAndDueDateCalculationRequest
) => {
  return [
    `/fund-products/v1/admin/fund-products/close-dates-and-due-dates`,
    fundCloseAndDueDateCalculationRequest,
  ] as const
}

export const getCalculateCloseDatesAndDueDatesQueryOptions = <
  TData = Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>,
  TError = ErrorType<ErrorResponse>
>(
  fundCloseAndDueDateCalculationRequest: FundCloseAndDueDateCalculationRequest,
  headers?: CalculateCloseDatesAndDueDatesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getCalculateCloseDatesAndDueDatesQueryKey(
      fundCloseAndDueDateCalculationRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>
  > = () =>
    calculateCloseDatesAndDueDates(
      fundCloseAndDueDateCalculationRequest,
      headers
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CalculateCloseDatesAndDueDatesQueryResult = NonNullable<
  Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>
>
export type CalculateCloseDatesAndDueDatesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Calculate close dates and due dates
 */
export const useCalculateCloseDatesAndDueDates = <
  TData = Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>,
  TError = ErrorType<ErrorResponse>
>(
  fundCloseAndDueDateCalculationRequest: FundCloseAndDueDateCalculationRequest,
  headers?: CalculateCloseDatesAndDueDatesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof calculateCloseDatesAndDueDates>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCalculateCloseDatesAndDueDatesQueryOptions(
    fundCloseAndDueDateCalculationRequest,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Recalculate and save close dates and due dates for existing funds
 * @summary Recalculate and save close dates and due dates for existing funds
 */
export const recalculateAndSaveCloseDatesAndDueDates = (
  fundCloseAndDueDateRecalculationRequest: FundCloseAndDueDateRecalculationRequest,
  headers?: RecalculateAndSaveCloseDatesAndDueDatesHeaders
) => {
  return fetchInstance<BulkPatchResponse>({
    url: `/fund-products/v1/admin/fund-products/recalculate-and-save-close-dates-and-due-dates`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundCloseAndDueDateRecalculationRequest,
  })
}

export const getRecalculateAndSaveCloseDatesAndDueDatesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalculateAndSaveCloseDatesAndDueDates>>,
    TError,
    {
      data: FundCloseAndDueDateRecalculationRequest
      headers?: RecalculateAndSaveCloseDatesAndDueDatesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof recalculateAndSaveCloseDatesAndDueDates>>,
  TError,
  {
    data: FundCloseAndDueDateRecalculationRequest
    headers?: RecalculateAndSaveCloseDatesAndDueDatesHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof recalculateAndSaveCloseDatesAndDueDates>>,
    {
      data: FundCloseAndDueDateRecalculationRequest
      headers?: RecalculateAndSaveCloseDatesAndDueDatesHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return recalculateAndSaveCloseDatesAndDueDates(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type RecalculateAndSaveCloseDatesAndDueDatesMutationResult = NonNullable<
  Awaited<ReturnType<typeof recalculateAndSaveCloseDatesAndDueDates>>
>
export type RecalculateAndSaveCloseDatesAndDueDatesMutationBody =
  FundCloseAndDueDateRecalculationRequest
export type RecalculateAndSaveCloseDatesAndDueDatesMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Recalculate and save close dates and due dates for existing funds
 */
export const useRecalculateAndSaveCloseDatesAndDueDates = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof recalculateAndSaveCloseDatesAndDueDates>>,
    TError,
    {
      data: FundCloseAndDueDateRecalculationRequest
      headers?: RecalculateAndSaveCloseDatesAndDueDatesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getRecalculateAndSaveCloseDatesAndDueDatesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:advisor-read`

Returns the number of funds matching the filter criteria
 * @deprecated
 * @summary Returns the number of funds matching the filter criteria
 */
export const countFundProductsDeprecated = (
  params?: CountFundProductsDeprecatedParams,
  headers?: CountFundProductsDeprecatedHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<CountResult>({
    url: `/fund-products/v1/fund-products/count`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getCountFundProductsDeprecatedQueryKey = (
  params?: CountFundProductsDeprecatedParams
) => {
  return [
    `/fund-products/v1/fund-products/count`,
    ...(params ? [params] : []),
  ] as const
}

export const getCountFundProductsDeprecatedQueryOptions = <
  TData = Awaited<ReturnType<typeof countFundProductsDeprecated>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: CountFundProductsDeprecatedParams,
  headers?: CountFundProductsDeprecatedHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof countFundProductsDeprecated>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getCountFundProductsDeprecatedQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof countFundProductsDeprecated>>
  > = ({ signal }) => countFundProductsDeprecated(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof countFundProductsDeprecated>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CountFundProductsDeprecatedQueryResult = NonNullable<
  Awaited<ReturnType<typeof countFundProductsDeprecated>>
>
export type CountFundProductsDeprecatedQueryError = ErrorType<ErrorResponse>

/**
 * @deprecated
 * @summary Returns the number of funds matching the filter criteria
 */
export const useCountFundProductsDeprecated = <
  TData = Awaited<ReturnType<typeof countFundProductsDeprecated>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: CountFundProductsDeprecatedParams,
  headers?: CountFundProductsDeprecatedHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof countFundProductsDeprecated>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCountFundProductsDeprecatedQueryOptions(
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:advisor-read`

Returns the number of funds matching the filter criteria
 * @summary Returns the number of funds matching the filter criteria
 */
export const countFundProducts = (
  fundProductFilterV1: FundProductFilterV1,
  headers?: CountFundProductsHeaders
) => {
  return fetchInstance<CountResult>({
    url: `/fund-products/v1/fund-products/count`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductFilterV1,
  })
}

export const getCountFundProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof countFundProducts>>,
    TError,
    { data: FundProductFilterV1; headers?: CountFundProductsHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof countFundProducts>>,
  TError,
  { data: FundProductFilterV1; headers?: CountFundProductsHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof countFundProducts>>,
    { data: FundProductFilterV1; headers?: CountFundProductsHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return countFundProducts(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type CountFundProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof countFundProducts>>
>
export type CountFundProductsMutationBody = FundProductFilterV1
export type CountFundProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Returns the number of funds matching the filter criteria
 */
export const useCountFundProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof countFundProducts>>,
    TError,
    { data: FundProductFilterV1; headers?: CountFundProductsHeaders },
    TContext
  >
}) => {
  const mutationOptions = getCountFundProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:advisor-read`

Get funds product (consumer facing model).
 * @summary Get funds product (consumer facing model)
 */
export const getFundProduct = (
  id: string,
  params?: GetFundProductParams,
  headers?: GetFundProductHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductV1>({
    url: `/fund-products/v1/fund-products/${id}`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundProductQueryKey = (
  id: string,
  params?: GetFundProductParams
) => {
  return [
    `/fund-products/v1/fund-products/${id}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundProductParams,
  headers?: GetFundProductHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProduct>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundProductQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFundProduct>>> = ({
    signal,
  }) => getFundProduct(id, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProduct>>
>
export type GetFundProductQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get funds product (consumer facing model)
 */
export const useGetFundProduct = <
  TData = Awaited<ReturnType<typeof getFundProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundProductParams,
  headers?: GetFundProductHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProduct>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductQueryOptions(
    id,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Get all the fund products allowed for a given user using m2m token
 * @summary Get all the fund products allowed for a given user using m2m token
 */
export const getFundProductsByUserId = (
  userId: string,
  params?: GetFundProductsByUserIdParams,
  headers?: GetFundProductsByUserIdHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductPageV1>({
    url: `/fund-products/v1/users/${userId}/fund-products`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundProductsByUserIdQueryKey = (
  userId: string,
  params?: GetFundProductsByUserIdParams
) => {
  return [
    `/fund-products/v1/users/${userId}/fund-products`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundProductsByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductsByUserId>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params?: GetFundProductsByUserIdParams,
  headers?: GetFundProductsByUserIdHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductsByUserId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundProductsByUserIdQueryKey(userId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductsByUserId>>
  > = ({ signal }) => getFundProductsByUserId(userId, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!userId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductsByUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductsByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductsByUserId>>
>
export type GetFundProductsByUserIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get all the fund products allowed for a given user using m2m token
 */
export const useGetFundProductsByUserId = <
  TData = Awaited<ReturnType<typeof getFundProductsByUserId>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  params?: GetFundProductsByUserIdParams,
  headers?: GetFundProductsByUserIdHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductsByUserId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductsByUserIdQueryOptions(
    userId,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Get funds product by id for a given user using m2m token
 * @summary Get funds product by id for a given user using m2m token
 */
export const getFundProductByUserId = (
  userId: string,
  id: string,
  params?: GetFundProductByUserIdParams,
  headers?: GetFundProductByUserIdHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductV1>({
    url: `/fund-products/v1/users/${userId}/fund-products/${id}`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundProductByUserIdQueryKey = (
  userId: string,
  id: string,
  params?: GetFundProductByUserIdParams
) => {
  return [
    `/fund-products/v1/users/${userId}/fund-products/${id}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundProductByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductByUserId>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  id: string,
  params?: GetFundProductByUserIdParams,
  headers?: GetFundProductByUserIdHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductByUserId>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundProductByUserIdQueryKey(userId, id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductByUserId>>
  > = ({ signal }) =>
    getFundProductByUserId(userId, id, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(userId && id),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductByUserId>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductByUserId>>
>
export type GetFundProductByUserIdQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get funds product by id for a given user using m2m token
 */
export const useGetFundProductByUserId = <
  TData = Awaited<ReturnType<typeof getFundProductByUserId>>,
  TError = ErrorType<ErrorResponse>
>(
  userId: string,
  id: string,
  params?: GetFundProductByUserIdParams,
  headers?: GetFundProductByUserIdHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductByUserId>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductByUserIdQueryOptions(
    userId,
    id,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:advisor-read`

Get funds product IDs and names allowed to show to consumer (advisor-facing)
 * @summary Get funds product IDs, names, and actions allowed to show to consumer (advisor-facing)
 */
export const getFundProductIdsAndNames = (
  headers?: GetFundProductIdsAndNamesHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductIdsAndNames>({
    url: `/fund-products/v1/fund-products/ids-names`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundProductIdsAndNamesQueryKey = () => {
  return [`/fund-products/v1/fund-products/ids-names`] as const
}

export const getGetFundProductIdsAndNamesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductIdsAndNames>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFundProductIdsAndNamesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductIdsAndNames>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundProductIdsAndNamesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductIdsAndNames>>
  > = ({ signal }) => getFundProductIdsAndNames(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductIdsAndNames>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductIdsAndNamesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductIdsAndNames>>
>
export type GetFundProductIdsAndNamesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get funds product IDs, names, and actions allowed to show to consumer (advisor-facing)
 */
export const useGetFundProductIdsAndNames = <
  TData = Awaited<ReturnType<typeof getFundProductIdsAndNames>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFundProductIdsAndNamesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductIdsAndNames>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductIdsAndNamesQueryOptions(
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** admin scope

Search funds product IDs, names, and actions allowed to show to consumer
 * @summary Search funds product IDs, names, and actions allowed to show to consumer
 */
export const searchFundProductIdsAndNames = (
  searchFundProductIdsAndNamesRequestV1: SearchFundProductIdsAndNamesRequestV1,
  headers?: SearchFundProductIdsAndNamesHeaders
) => {
  return fetchInstance<FundProductIdsAndNames>({
    url: `/fund-products/v1/fund-products/ids-names/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: searchFundProductIdsAndNamesRequestV1,
  })
}

export const getSearchFundProductIdsAndNamesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundProductIdsAndNames>>,
    TError,
    {
      data: SearchFundProductIdsAndNamesRequestV1
      headers?: SearchFundProductIdsAndNamesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFundProductIdsAndNames>>,
  TError,
  {
    data: SearchFundProductIdsAndNamesRequestV1
    headers?: SearchFundProductIdsAndNamesHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFundProductIdsAndNames>>,
    {
      data: SearchFundProductIdsAndNamesRequestV1
      headers?: SearchFundProductIdsAndNamesHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return searchFundProductIdsAndNames(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFundProductIdsAndNamesMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFundProductIdsAndNames>>
>
export type SearchFundProductIdsAndNamesMutationBody =
  SearchFundProductIdsAndNamesRequestV1
export type SearchFundProductIdsAndNamesMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search funds product IDs, names, and actions allowed to show to consumer
 */
export const useSearchFundProductIdsAndNames = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundProductIdsAndNames>>,
    TError,
    {
      data: SearchFundProductIdsAndNamesRequestV1
      headers?: SearchFundProductIdsAndNamesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchFundProductIdsAndNamesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get the firm configuration for a fund
 * @summary Get all firm configurations for a fund
 */
export const getFundFirmConfigurations = (
  id: string,
  params?: GetFundFirmConfigurationsParams,
  headers?: GetFundFirmConfigurationsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundFirmConfigurationV1[]>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundFirmConfigurationsQueryKey = (
  id: string,
  params?: GetFundFirmConfigurationsParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/firm-configuration`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundFirmConfigurationsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundFirmConfigurations>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundFirmConfigurationsParams,
  headers?: GetFundFirmConfigurationsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundFirmConfigurations>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundFirmConfigurationsQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundFirmConfigurations>>
  > = ({ signal }) => getFundFirmConfigurations(id, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundFirmConfigurations>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundFirmConfigurationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundFirmConfigurations>>
>
export type GetFundFirmConfigurationsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get all firm configurations for a fund
 */
export const useGetFundFirmConfigurations = <
  TData = Awaited<ReturnType<typeof getFundFirmConfigurations>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params?: GetFundFirmConfigurationsParams,
  headers?: GetFundFirmConfigurationsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundFirmConfigurations>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundFirmConfigurationsQueryOptions(
    id,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Bulk patch the firm configuration for a fund
 * @summary Bulk patch the firm configuration for a fund
 */
export const bulkPatchFundFirmConfigurations = (
  id: string,
  patchDocument: PatchDocument[],
  headers?: BulkPatchFundFirmConfigurationsHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: patchDocument,
  })
}

export const getBulkPatchFundFirmConfigurationsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkPatchFundFirmConfigurations>>,
    TError,
    {
      id: string
      data: PatchDocument[]
      headers?: BulkPatchFundFirmConfigurationsHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkPatchFundFirmConfigurations>>,
  TError,
  {
    id: string
    data: PatchDocument[]
    headers?: BulkPatchFundFirmConfigurationsHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkPatchFundFirmConfigurations>>,
    {
      id: string
      data: PatchDocument[]
      headers?: BulkPatchFundFirmConfigurationsHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return bulkPatchFundFirmConfigurations(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkPatchFundFirmConfigurationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkPatchFundFirmConfigurations>>
>
export type BulkPatchFundFirmConfigurationsMutationBody = PatchDocument[]
export type BulkPatchFundFirmConfigurationsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Bulk patch the firm configuration for a fund
 */
export const useBulkPatchFundFirmConfigurations = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkPatchFundFirmConfigurations>>,
    TError,
    {
      id: string
      data: PatchDocument[]
      headers?: BulkPatchFundFirmConfigurationsHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getBulkPatchFundFirmConfigurationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get the firm configuration for a fund
 * @summary Get the firm configuration for a fund
 */
export const getFundFirmConfiguration = (
  id: string,
  firmId: string,
  params?: GetFundFirmConfigurationParams,
  headers?: GetFundFirmConfigurationHeaders,
  signal?: AbortSignal
) => {
  return fetchResponse<FundFirmConfigurationV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration/${firmId}`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundFirmConfigurationQueryKey = (
  id: string,
  firmId: string,
  params?: GetFundFirmConfigurationParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/firm-configuration/${firmId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundFirmConfigurationQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundFirmConfiguration>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  firmId: string,
  params?: GetFundFirmConfigurationParams,
  headers?: GetFundFirmConfigurationHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundFirmConfiguration>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundFirmConfigurationQueryKey(id, firmId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundFirmConfiguration>>
  > = ({ signal }) =>
    getFundFirmConfiguration(id, firmId, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && firmId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundFirmConfiguration>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundFirmConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundFirmConfiguration>>
>
export type GetFundFirmConfigurationQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get the firm configuration for a fund
 */
export const useGetFundFirmConfiguration = <
  TData = Awaited<ReturnType<typeof getFundFirmConfiguration>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  firmId: string,
  params?: GetFundFirmConfigurationParams,
  headers?: GetFundFirmConfigurationHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundFirmConfiguration>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundFirmConfigurationQueryOptions(
    id,
    firmId,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Update the firm configuration for a fund
 * @summary Update the firm configuration for a fund
 */
export const updateFundFirmConfiguration = (
  id: string,
  firmId: string,
  fundFirmConfigurationConfigV1: FundFirmConfigurationConfigV1,
  headers?: UpdateFundFirmConfigurationHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration/${firmId}`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundFirmConfigurationConfigV1,
  })
}

export const getUpdateFundFirmConfigurationMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      data: FundFirmConfigurationConfigV1
      headers?: UpdateFundFirmConfigurationHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundFirmConfiguration>>,
  TError,
  {
    id: string
    firmId: string
    data: FundFirmConfigurationConfigV1
    headers?: UpdateFundFirmConfigurationHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundFirmConfiguration>>,
    {
      id: string
      firmId: string
      data: FundFirmConfigurationConfigV1
      headers?: UpdateFundFirmConfigurationHeaders
    }
  > = (props) => {
    const { id, firmId, data, headers } = props ?? {}

    return updateFundFirmConfiguration(id, firmId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundFirmConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundFirmConfiguration>>
>
export type UpdateFundFirmConfigurationMutationBody =
  FundFirmConfigurationConfigV1
export type UpdateFundFirmConfigurationMutationError = ErrorType<ErrorResponse>

/**
 * @summary Update the firm configuration for a fund
 */
export const useUpdateFundFirmConfiguration = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      data: FundFirmConfigurationConfigV1
      headers?: UpdateFundFirmConfigurationHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getUpdateFundFirmConfigurationMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Remove the firm configuration from a fund
 * @summary Remove the firm configuration from a fund
 */
export const deleteFundFirmConfiguration = (
  id: string,
  firmId: string,
  headers?: DeleteFundFirmConfigurationHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration/${firmId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteFundFirmConfigurationMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      headers?: DeleteFundFirmConfigurationHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFundFirmConfiguration>>,
  TError,
  { id: string; firmId: string; headers?: DeleteFundFirmConfigurationHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFundFirmConfiguration>>,
    { id: string; firmId: string; headers?: DeleteFundFirmConfigurationHeaders }
  > = (props) => {
    const { id, firmId, headers } = props ?? {}

    return deleteFundFirmConfiguration(id, firmId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFundFirmConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundFirmConfiguration>>
>

export type DeleteFundFirmConfigurationMutationError = ErrorType<ErrorResponse>

/**
 * @summary Remove the firm configuration from a fund
 */
export const useDeleteFundFirmConfiguration = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      headers?: DeleteFundFirmConfigurationHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFundFirmConfigurationMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Bulk patch the firm configuration for a fund
 * @summary Patch the firm configuration for a fund
 */
export const patchFundFirmConfiguration = (
  id: string,
  firmId: string,
  patchDocument: PatchDocument[],
  headers?: PatchFundFirmConfigurationHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/firm-configuration/${firmId}`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: patchDocument,
  })
}

export const getPatchFundFirmConfigurationMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      data: PatchDocument[]
      headers?: PatchFundFirmConfigurationHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchFundFirmConfiguration>>,
  TError,
  {
    id: string
    firmId: string
    data: PatchDocument[]
    headers?: PatchFundFirmConfigurationHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchFundFirmConfiguration>>,
    {
      id: string
      firmId: string
      data: PatchDocument[]
      headers?: PatchFundFirmConfigurationHeaders
    }
  > = (props) => {
    const { id, firmId, data, headers } = props ?? {}

    return patchFundFirmConfiguration(id, firmId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchFundFirmConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchFundFirmConfiguration>>
>
export type PatchFundFirmConfigurationMutationBody = PatchDocument[]
export type PatchFundFirmConfigurationMutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch the firm configuration for a fund
 */
export const usePatchFundFirmConfiguration = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundFirmConfiguration>>,
    TError,
    {
      id: string
      firmId: string
      data: PatchDocument[]
      headers?: PatchFundFirmConfigurationHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getPatchFundFirmConfigurationMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Delete fund product variant
 * @summary Delete fund product variant
 */
export const deleteFundProductVariant = (
  id: string,
  firmId: string,
  headers?: DeleteFundProductVariantHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/variants/${firmId}`,
    method: 'DELETE',
    headers,
  })
}

export const getDeleteFundProductVariantMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundProductVariant>>,
    TError,
    { id: string; firmId: string; headers?: DeleteFundProductVariantHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteFundProductVariant>>,
  TError,
  { id: string; firmId: string; headers?: DeleteFundProductVariantHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteFundProductVariant>>,
    { id: string; firmId: string; headers?: DeleteFundProductVariantHeaders }
  > = (props) => {
    const { id, firmId, headers } = props ?? {}

    return deleteFundProductVariant(id, firmId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteFundProductVariantMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteFundProductVariant>>
>

export type DeleteFundProductVariantMutationError = ErrorType<ErrorResponse>

/**
 * @summary Delete fund product variant
 */
export const useDeleteFundProductVariant = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteFundProductVariant>>,
    TError,
    { id: string; firmId: string; headers?: DeleteFundProductVariantHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDeleteFundProductVariantMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get fund product variant overrides
 * @summary Get fund product variant overrides
 */
export const getFundProductVariantOverrides = (
  id: string,
  firmId: string,
  headers?: GetFundProductVariantOverridesHeaders,
  signal?: AbortSignal
) => {
  return fetchResponse<FundVariantOverridesV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/variants/${firmId}/overrides`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundProductVariantOverridesQueryKey = (
  id: string,
  firmId: string
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/variants/${firmId}/overrides`,
  ] as const
}

export const getGetFundProductVariantOverridesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductVariantOverrides>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  firmId: string,
  headers?: GetFundProductVariantOverridesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductVariantOverrides>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetFundProductVariantOverridesQueryKey(id, firmId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductVariantOverrides>>
  > = ({ signal }) =>
    getFundProductVariantOverrides(id, firmId, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!(id && firmId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductVariantOverrides>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductVariantOverridesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductVariantOverrides>>
>
export type GetFundProductVariantOverridesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get fund product variant overrides
 */
export const useGetFundProductVariantOverrides = <
  TData = Awaited<ReturnType<typeof getFundProductVariantOverrides>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  firmId: string,
  headers?: GetFundProductVariantOverridesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductVariantOverrides>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductVariantOverridesQueryOptions(
    id,
    firmId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Create a fund product variant override
 * @summary Update fund product variant overrides
 */
export const updateFundProductVariantOverrides = (
  id: string,
  firmId: string,
  fundVariantOverridesV1: FundVariantOverridesV1,
  headers?: UpdateFundProductVariantOverridesHeaders
) => {
  return fetchResponse<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/variants/${firmId}/overrides`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundVariantOverridesV1,
  })
}

export const getUpdateFundProductVariantOverridesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundProductVariantOverrides>>,
    TError,
    {
      id: string
      firmId: string
      data: FundVariantOverridesV1
      headers?: UpdateFundProductVariantOverridesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateFundProductVariantOverrides>>,
  TError,
  {
    id: string
    firmId: string
    data: FundVariantOverridesV1
    headers?: UpdateFundProductVariantOverridesHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateFundProductVariantOverrides>>,
    {
      id: string
      firmId: string
      data: FundVariantOverridesV1
      headers?: UpdateFundProductVariantOverridesHeaders
    }
  > = (props) => {
    const { id, firmId, data, headers } = props ?? {}

    return updateFundProductVariantOverrides(id, firmId, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateFundProductVariantOverridesMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateFundProductVariantOverrides>>
>
export type UpdateFundProductVariantOverridesMutationBody =
  FundVariantOverridesV1
export type UpdateFundProductVariantOverridesMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Update fund product variant overrides
 */
export const useUpdateFundProductVariantOverrides = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateFundProductVariantOverrides>>,
    TError,
    {
      id: string
      firmId: string
      data: FundVariantOverridesV1
      headers?: UpdateFundProductVariantOverridesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateFundProductVariantOverridesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-write`

Create a fund product
 * @summary Create a fund product
 */
export const createFundProduct = (
  fundProductAdminRequestV1: FundProductAdminRequestV1,
  headers?: CreateFundProductHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductAdminRequestV1,
  })
}

export const getCreateFundProductMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProduct>>,
    TError,
    { data: FundProductAdminRequestV1; headers?: CreateFundProductHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof createFundProduct>>,
  TError,
  { data: FundProductAdminRequestV1; headers?: CreateFundProductHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createFundProduct>>,
    { data: FundProductAdminRequestV1; headers?: CreateFundProductHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return createFundProduct(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type CreateFundProductMutationResult = NonNullable<
  Awaited<ReturnType<typeof createFundProduct>>
>
export type CreateFundProductMutationBody = FundProductAdminRequestV1
export type CreateFundProductMutationError = ErrorType<ErrorResponse>

/**
 * @summary Create a fund product
 */
export const useCreateFundProduct = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createFundProduct>>,
    TError,
    { data: FundProductAdminRequestV1; headers?: CreateFundProductHeaders },
    TContext
  >
}) => {
  const mutationOptions = getCreateFundProductMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-write` 

Bulk patch (partially update) multiple fund products
 * @summary Bulk patch (partially update) multiple fund products
 */
export const bulkPatchFundProducts = (
  patchRequest: PatchRequest,
  headers?: BulkPatchFundProductsHeaders
) => {
  return fetchInstance<BulkPatchResponse>({
    url: `/fund-products/v1/admin/fund-products`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: patchRequest,
  })
}

export const getBulkPatchFundProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkPatchFundProducts>>,
    TError,
    { data: PatchRequest; headers?: BulkPatchFundProductsHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkPatchFundProducts>>,
  TError,
  { data: PatchRequest; headers?: BulkPatchFundProductsHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkPatchFundProducts>>,
    { data: PatchRequest; headers?: BulkPatchFundProductsHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return bulkPatchFundProducts(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkPatchFundProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkPatchFundProducts>>
>
export type BulkPatchFundProductsMutationBody = PatchRequest
export type BulkPatchFundProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk patch (partially update) multiple fund products
 */
export const useBulkPatchFundProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkPatchFundProducts>>,
    TError,
    { data: PatchRequest; headers?: BulkPatchFundProductsHeaders },
    TContext
  >
}) => {
  const mutationOptions = getBulkPatchFundProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:advisor-read`

Search for multiple fund product aggregations
 * @summary Search for multiple fund product aggregations
 */
export const searchFundProductAggregationsList = (
  fundProductFilterV1: FundProductFilterV1[],
  headers?: SearchFundProductAggregationsListHeaders
) => {
  return fetchInstance<FundProductAggregationsV1[]>({
    url: `/fund-products/v1/fund-products/aggregations/list`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductFilterV1,
  })
}

export const getSearchFundProductAggregationsListQueryKey = (
  fundProductFilterV1: FundProductFilterV1[]
) => {
  return [
    `/fund-products/v1/fund-products/aggregations/list`,
    fundProductFilterV1,
  ] as const
}

export const getSearchFundProductAggregationsListQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFundProductAggregationsList>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductFilterV1: FundProductFilterV1[],
  headers?: SearchFundProductAggregationsListHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFundProductAggregationsList>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchFundProductAggregationsListQueryKey(fundProductFilterV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFundProductAggregationsList>>
  > = () => searchFundProductAggregationsList(fundProductFilterV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchFundProductAggregationsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchFundProductAggregationsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFundProductAggregationsList>>
>
export type SearchFundProductAggregationsListQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Search for multiple fund product aggregations
 */
export const useSearchFundProductAggregationsList = <
  TData = Awaited<ReturnType<typeof searchFundProductAggregationsList>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductFilterV1: FundProductFilterV1[],
  headers?: SearchFundProductAggregationsListHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFundProductAggregationsList>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchFundProductAggregationsListQueryOptions(
    fundProductFilterV1,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:advisor-read`

Search for fund product aggregations
 * @summary Search for fund product aggregations
 */
export const searchFundProductAggregations = (
  fundProductFilterV1: FundProductFilterV1,
  headers?: SearchFundProductAggregationsHeaders
) => {
  return fetchInstance<FundProductAggregationsV1>({
    url: `/fund-products/v1/fund-products/aggregations`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductFilterV1,
  })
}

export const getSearchFundProductAggregationsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundProductAggregations>>,
    TError,
    {
      data: FundProductFilterV1
      headers?: SearchFundProductAggregationsHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFundProductAggregations>>,
  TError,
  { data: FundProductFilterV1; headers?: SearchFundProductAggregationsHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFundProductAggregations>>,
    {
      data: FundProductFilterV1
      headers?: SearchFundProductAggregationsHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return searchFundProductAggregations(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFundProductAggregationsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFundProductAggregations>>
>
export type SearchFundProductAggregationsMutationBody = FundProductFilterV1
export type SearchFundProductAggregationsMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Search for fund product aggregations
 */
export const useSearchFundProductAggregations = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundProductAggregations>>,
    TError,
    {
      data: FundProductFilterV1
      headers?: SearchFundProductAggregationsHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getSearchFundProductAggregationsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:advisor-read`

Search for fund products
 * @summary Search for fund products
 */
export const searchFundProducts = (
  fundProductsSearchRequestV1: FundProductsSearchRequestV1,
  headers?: SearchFundProductsHeaders
) => {
  return fetchInstance<FundProductPageV1>({
    url: `/fund-products/v1/fund-products/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductsSearchRequestV1,
  })
}

export const getSearchFundProductsQueryKey = (
  fundProductsSearchRequestV1: FundProductsSearchRequestV1
) => {
  return [
    `/fund-products/v1/fund-products/search`,
    fundProductsSearchRequestV1,
  ] as const
}

export const getSearchFundProductsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsSearchRequestV1: FundProductsSearchRequestV1,
  headers?: SearchFundProductsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchFundProducts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchFundProductsQueryKey(fundProductsSearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchFundProducts>>
  > = () => searchFundProducts(fundProductsSearchRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchFundProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchFundProductsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFundProducts>>
>
export type SearchFundProductsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for fund products
 */
export const useSearchFundProductsInfinite = <
  TData = Awaited<ReturnType<typeof searchFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsSearchRequestV1: FundProductsSearchRequestV1,
  headers?: SearchFundProductsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchFundProducts>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchFundProductsInfiniteQueryOptions(
    fundProductsSearchRequestV1,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Search for admin fund products
 * @summary Search for admin fund products
 */
export const searchAdminFundProducts = (
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams,
  headers?: SearchAdminFundProductsHeaders
) => {
  return fetchInstance<FundProductAdminPageV1>({
    url: `/fund-products/v1/admin/fund-products/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductsAdminSearchRequestV1,
    params,
  })
}

export const getSearchAdminFundProductsQueryKey = (
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/search`,
    ...(params ? [params] : []),
    fundProductsAdminSearchRequestV1,
  ] as const
}

export const getSearchAdminFundProductsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchAdminFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams,
  headers?: SearchAdminFundProductsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchAdminFundProducts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchAdminFundProductsQueryKey(fundProductsAdminSearchRequestV1, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchAdminFundProducts>>
  > = () =>
    searchAdminFundProducts(fundProductsAdminSearchRequestV1, params, headers)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchAdminFundProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchAdminFundProductsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchAdminFundProducts>>
>
export type SearchAdminFundProductsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for admin fund products
 */
export const useSearchAdminFundProductsInfinite = <
  TData = Awaited<ReturnType<typeof searchAdminFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams,
  headers?: SearchAdminFundProductsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchAdminFundProducts>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchAdminFundProductsInfiniteQueryOptions(
    fundProductsAdminSearchRequestV1,
    params,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchAdminFundProductsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchAdminFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams,
  headers?: SearchAdminFundProductsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAdminFundProducts>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchAdminFundProductsQueryKey(fundProductsAdminSearchRequestV1, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchAdminFundProducts>>
  > = () =>
    searchAdminFundProducts(fundProductsAdminSearchRequestV1, params, headers)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchAdminFundProducts>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchAdminFundProductsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchAdminFundProducts>>
>
export type SearchAdminFundProductsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search for admin fund products
 */
export const useSearchAdminFundProducts = <
  TData = Awaited<ReturnType<typeof searchAdminFundProducts>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductsAdminSearchRequestV1: FundProductsAdminSearchRequestV1,
  params: SearchAdminFundProductsParams,
  headers?: SearchAdminFundProductsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAdminFundProducts>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchAdminFundProductsQueryOptions(
    fundProductsAdminSearchRequestV1,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Preview an uncommitted admin fund product as consumer model
 * @summary Preview an uncommitted admin fund product as consumer model
 */
export const previewAdminFundProducts = (
  id: string,
  fundProductAdminRequestV1: FundProductAdminRequestV1,
  headers?: PreviewAdminFundProductsHeaders
) => {
  return fetchInstance<FundProductV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/preview`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductAdminRequestV1,
  })
}

export const getPreviewAdminFundProductsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof previewAdminFundProducts>>,
    TError,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: PreviewAdminFundProductsHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof previewAdminFundProducts>>,
  TError,
  {
    id: string
    data: FundProductAdminRequestV1
    headers?: PreviewAdminFundProductsHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof previewAdminFundProducts>>,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: PreviewAdminFundProductsHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return previewAdminFundProducts(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PreviewAdminFundProductsMutationResult = NonNullable<
  Awaited<ReturnType<typeof previewAdminFundProducts>>
>
export type PreviewAdminFundProductsMutationBody = FundProductAdminRequestV1
export type PreviewAdminFundProductsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Preview an uncommitted admin fund product as consumer model
 */
export const usePreviewAdminFundProducts = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof previewAdminFundProducts>>,
    TError,
    {
      id: string
      data: FundProductAdminRequestV1
      headers?: PreviewAdminFundProductsHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getPreviewAdminFundProductsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`
Get performance report for a fund product
 * @summary Get performance report for available fund products
 */
export const getPerformanceReport = (
  params?: GetPerformanceReportParams,
  headers?: GetPerformanceReportHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<Blob>({
    url: `/fund-products/v1/fund-products/performance-report`,
    method: 'GET',
    headers,
    params,
    responseType: 'blob',
    signal,
  })
}

export const getGetPerformanceReportQueryKey = (
  params?: GetPerformanceReportParams
) => {
  return [
    `/fund-products/v1/fund-products/performance-report`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetPerformanceReportQueryOptions = <
  TData = Awaited<ReturnType<typeof getPerformanceReport>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetPerformanceReportParams,
  headers?: GetPerformanceReportHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPerformanceReport>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetPerformanceReportQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPerformanceReport>>
  > = ({ signal }) => getPerformanceReport(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPerformanceReport>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPerformanceReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPerformanceReport>>
>
export type GetPerformanceReportQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get performance report for available fund products
 */
export const useGetPerformanceReport = <
  TData = Awaited<ReturnType<typeof getPerformanceReport>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetPerformanceReportParams,
  headers?: GetPerformanceReportHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPerformanceReport>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPerformanceReportQueryOptions(
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Updates the share class policy status Active/Inactive
 * @summary Updates the share class policy status Active/Inactive
 */
export const updateShareClassPolicyStatus = (
  id: string,
  fundProductShareClassPolicyStatusRequestV1: FundProductShareClassPolicyStatusRequestV1,
  headers?: UpdateShareClassPolicyStatusHeaders
) => {
  return fetchInstance<FundProductAdminV1>({
    url: `/fund-products/v1/admin/fund-products/${id}/share-class/policy-status/update`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductShareClassPolicyStatusRequestV1,
  })
}

export const getUpdateShareClassPolicyStatusMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateShareClassPolicyStatus>>,
    TError,
    {
      id: string
      data: FundProductShareClassPolicyStatusRequestV1
      headers?: UpdateShareClassPolicyStatusHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateShareClassPolicyStatus>>,
  TError,
  {
    id: string
    data: FundProductShareClassPolicyStatusRequestV1
    headers?: UpdateShareClassPolicyStatusHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateShareClassPolicyStatus>>,
    {
      id: string
      data: FundProductShareClassPolicyStatusRequestV1
      headers?: UpdateShareClassPolicyStatusHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return updateShareClassPolicyStatus(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type UpdateShareClassPolicyStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateShareClassPolicyStatus>>
>
export type UpdateShareClassPolicyStatusMutationBody =
  FundProductShareClassPolicyStatusRequestV1
export type UpdateShareClassPolicyStatusMutationError = ErrorType<ErrorResponse>

/**
 * @summary Updates the share class policy status Active/Inactive
 */
export const useUpdateShareClassPolicyStatus = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateShareClassPolicyStatus>>,
    TError,
    {
      id: string
      data: FundProductShareClassPolicyStatusRequestV1
      headers?: UpdateShareClassPolicyStatusHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getUpdateShareClassPolicyStatusMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Runs validation against all fund products and provide the output as json
 * @summary Runs validation against all fund products and provide the output as json
 */
export const runValidation = (
  headers?: RunValidationHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<RunValidation200>({
    url: `/fund-products/v1/validate-fund-products`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getRunValidationQueryKey = () => {
  return [`/fund-products/v1/validate-fund-products`] as const
}

export const getRunValidationQueryOptions = <
  TData = Awaited<ReturnType<typeof runValidation>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: RunValidationHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof runValidation>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getRunValidationQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof runValidation>>> = ({
    signal,
  }) => runValidation(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof runValidation>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type RunValidationQueryResult = NonNullable<
  Awaited<ReturnType<typeof runValidation>>
>
export type RunValidationQueryError = ErrorType<ErrorResponse>

/**
 * @summary Runs validation against all fund products and provide the output as json
 */
export const useRunValidation = <
  TData = Awaited<ReturnType<typeof runValidation>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: RunValidationHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof runValidation>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getRunValidationQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Publishes an admin event for the given fund
 * @summary Publishes an admin event for the given fund
 */
export const publishFundProductAdminEvent = (
  id: string,
  headers?: PublishFundProductAdminEventHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/${id}/publish-event`,
    method: 'POST',
    headers,
  })
}

export const getPublishFundProductAdminEventMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProductAdminEvent>>,
    TError,
    { id: string; headers?: PublishFundProductAdminEventHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishFundProductAdminEvent>>,
  TError,
  { id: string; headers?: PublishFundProductAdminEventHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishFundProductAdminEvent>>,
    { id: string; headers?: PublishFundProductAdminEventHeaders }
  > = (props) => {
    const { id, headers } = props ?? {}

    return publishFundProductAdminEvent(id, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishFundProductAdminEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishFundProductAdminEvent>>
>

export type PublishFundProductAdminEventMutationError = ErrorType<ErrorResponse>

/**
 * @summary Publishes an admin event for the given fund
 */
export const usePublishFundProductAdminEvent = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProductAdminEvent>>,
    TError,
    { id: string; headers?: PublishFundProductAdminEventHeaders },
    TContext
  >
}) => {
  const mutationOptions =
    getPublishFundProductAdminEventMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope`

Returns the progress of a bulk fund admin publish request
 * @summary Returns the progress of a bulk fund admin publish request
 */
export const getPublishFundAdminEventsBulkProgress = (
  trackingId: string,
  signal?: AbortSignal
) => {
  return fetchInstance<PublishProgress>({
    url: `/fund-products/v1/admin/fund-products/publish-events/tracking-id/${trackingId}`,
    method: 'GET',
    signal,
  })
}

export const getGetPublishFundAdminEventsBulkProgressQueryKey = (
  trackingId: string
) => {
  return [
    `/fund-products/v1/admin/fund-products/publish-events/tracking-id/${trackingId}`,
  ] as const
}

export const getGetPublishFundAdminEventsBulkProgressQueryOptions = <
  TData = Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>,
  TError = ErrorType<ErrorResponse>
>(
  trackingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetPublishFundAdminEventsBulkProgressQueryKey(trackingId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>
  > = ({ signal }) => getPublishFundAdminEventsBulkProgress(trackingId, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!trackingId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetPublishFundAdminEventsBulkProgressQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>
>
export type GetPublishFundAdminEventsBulkProgressQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Returns the progress of a bulk fund admin publish request
 */
export const useGetPublishFundAdminEventsBulkProgress = <
  TData = Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>,
  TError = ErrorType<ErrorResponse>
>(
  trackingId: string,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPublishFundAdminEventsBulkProgress>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPublishFundAdminEventsBulkProgressQueryOptions(
    trackingId,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Bulk publishes admin events for the given funds
 * @summary Bulk publishes admin events for the given funds
 */
export const publishFundProductAdminEventsBulk = (
  bulkPublishRequest: BulkPublishRequest,
  headers?: PublishFundProductAdminEventsBulkHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/publish-events`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: bulkPublishRequest,
  })
}

export const getPublishFundProductAdminEventsBulkMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProductAdminEventsBulk>>,
    TError,
    {
      data: BulkPublishRequest
      headers?: PublishFundProductAdminEventsBulkHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishFundProductAdminEventsBulk>>,
  TError,
  {
    data: BulkPublishRequest
    headers?: PublishFundProductAdminEventsBulkHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishFundProductAdminEventsBulk>>,
    {
      data: BulkPublishRequest
      headers?: PublishFundProductAdminEventsBulkHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return publishFundProductAdminEventsBulk(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishFundProductAdminEventsBulkMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishFundProductAdminEventsBulk>>
>
export type PublishFundProductAdminEventsBulkMutationBody = BulkPublishRequest
export type PublishFundProductAdminEventsBulkMutationError =
  ErrorType<ErrorResponse>

/**
 * @summary Bulk publishes admin events for the given funds
 */
export const usePublishFundProductAdminEventsBulk = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishFundProductAdminEventsBulk>>,
    TError,
    {
      data: BulkPublishRequest
      headers?: PublishFundProductAdminEventsBulkHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getPublishFundProductAdminEventsBulkMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * Get a list of allowed actions for the requested user ids. Please do not use this endpoint to build functionality.
 * @deprecated
 * @summary Get a list of allowed actions for the requested user ids. Please do not use this endpoint to build functionality.
 */
export const getFundProductUserAllowedActions = (
  checkFundProductUserAccessPolicyRequestV1: CheckFundProductUserAccessPolicyRequestV1,
  headers?: GetFundProductUserAllowedActionsHeaders
) => {
  return fetchInstance<FundProductUserAllowedActions>({
    url: `/fund-products/v1/fund-products/access-policy/user/allowed-actions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: checkFundProductUserAccessPolicyRequestV1,
  })
}

export const getGetFundProductUserAllowedActionsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFundProductUserAllowedActions>>,
    TError,
    {
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetFundProductUserAllowedActionsHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getFundProductUserAllowedActions>>,
  TError,
  {
    data: CheckFundProductUserAccessPolicyRequestV1
    headers?: GetFundProductUserAllowedActionsHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getFundProductUserAllowedActions>>,
    {
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetFundProductUserAllowedActionsHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return getFundProductUserAllowedActions(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetFundProductUserAllowedActionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductUserAllowedActions>>
>
export type GetFundProductUserAllowedActionsMutationBody =
  CheckFundProductUserAccessPolicyRequestV1
export type GetFundProductUserAllowedActionsMutationError =
  ErrorType<ErrorResponse>

/**
 * @deprecated
 * @summary Get a list of allowed actions for the requested user ids. Please do not use this endpoint to build functionality.
 */
export const useGetFundProductUserAllowedActions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getFundProductUserAllowedActions>>,
    TError,
    {
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetFundProductUserAllowedActionsHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getGetFundProductUserAllowedActionsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `admin scope

Get a list of allowed actions for the requested user ids and fund product id.
 * @deprecated
 * @summary Get a list of allowed actions for the requested user ids and fund product id.
 */
export const getUserAllowedActionsByFundProductId = (
  id: string,
  checkFundProductUserAccessPolicyRequestV1: CheckFundProductUserAccessPolicyRequestV1,
  headers?: GetUserAllowedActionsByFundProductIdHeaders
) => {
  return fetchInstance<FundProductUserAllowedActions>({
    url: `/fund-products/v1/fund-products/${id}/access-policy/user/allowed-actions`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: checkFundProductUserAccessPolicyRequestV1,
  })
}

export const getGetUserAllowedActionsByFundProductIdMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserAllowedActionsByFundProductId>>,
    TError,
    {
      id: string
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetUserAllowedActionsByFundProductIdHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof getUserAllowedActionsByFundProductId>>,
  TError,
  {
    id: string
    data: CheckFundProductUserAccessPolicyRequestV1
    headers?: GetUserAllowedActionsByFundProductIdHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof getUserAllowedActionsByFundProductId>>,
    {
      id: string
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetUserAllowedActionsByFundProductIdHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return getUserAllowedActionsByFundProductId(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type GetUserAllowedActionsByFundProductIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof getUserAllowedActionsByFundProductId>>
>
export type GetUserAllowedActionsByFundProductIdMutationBody =
  CheckFundProductUserAccessPolicyRequestV1
export type GetUserAllowedActionsByFundProductIdMutationError =
  ErrorType<ErrorResponse>

/**
 * @deprecated
 * @summary Get a list of allowed actions for the requested user ids and fund product id.
 */
export const useGetUserAllowedActionsByFundProductId = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof getUserAllowedActionsByFundProductId>>,
    TError,
    {
      id: string
      data: CheckFundProductUserAccessPolicyRequestV1
      headers?: GetUserAllowedActionsByFundProductIdHeaders
    },
    TContext
  >
}) => {
  const mutationOptions =
    getGetUserAllowedActionsByFundProductIdMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-write`

Provides ability to search fund-first fund product access policies
 * @summary Provides ability to search fund-first fund product access policies
 */
export const searchAccessPolicies = (
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1,
  headers?: SearchAccessPoliciesHeaders
) => {
  return fetchInstance<SubjectShareClassAccessPolicyPageV1>({
    url: `/fund-products/v1/admin/fund-products/access-policy/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductAccessPolicySearchRequestV1,
  })
}

export const getSearchAccessPoliciesQueryKey = (
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1
) => {
  return [
    `/fund-products/v1/admin/fund-products/access-policy/search`,
    fundProductAccessPolicySearchRequestV1,
  ] as const
}

export const getSearchAccessPoliciesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1,
  headers?: SearchAccessPoliciesHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchAccessPolicies>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchAccessPoliciesQueryKey(fundProductAccessPolicySearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchAccessPolicies>>
  > = () =>
    searchAccessPolicies(fundProductAccessPolicySearchRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchAccessPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchAccessPoliciesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchAccessPolicies>>
>
export type SearchAccessPoliciesInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Provides ability to search fund-first fund product access policies
 */
export const useSearchAccessPoliciesInfinite = <
  TData = Awaited<ReturnType<typeof searchAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1,
  headers?: SearchAccessPoliciesHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchAccessPolicies>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchAccessPoliciesInfiniteQueryOptions(
    fundProductAccessPolicySearchRequestV1,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchAccessPoliciesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1,
  headers?: SearchAccessPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAccessPolicies>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchAccessPoliciesQueryKey(fundProductAccessPolicySearchRequestV1)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchAccessPolicies>>
  > = () =>
    searchAccessPolicies(fundProductAccessPolicySearchRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchAccessPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchAccessPoliciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchAccessPolicies>>
>
export type SearchAccessPoliciesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Provides ability to search fund-first fund product access policies
 */
export const useSearchAccessPolicies = <
  TData = Awaited<ReturnType<typeof searchAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  fundProductAccessPolicySearchRequestV1: FundProductAccessPolicySearchRequestV1,
  headers?: SearchAccessPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchAccessPolicies>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchAccessPoliciesQueryOptions(
    fundProductAccessPolicySearchRequestV1,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:advisor-read`

Provides the users that have completed required courses and users that did not complete
 * @summary Provides the users that have completed required courses and users that did not complete
 */
export const checkRequiredCourses = (
  id: string,
  fundProductRequiredCoursesCheckRequest: FundProductRequiredCoursesCheckRequest,
  headers?: CheckRequiredCoursesHeaders
) => {
  return fetchInstance<FundProductRequiredCoursesCompletedUsers>({
    url: `/fund-products/v1/fund-products/${id}/access-policy/required-courses/check`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductRequiredCoursesCheckRequest,
  })
}

export const getCheckRequiredCoursesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkRequiredCourses>>,
    TError,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: CheckRequiredCoursesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof checkRequiredCourses>>,
  TError,
  {
    id: string
    data: FundProductRequiredCoursesCheckRequest
    headers?: CheckRequiredCoursesHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof checkRequiredCourses>>,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: CheckRequiredCoursesHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return checkRequiredCourses(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type CheckRequiredCoursesMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkRequiredCourses>>
>
export type CheckRequiredCoursesMutationBody =
  FundProductRequiredCoursesCheckRequest
export type CheckRequiredCoursesMutationError = ErrorType<ErrorResponse>

/**
 * @summary Provides the users that have completed required courses and users that did not complete
 */
export const useCheckRequiredCourses = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof checkRequiredCourses>>,
    TError,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: CheckRequiredCoursesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getCheckRequiredCoursesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:advisor-read`

Provides the courses for a given fund and list of users.
 * @summary Provides the courses for a given fund product and list of users.
 */
export const fetchFundProductCourses = (
  id: string,
  fundProductRequiredCoursesCheckRequest: FundProductRequiredCoursesCheckRequest,
  headers?: FetchFundProductCoursesHeaders
) => {
  return fetchInstance<FundProductCourses>({
    url: `/fund-products/v1/fund-products/${id}/access-policy/courses`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductRequiredCoursesCheckRequest,
  })
}

export const getFetchFundProductCoursesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchFundProductCourses>>,
    TError,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: FetchFundProductCoursesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof fetchFundProductCourses>>,
  TError,
  {
    id: string
    data: FundProductRequiredCoursesCheckRequest
    headers?: FetchFundProductCoursesHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof fetchFundProductCourses>>,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: FetchFundProductCoursesHeaders
    }
  > = (props) => {
    const { id, data, headers } = props ?? {}

    return fetchFundProductCourses(id, data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type FetchFundProductCoursesMutationResult = NonNullable<
  Awaited<ReturnType<typeof fetchFundProductCourses>>
>
export type FetchFundProductCoursesMutationBody =
  FundProductRequiredCoursesCheckRequest
export type FetchFundProductCoursesMutationError = ErrorType<ErrorResponse>

/**
 * @summary Provides the courses for a given fund product and list of users.
 */
export const useFetchFundProductCourses = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof fetchFundProductCourses>>,
    TError,
    {
      id: string
      data: FundProductRequiredCoursesCheckRequest
      headers?: FetchFundProductCoursesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getFetchFundProductCoursesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-read`

Get subject access policy
 * @summary Get subject access policy
 */
export const getSubjectAccessPolicy = (
  params: GetSubjectAccessPolicyParams,
  headers?: GetSubjectAccessPolicyHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<SubjectFirstAccessPolicySetting>({
    url: `/fund-products/v1/admin/fund-products/access-policy/subjects`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetSubjectAccessPolicyQueryKey = (
  params: GetSubjectAccessPolicyParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/access-policy/subjects`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetSubjectAccessPolicyQueryOptions = <
  TData = Awaited<ReturnType<typeof getSubjectAccessPolicy>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetSubjectAccessPolicyParams,
  headers?: GetSubjectAccessPolicyHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubjectAccessPolicy>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetSubjectAccessPolicyQueryKey(params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSubjectAccessPolicy>>
  > = ({ signal }) => getSubjectAccessPolicy(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSubjectAccessPolicy>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetSubjectAccessPolicyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSubjectAccessPolicy>>
>
export type GetSubjectAccessPolicyQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get subject access policy
 */
export const useGetSubjectAccessPolicy = <
  TData = Awaited<ReturnType<typeof getSubjectAccessPolicy>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetSubjectAccessPolicyParams,
  headers?: GetSubjectAccessPolicyHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSubjectAccessPolicy>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSubjectAccessPolicyQueryOptions(
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get share class approvals
 * @summary Get share class product approvals
 */
export const getShareClassApprovalsByFundProduct = (
  id: string,
  params: GetShareClassApprovalsByFundProductParams,
  headers?: GetShareClassApprovalsByFundProductHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<ShareClassesApprovals>({
    url: `/fund-products/v1/admin/fund-products/${id}/access-policy/share-class-approvals`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetShareClassApprovalsByFundProductQueryKey = (
  id: string,
  params: GetShareClassApprovalsByFundProductParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/access-policy/share-class-approvals`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetShareClassApprovalsByFundProductQueryOptions = <
  TData = Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params: GetShareClassApprovalsByFundProductParams,
  headers?: GetShareClassApprovalsByFundProductHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetShareClassApprovalsByFundProductQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>
  > = ({ signal }) =>
    getShareClassApprovalsByFundProduct(id, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetShareClassApprovalsByFundProductQueryResult = NonNullable<
  Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>
>
export type GetShareClassApprovalsByFundProductQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get share class product approvals
 */
export const useGetShareClassApprovalsByFundProduct = <
  TData = Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params: GetShareClassApprovalsByFundProductParams,
  headers?: GetShareClassApprovalsByFundProductHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getShareClassApprovalsByFundProduct>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetShareClassApprovalsByFundProductQueryOptions(
    id,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get fund product policies
 * @summary Get fund product policies
 */
export const getFundProductPolicies = (
  id: string,
  params: GetFundProductPoliciesParams,
  headers?: GetFundProductPoliciesHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductAccessPolicies>({
    url: `/fund-products/v1/admin/fund-products/${id}/access-policy/fund-products`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundProductPoliciesQueryKey = (
  id: string,
  params: GetFundProductPoliciesParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/access-policy/fund-products`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundProductPoliciesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundProductPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params: GetFundProductPoliciesParams,
  headers?: GetFundProductPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductPolicies>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundProductPoliciesQueryKey(id, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundProductPolicies>>
  > = ({ signal }) => getFundProductPolicies(id, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundProductPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundProductPoliciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundProductPolicies>>
>
export type GetFundProductPoliciesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get fund product policies
 */
export const useGetFundProductPolicies = <
  TData = Awaited<ReturnType<typeof getFundProductPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params: GetFundProductPoliciesParams,
  headers?: GetFundProductPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundProductPolicies>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundProductPoliciesQueryOptions(
    id,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get share class access policies
 * @summary Get share class access policies
 */
export const getShareClassAccessPoliciesByFundProductAndSubject = (
  id: string,
  params: GetShareClassAccessPoliciesByFundProductAndSubjectParams,
  headers?: GetShareClassAccessPoliciesByFundProductAndSubjectHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<ShareClassAccessPolicies>({
    url: `/fund-products/v1/admin/fund-products/${id}/access-policy/share-classes`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetShareClassAccessPoliciesByFundProductAndSubjectQueryKey = (
  id: string,
  params: GetShareClassAccessPoliciesByFundProductAndSubjectParams
) => {
  return [
    `/fund-products/v1/admin/fund-products/${id}/access-policy/share-classes`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetShareClassAccessPoliciesByFundProductAndSubjectQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
    >,
    TError = ErrorType<ErrorResponse>
  >(
    id: string,
    params: GetShareClassAccessPoliciesByFundProductAndSubjectParams,
    headers?: GetShareClassAccessPoliciesByFundProductAndSubjectHeaders,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
        >,
        TError,
        TData
      >
    }
  ) => {
    const { query: queryOptions } = options ?? {}

    const queryKey =
      queryOptions?.queryKey ??
      getGetShareClassAccessPoliciesByFundProductAndSubjectQueryKey(id, params)

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
      >
    > = ({ signal }) =>
      getShareClassAccessPoliciesByFundProductAndSubject(
        id,
        params,
        headers,
        signal
      )

    return {
      queryKey,
      queryFn,
      enabled: !!id,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
      >,
      TError,
      TData
    > & { queryKey: QueryKey }
  }

export type GetShareClassAccessPoliciesByFundProductAndSubjectQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
    >
  >
export type GetShareClassAccessPoliciesByFundProductAndSubjectQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get share class access policies
 */
export const useGetShareClassAccessPoliciesByFundProductAndSubject = <
  TData = Awaited<
    ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
  >,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  params: GetShareClassAccessPoliciesByFundProductAndSubjectParams,
  headers?: GetShareClassAccessPoliciesByFundProductAndSubjectHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof getShareClassAccessPoliciesByFundProductAndSubject>
      >,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetShareClassAccessPoliciesByFundProductAndSubjectQueryOptions(
      id,
      params,
      headers,
      options
    )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-write`
    
Patch access policies in JSON Patch style. This endpoints allows to patch multiple access policies in one 
request and in one transaction. Access policies will be created if they do not exist.
 * @summary patch access policies in JSON Patch style
 */
export const patchAccessPolicies = (
  accessPolicyPatchAggregateV2: AccessPolicyPatchAggregateV2,
  headers?: PatchAccessPoliciesHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/fund-products/access-policy`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: accessPolicyPatchAggregateV2,
  })
}

export const getPatchAccessPoliciesMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAccessPolicies>>,
    TError,
    {
      data: AccessPolicyPatchAggregateV2
      headers?: PatchAccessPoliciesHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchAccessPolicies>>,
  TError,
  { data: AccessPolicyPatchAggregateV2; headers?: PatchAccessPoliciesHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchAccessPolicies>>,
    { data: AccessPolicyPatchAggregateV2; headers?: PatchAccessPoliciesHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return patchAccessPolicies(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchAccessPoliciesMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchAccessPolicies>>
>
export type PatchAccessPoliciesMutationBody = AccessPolicyPatchAggregateV2
export type PatchAccessPoliciesMutationError = ErrorType<ErrorResponse>

/**
 * @summary patch access policies in JSON Patch style
 */
export const usePatchAccessPolicies = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchAccessPolicies>>,
    TError,
    {
      data: AccessPolicyPatchAggregateV2
      headers?: PatchAccessPoliciesHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getPatchAccessPoliciesMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-read`

Provides ability to search subject-first fund product access policies
 * @summary Provides ability to search subject-first fund product access policies
 */
export const searchSubjectFirstAccessPolicies = (
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest,
  headers?: SearchSubjectFirstAccessPoliciesHeaders
) => {
  return fetchInstance<SubjectFirstAccessPolicyShareClassPage>({
    url: `/fund-products/v1/admin/fund-products/access-policy/subject-first/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: subjectFirstAccessPolicySearchRequest,
  })
}

export const getSearchSubjectFirstAccessPoliciesQueryKey = (
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest
) => {
  return [
    `/fund-products/v1/admin/fund-products/access-policy/subject-first/search`,
    subjectFirstAccessPolicySearchRequest,
  ] as const
}

export const getSearchSubjectFirstAccessPoliciesInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest,
  headers?: SearchSubjectFirstAccessPoliciesHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchSubjectFirstAccessPoliciesQueryKey(
      subjectFirstAccessPolicySearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>
  > = () =>
    searchSubjectFirstAccessPolicies(
      subjectFirstAccessPolicySearchRequest,
      headers
    )

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchSubjectFirstAccessPoliciesInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>
>
export type SearchSubjectFirstAccessPoliciesInfiniteQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Provides ability to search subject-first fund product access policies
 */
export const useSearchSubjectFirstAccessPoliciesInfinite = <
  TData = Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest,
  headers?: SearchSubjectFirstAccessPoliciesHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchSubjectFirstAccessPoliciesInfiniteQueryOptions(
    subjectFirstAccessPolicySearchRequest,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchSubjectFirstAccessPoliciesQueryOptions = <
  TData = Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest,
  headers?: SearchSubjectFirstAccessPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getSearchSubjectFirstAccessPoliciesQueryKey(
      subjectFirstAccessPolicySearchRequest
    )

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>
  > = () =>
    searchSubjectFirstAccessPolicies(
      subjectFirstAccessPolicySearchRequest,
      headers
    )

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchSubjectFirstAccessPoliciesQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>
>
export type SearchSubjectFirstAccessPoliciesQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Provides ability to search subject-first fund product access policies
 */
export const useSearchSubjectFirstAccessPolicies = <
  TData = Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
  TError = ErrorType<ErrorResponse>
>(
  subjectFirstAccessPolicySearchRequest: SubjectFirstAccessPolicySearchRequest,
  headers?: SearchSubjectFirstAccessPoliciesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchSubjectFirstAccessPolicies>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchSubjectFirstAccessPoliciesQueryOptions(
    subjectFirstAccessPolicySearchRequest,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Search firms
 * @summary Search firms
 */
export const searchFirms = (
  firmRequestV1: FirmRequestV1,
  headers?: SearchFirmsHeaders
) => {
  return fetchInstance<FirmPageV1>({
    url: `/fund-products/v1/cache/firms/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: firmRequestV1,
  })
}

export const getSearchFirmsQueryKey = (firmRequestV1: FirmRequestV1) => {
  return [`/fund-products/v1/cache/firms/search`, firmRequestV1] as const
}

export const getSearchFirmsInfiniteQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFirms>>,
  TError = ErrorType<ErrorResponse>
>(
  firmRequestV1: FirmRequestV1,
  headers?: SearchFirmsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchFirms>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchFirmsQueryKey(firmRequestV1)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchFirms>>> = () =>
    searchFirms(firmRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof searchFirms>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchFirmsInfiniteQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFirms>>
>
export type SearchFirmsInfiniteQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search firms
 */
export const useSearchFirmsInfinite = <
  TData = Awaited<ReturnType<typeof searchFirms>>,
  TError = ErrorType<ErrorResponse>
>(
  firmRequestV1: FirmRequestV1,
  headers?: SearchFirmsHeaders,
  options?: {
    query?: UseInfiniteQueryOptions<
      Awaited<ReturnType<typeof searchFirms>>,
      TError,
      TData
    >
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchFirmsInfiniteQueryOptions(
    firmRequestV1,
    headers,
    options
  )

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<
    TData,
    TError
  > & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const getSearchFirmsQueryOptions = <
  TData = Awaited<ReturnType<typeof searchFirms>>,
  TError = ErrorType<ErrorResponse>
>(
  firmRequestV1: FirmRequestV1,
  headers?: SearchFirmsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFirms>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getSearchFirmsQueryKey(firmRequestV1)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchFirms>>> = () =>
    searchFirms(firmRequestV1, headers)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof searchFirms>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type SearchFirmsQueryResult = NonNullable<
  Awaited<ReturnType<typeof searchFirms>>
>
export type SearchFirmsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Search firms
 */
export const useSearchFirms = <
  TData = Awaited<ReturnType<typeof searchFirms>>,
  TError = ErrorType<ErrorResponse>
>(
  firmRequestV1: FirmRequestV1,
  headers?: SearchFirmsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof searchFirms>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getSearchFirmsQueryOptions(
    firmRequestV1,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get cached document delivery workflows
 * @summary Get cached document delivery workflows
 */
export const getDocumentDeliveryWorkflows = (
  headers?: GetDocumentDeliveryWorkflowsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<DocumentDeliveryWorkflowV1[]>({
    url: `/fund-products/v1/cache/document-delivery-workflows`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetDocumentDeliveryWorkflowsQueryKey = () => {
  return [`/fund-products/v1/cache/document-delivery-workflows`] as const
}

export const getGetDocumentDeliveryWorkflowsQueryOptions = <
  TData = Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetDocumentDeliveryWorkflowsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentDeliveryWorkflowsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>
  > = ({ signal }) => getDocumentDeliveryWorkflows(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetDocumentDeliveryWorkflowsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>
>
export type GetDocumentDeliveryWorkflowsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get cached document delivery workflows
 */
export const useGetDocumentDeliveryWorkflows = <
  TData = Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetDocumentDeliveryWorkflowsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDocumentDeliveryWorkflows>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDocumentDeliveryWorkflowsQueryOptions(
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get cached benchmarks
 * @summary Get cached benchmarks
 */
export const getBenchmarks = (
  headers?: GetBenchmarksHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<BenchmarkV1[]>({
    url: `/fund-products/v1/cache/benchmarks`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetBenchmarksQueryKey = () => {
  return [`/fund-products/v1/cache/benchmarks`] as const
}

export const getGetBenchmarksQueryOptions = <
  TData = Awaited<ReturnType<typeof getBenchmarks>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetBenchmarksHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBenchmarks>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBenchmarksQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBenchmarks>>> = ({
    signal,
  }) => getBenchmarks(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getBenchmarks>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetBenchmarksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getBenchmarks>>
>
export type GetBenchmarksQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get cached benchmarks
 */
export const useGetBenchmarks = <
  TData = Awaited<ReturnType<typeof getBenchmarks>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetBenchmarksHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getBenchmarks>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetBenchmarksQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get cached custodians
 * @summary Get cached custodians
 */
export const getCustodians = (
  params?: GetCustodiansParams,
  headers?: GetCustodiansHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<CustodianPageV1>({
    url: `/fund-products/v1/cache/custodians`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetCustodiansQueryKey = (params?: GetCustodiansParams) => {
  return [
    `/fund-products/v1/cache/custodians`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetCustodiansQueryOptions = <
  TData = Awaited<ReturnType<typeof getCustodians>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetCustodiansParams,
  headers?: GetCustodiansHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustodians>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCustodiansQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustodians>>> = ({
    signal,
  }) => getCustodians(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getCustodians>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetCustodiansQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustodians>>
>
export type GetCustodiansQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get cached custodians
 */
export const useGetCustodians = <
  TData = Awaited<ReturnType<typeof getCustodians>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetCustodiansParams,
  headers?: GetCustodiansHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getCustodians>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCustodiansQueryOptions(params, headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get cached fund administrators
 * @summary Get cached fund administrators
 */
export const getFundAdministrators = (
  headers?: GetFundAdministratorsHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundAdministratorV1[]>({
    url: `/fund-products/v1/cache/fund-administrators`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundAdministratorsQueryKey = () => {
  return [`/fund-products/v1/cache/fund-administrators`] as const
}

export const getGetFundAdministratorsQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundAdministrators>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFundAdministratorsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundAdministrators>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFundAdministratorsQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundAdministrators>>
  > = ({ signal }) => getFundAdministrators(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundAdministrators>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundAdministratorsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundAdministrators>>
>
export type GetFundAdministratorsQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get cached fund administrators
 */
export const useGetFundAdministrators = <
  TData = Awaited<ReturnType<typeof getFundAdministrators>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFundAdministratorsHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundAdministrators>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundAdministratorsQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-read`

Get cached fund managers
 * @summary Get cached fund managers
 */
export const getFundManagers = (
  params?: GetFundManagersParams,
  headers?: GetFundManagersHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundManagerV1[]>({
    url: `/fund-products/v1/cache/fund-managers`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundManagersQueryKey = (params?: GetFundManagersParams) => {
  return [
    `/fund-products/v1/cache/fund-managers`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundManagersQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundManagers>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetFundManagersParams,
  headers?: GetFundManagersHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundManagers>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFundManagersQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFundManagers>>> = ({
    signal,
  }) => getFundManagers(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundManagers>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundManagersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundManagers>>
>
export type GetFundManagersQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get cached fund managers
 */
export const useGetFundManagers = <
  TData = Awaited<ReturnType<typeof getFundManagers>>,
  TError = ErrorType<ErrorResponse>
>(
  params?: GetFundManagersParams,
  headers?: GetFundManagersHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundManagers>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundManagersQueryOptions(params, headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `admin scope`

Reingest fund products into elastic search
 * @summary Reingest fund products into elastic search
 */
export const triggerReIngestion = (
  fundProductIngestionRequest: FundProductIngestionRequest,
  headers?: TriggerReIngestionHeaders
) => {
  return fetchInstance<void>({
    url: `/admin/trigger-fund-products-re-ingestion`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundProductIngestionRequest,
  })
}

export const getTriggerReIngestionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerReIngestion>>,
    TError,
    { data: FundProductIngestionRequest; headers?: TriggerReIngestionHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof triggerReIngestion>>,
  TError,
  { data: FundProductIngestionRequest; headers?: TriggerReIngestionHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof triggerReIngestion>>,
    { data: FundProductIngestionRequest; headers?: TriggerReIngestionHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return triggerReIngestion(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type TriggerReIngestionMutationResult = NonNullable<
  Awaited<ReturnType<typeof triggerReIngestion>>
>
export type TriggerReIngestionMutationBody = FundProductIngestionRequest
export type TriggerReIngestionMutationError = ErrorType<ErrorResponse>

/**
 * @summary Reingest fund products into elastic search
 */
export const useTriggerReIngestion = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof triggerReIngestion>>,
    TError,
    { data: FundProductIngestionRequest; headers?: TriggerReIngestionHeaders },
    TContext
  >
}) => {
  const mutationOptions = getTriggerReIngestionMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:advisor-read`

Get click through agreement for fund
 * @summary Get click through agreement for fund
 */
export const getClickThroughAgreement = (
  id: string,
  headers?: GetClickThroughAgreementHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<ClickThroughAgreement>({
    url: `/fund-products/v1/fund-products/${id}/click-through-agreement`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetClickThroughAgreementQueryKey = (id: string) => {
  return [
    `/fund-products/v1/fund-products/${id}/click-through-agreement`,
  ] as const
}

export const getGetClickThroughAgreementQueryOptions = <
  TData = Awaited<ReturnType<typeof getClickThroughAgreement>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  headers?: GetClickThroughAgreementHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClickThroughAgreement>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetClickThroughAgreementQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getClickThroughAgreement>>
  > = ({ signal }) => getClickThroughAgreement(id, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getClickThroughAgreement>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetClickThroughAgreementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getClickThroughAgreement>>
>
export type GetClickThroughAgreementQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get click through agreement for fund
 */
export const useGetClickThroughAgreement = <
  TData = Awaited<ReturnType<typeof getClickThroughAgreement>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  headers?: GetClickThroughAgreementHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getClickThroughAgreement>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetClickThroughAgreementQueryOptions(
    id,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:advisor-read`

Get latest click through agreement acceptance for fund
 * @summary Get latest click through agreement acceptance for fund
 */
export const getLatestAcceptedClickThroughAgreement = (
  id: string,
  headers?: GetLatestAcceptedClickThroughAgreementHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<ClickThroughAgreementAcceptance>({
    url: `/fund-products/v1/fund-products/${id}/click-through-agreement/latest/acceptance`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetLatestAcceptedClickThroughAgreementQueryKey = (
  id: string
) => {
  return [
    `/fund-products/v1/fund-products/${id}/click-through-agreement/latest/acceptance`,
  ] as const
}

export const getGetLatestAcceptedClickThroughAgreementQueryOptions = <
  TData = Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  headers?: GetLatestAcceptedClickThroughAgreementHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getGetLatestAcceptedClickThroughAgreementQueryKey(id)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>
  > = ({ signal }) =>
    getLatestAcceptedClickThroughAgreement(id, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetLatestAcceptedClickThroughAgreementQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>
>
export type GetLatestAcceptedClickThroughAgreementQueryError =
  ErrorType<ErrorResponse>

/**
 * @summary Get latest click through agreement acceptance for fund
 */
export const useGetLatestAcceptedClickThroughAgreement = <
  TData = Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>,
  TError = ErrorType<ErrorResponse>
>(
  id: string,
  headers?: GetLatestAcceptedClickThroughAgreementHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getLatestAcceptedClickThroughAgreement>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetLatestAcceptedClickThroughAgreementQueryOptions(
    id,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:advisor-read`

Accept click through agreement for fund
 * @summary Accept click through agreement for fund
 */
export const acceptClickThroughAgreement = (
  id: string,
  clickThroughAgreementId: string,
  headers?: AcceptClickThroughAgreementHeaders
) => {
  return fetchInstance<ClickThroughAgreementAcceptance>({
    url: `/fund-products/v1/fund-products/${id}/click-through-agreement/${clickThroughAgreementId}/acceptance`,
    method: 'POST',
    headers,
  })
}

export const getAcceptClickThroughAgreementMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptClickThroughAgreement>>,
    TError,
    {
      id: string
      clickThroughAgreementId: string
      headers?: AcceptClickThroughAgreementHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof acceptClickThroughAgreement>>,
  TError,
  {
    id: string
    clickThroughAgreementId: string
    headers?: AcceptClickThroughAgreementHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof acceptClickThroughAgreement>>,
    {
      id: string
      clickThroughAgreementId: string
      headers?: AcceptClickThroughAgreementHeaders
    }
  > = (props) => {
    const { id, clickThroughAgreementId, headers } = props ?? {}

    return acceptClickThroughAgreement(id, clickThroughAgreementId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type AcceptClickThroughAgreementMutationResult = NonNullable<
  Awaited<ReturnType<typeof acceptClickThroughAgreement>>
>

export type AcceptClickThroughAgreementMutationError = ErrorType<ErrorResponse>

/**
 * @summary Accept click through agreement for fund
 */
export const useAcceptClickThroughAgreement = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof acceptClickThroughAgreement>>,
    TError,
    {
      id: string
      clickThroughAgreementId: string
      headers?: AcceptClickThroughAgreementHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getAcceptClickThroughAgreementMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-read`

Get feature toggles
 * @summary Get feature toggles
 */
export const getFeatureToggles = (
  headers?: GetFeatureTogglesHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FeatureTogglesV1>({
    url: `/fund-products/v1/feature-toggles`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFeatureTogglesQueryKey = () => {
  return [`/fund-products/v1/feature-toggles`] as const
}

export const getGetFeatureTogglesQueryOptions = <
  TData = Awaited<ReturnType<typeof getFeatureToggles>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFeatureTogglesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFeatureToggles>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFeatureTogglesQueryKey()

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFeatureToggles>>
  > = ({ signal }) => getFeatureToggles(headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFeatureToggles>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFeatureTogglesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFeatureToggles>>
>
export type GetFeatureTogglesQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get feature toggles
 */
export const useGetFeatureToggles = <
  TData = Awaited<ReturnType<typeof getFeatureToggles>>,
  TError = ErrorType<ErrorResponse>
>(
  headers?: GetFeatureTogglesHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFeatureToggles>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFeatureTogglesQueryOptions(headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Bulk upload funds
 * @summary Bulk upload funds
 */
export const bulkUploadFunds = (
  bulkUploadFundsBody: BulkUploadFundsBody,
  headers?: BulkUploadFundsHeaders
) => {
  const formData = new FormData()
  formData.append('file', bulkUploadFundsBody.file)

  return fetchInstance<FundBulkUploadResponseV1>({
    url: `/fund-products/v1/admin/bulk-uploads`,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data', ...headers },
    data: formData,
  })
}

export const getBulkUploadFundsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadFunds>>,
    TError,
    { data: BulkUploadFundsBody; headers?: BulkUploadFundsHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof bulkUploadFunds>>,
  TError,
  { data: BulkUploadFundsBody; headers?: BulkUploadFundsHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof bulkUploadFunds>>,
    { data: BulkUploadFundsBody; headers?: BulkUploadFundsHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return bulkUploadFunds(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type BulkUploadFundsMutationResult = NonNullable<
  Awaited<ReturnType<typeof bulkUploadFunds>>
>
export type BulkUploadFundsMutationBody = BulkUploadFundsBody
export type BulkUploadFundsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Bulk upload funds
 */
export const useBulkUploadFunds = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof bulkUploadFunds>>,
    TError,
    { data: BulkUploadFundsBody; headers?: BulkUploadFundsHeaders },
    TContext
  >
}) => {
  const mutationOptions = getBulkUploadFundsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Search fund bulk uploads
 * @summary Search fund bulk uploads
 */
export const searchFundBulkUploads = (
  fundBulkUploadSearchRequestV1: FundBulkUploadSearchRequestV1,
  headers?: SearchFundBulkUploadsHeaders
) => {
  return fetchInstance<FundBulkUploadSummaryPageV1>({
    url: `/fund-products/v1/admin/bulk-uploads/search`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: fundBulkUploadSearchRequestV1,
  })
}

export const getSearchFundBulkUploadsMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundBulkUploads>>,
    TError,
    {
      data: FundBulkUploadSearchRequestV1
      headers?: SearchFundBulkUploadsHeaders
    },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof searchFundBulkUploads>>,
  TError,
  {
    data: FundBulkUploadSearchRequestV1
    headers?: SearchFundBulkUploadsHeaders
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof searchFundBulkUploads>>,
    {
      data: FundBulkUploadSearchRequestV1
      headers?: SearchFundBulkUploadsHeaders
    }
  > = (props) => {
    const { data, headers } = props ?? {}

    return searchFundBulkUploads(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type SearchFundBulkUploadsMutationResult = NonNullable<
  Awaited<ReturnType<typeof searchFundBulkUploads>>
>
export type SearchFundBulkUploadsMutationBody = FundBulkUploadSearchRequestV1
export type SearchFundBulkUploadsMutationError = ErrorType<ErrorResponse>

/**
 * @summary Search fund bulk uploads
 */
export const useSearchFundBulkUploads = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof searchFundBulkUploads>>,
    TError,
    {
      data: FundBulkUploadSearchRequestV1
      headers?: SearchFundBulkUploadsHeaders
    },
    TContext
  >
}) => {
  const mutationOptions = getSearchFundBulkUploadsMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-read`

Get fund bulk upload
 * @summary Get fund bulk upload
 */
export const getFundBulkUpload = (
  uploadId: string,
  headers?: GetFundBulkUploadHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundBulkUploadV1>({
    url: `/fund-products/v1/admin/bulk-uploads/${uploadId}`,
    method: 'GET',
    headers,
    signal,
  })
}

export const getGetFundBulkUploadQueryKey = (uploadId: string) => {
  return [`/fund-products/v1/admin/bulk-uploads/${uploadId}`] as const
}

export const getGetFundBulkUploadQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundBulkUpload>>,
  TError = ErrorType<ErrorResponse>
>(
  uploadId: string,
  headers?: GetFundBulkUploadHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundBulkUpload>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ?? getGetFundBulkUploadQueryKey(uploadId)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getFundBulkUpload>>
  > = ({ signal }) => getFundBulkUpload(uploadId, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!uploadId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundBulkUpload>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundBulkUploadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundBulkUpload>>
>
export type GetFundBulkUploadQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get fund bulk upload
 */
export const useGetFundBulkUpload = <
  TData = Awaited<ReturnType<typeof getFundBulkUpload>>,
  TError = ErrorType<ErrorResponse>
>(
  uploadId: string,
  headers?: GetFundBulkUploadHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundBulkUpload>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundBulkUploadQueryOptions(
    uploadId,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-read`

Download fund bulk upload template
 * @summary Download fund bulk upload template
 */
export const downloadFundBulkUploadTemplate = (
  templateId: string,
  params?: DownloadFundBulkUploadTemplateParams,
  headers?: DownloadFundBulkUploadTemplateHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<Blob>({
    url: `/fund-products/v1/admin/bulk-uploads/templates/${templateId}`,
    method: 'GET',
    headers,
    params,
    responseType: 'blob',
    signal,
  })
}

export const getDownloadFundBulkUploadTemplateQueryKey = (
  templateId: string,
  params?: DownloadFundBulkUploadTemplateParams
) => {
  return [
    `/fund-products/v1/admin/bulk-uploads/templates/${templateId}`,
    ...(params ? [params] : []),
  ] as const
}

export const getDownloadFundBulkUploadTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>,
  TError = ErrorType<ErrorResponse>
>(
  templateId: string,
  params?: DownloadFundBulkUploadTemplateParams,
  headers?: DownloadFundBulkUploadTemplateHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey =
    queryOptions?.queryKey ??
    getDownloadFundBulkUploadTemplateQueryKey(templateId, params)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>
  > = ({ signal }) =>
    downloadFundBulkUploadTemplate(templateId, params, headers, signal)

  return {
    queryKey,
    queryFn,
    enabled: !!templateId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type DownloadFundBulkUploadTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>
>
export type DownloadFundBulkUploadTemplateQueryError = ErrorType<ErrorResponse>

/**
 * @summary Download fund bulk upload template
 */
export const useDownloadFundBulkUploadTemplate = <
  TData = Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>,
  TError = ErrorType<ErrorResponse>
>(
  templateId: string,
  params?: DownloadFundBulkUploadTemplateParams,
  headers?: DownloadFundBulkUploadTemplateHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof downloadFundBulkUploadTemplate>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getDownloadFundBulkUploadTemplateQueryOptions(
    templateId,
    params,
    headers,
    options
  )

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `funds:products:admin-write`

Validate all funds in bulk upload
 * @summary Validate all funds in bulk upload
 */
export const validateAllFundsInBulkUpload = (
  uploadId: string,
  headers?: ValidateAllFundsInBulkUploadHeaders
) => {
  return fetchInstance<FundBulkUploadResponseV1>({
    url: `/fund-products/v1/admin/bulk-uploads/${uploadId}/validate-all`,
    method: 'POST',
    headers,
  })
}

export const getValidateAllFundsInBulkUploadMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: ValidateAllFundsInBulkUploadHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof validateAllFundsInBulkUpload>>,
  TError,
  { uploadId: string; headers?: ValidateAllFundsInBulkUploadHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof validateAllFundsInBulkUpload>>,
    { uploadId: string; headers?: ValidateAllFundsInBulkUploadHeaders }
  > = (props) => {
    const { uploadId, headers } = props ?? {}

    return validateAllFundsInBulkUpload(uploadId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type ValidateAllFundsInBulkUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof validateAllFundsInBulkUpload>>
>

export type ValidateAllFundsInBulkUploadMutationError = ErrorType<ErrorResponse>

/**
 * @summary Validate all funds in bulk upload
 */
export const useValidateAllFundsInBulkUpload = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof validateAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: ValidateAllFundsInBulkUploadHeaders },
    TContext
  >
}) => {
  const mutationOptions =
    getValidateAllFundsInBulkUploadMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Draft all funds in bulk upload
 * @summary Draft all funds in bulk upload
 */
export const draftAllFundsInBulkUpload = (
  uploadId: string,
  headers?: DraftAllFundsInBulkUploadHeaders
) => {
  return fetchInstance<FundBulkUploadResponseV1>({
    url: `/fund-products/v1/admin/bulk-uploads/${uploadId}/draft-all`,
    method: 'POST',
    headers,
  })
}

export const getDraftAllFundsInBulkUploadMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof draftAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: DraftAllFundsInBulkUploadHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof draftAllFundsInBulkUpload>>,
  TError,
  { uploadId: string; headers?: DraftAllFundsInBulkUploadHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof draftAllFundsInBulkUpload>>,
    { uploadId: string; headers?: DraftAllFundsInBulkUploadHeaders }
  > = (props) => {
    const { uploadId, headers } = props ?? {}

    return draftAllFundsInBulkUpload(uploadId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type DraftAllFundsInBulkUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof draftAllFundsInBulkUpload>>
>

export type DraftAllFundsInBulkUploadMutationError = ErrorType<ErrorResponse>

/**
 * @summary Draft all funds in bulk upload
 */
export const useDraftAllFundsInBulkUpload = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof draftAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: DraftAllFundsInBulkUploadHeaders },
    TContext
  >
}) => {
  const mutationOptions = getDraftAllFundsInBulkUploadMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `funds:products:admin-write`

Publish all funds in bulk upload
 * @summary Publish all funds in bulk upload
 */
export const publishAllFundsInBulkUpload = (
  uploadId: string,
  headers?: PublishAllFundsInBulkUploadHeaders
) => {
  return fetchInstance<FundBulkUploadResponseV1>({
    url: `/fund-products/v1/admin/bulk-uploads/${uploadId}/publish-all`,
    method: 'POST',
    headers,
  })
}

export const getPublishAllFundsInBulkUploadMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: PublishAllFundsInBulkUploadHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof publishAllFundsInBulkUpload>>,
  TError,
  { uploadId: string; headers?: PublishAllFundsInBulkUploadHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof publishAllFundsInBulkUpload>>,
    { uploadId: string; headers?: PublishAllFundsInBulkUploadHeaders }
  > = (props) => {
    const { uploadId, headers } = props ?? {}

    return publishAllFundsInBulkUpload(uploadId, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PublishAllFundsInBulkUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof publishAllFundsInBulkUpload>>
>

export type PublishAllFundsInBulkUploadMutationError = ErrorType<ErrorResponse>

/**
 * @summary Publish all funds in bulk upload
 */
export const usePublishAllFundsInBulkUpload = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof publishAllFundsInBulkUpload>>,
    TError,
    { uploadId: string; headers?: PublishAllFundsInBulkUploadHeaders },
    TContext
  >
}) => {
  const mutationOptions = getPublishAllFundsInBulkUploadMutationOptions(options)

  return useMutation(mutationOptions)
}

/**
 * **Permission:** `fund:products:admin-read`

Get custom ranks
 * @summary Get fund ranks
 */
export const getFundRanks = (
  params: GetFundRanksParams,
  headers?: GetFundRanksHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<SubjectFirstFundRanks>({
    url: `/fund-products/v1/admin/ranks/subjects`,
    method: 'GET',
    headers,
    params,
    signal,
  })
}

export const getGetFundRanksQueryKey = (params: GetFundRanksParams) => {
  return [
    `/fund-products/v1/admin/ranks/subjects`,
    ...(params ? [params] : []),
  ] as const
}

export const getGetFundRanksQueryOptions = <
  TData = Awaited<ReturnType<typeof getFundRanks>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetFundRanksParams,
  headers?: GetFundRanksHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundRanks>>,
      TError,
      TData
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFundRanksQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFundRanks>>> = ({
    signal,
  }) => getFundRanks(params, headers, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getFundRanks>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type GetFundRanksQueryResult = NonNullable<
  Awaited<ReturnType<typeof getFundRanks>>
>
export type GetFundRanksQueryError = ErrorType<ErrorResponse>

/**
 * @summary Get fund ranks
 */
export const useGetFundRanks = <
  TData = Awaited<ReturnType<typeof getFundRanks>>,
  TError = ErrorType<ErrorResponse>
>(
  params: GetFundRanksParams,
  headers?: GetFundRanksHeaders,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getFundRanks>>,
      TError,
      TData
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetFundRanksQueryOptions(params, headers, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * **Permission:** `fund:products:admin-write`

Patch custom ranks
 * @summary Patch fund ranks
 */
export const patchFundRanks = (
  subjectFirstFundRankPatch: SubjectFirstFundRankPatch[],
  headers?: PatchFundRanksHeaders
) => {
  return fetchInstance<void>({
    url: `/fund-products/v1/admin/ranks/subjects`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', ...headers },
    data: subjectFirstFundRankPatch,
  })
}

export const getPatchFundRanksMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundRanks>>,
    TError,
    { data: SubjectFirstFundRankPatch[]; headers?: PatchFundRanksHeaders },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof patchFundRanks>>,
  TError,
  { data: SubjectFirstFundRankPatch[]; headers?: PatchFundRanksHeaders },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof patchFundRanks>>,
    { data: SubjectFirstFundRankPatch[]; headers?: PatchFundRanksHeaders }
  > = (props) => {
    const { data, headers } = props ?? {}

    return patchFundRanks(data, headers)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchFundRanksMutationResult = NonNullable<
  Awaited<ReturnType<typeof patchFundRanks>>
>
export type PatchFundRanksMutationBody = SubjectFirstFundRankPatch[]
export type PatchFundRanksMutationError = ErrorType<ErrorResponse>

/**
 * @summary Patch fund ranks
 */
export const usePatchFundRanks = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof patchFundRanks>>,
    TError,
    { data: SubjectFirstFundRankPatch[]; headers?: PatchFundRanksHeaders },
    TContext
  >
}) => {
  const mutationOptions = getPatchFundRanksMutationOptions(options)

  return useMutation(mutationOptions)
}
