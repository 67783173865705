import { To } from 'react-router-dom'
import { isFunction } from 'remeda'

import { getLoneOrMfePath } from '@cais-group/caisiq/util/js/get-lone-or-mfe-path'

const NONAUTH_ROUTES = {
  // External access routes (Auth0)
  caisiqDirect: '/cais',
  pershingDirect: '/pershing-direct',
  loginError: '/loginerror',
  login: '/login',
  home: '/',
  verifyEmail: '/verifyemail',
  emailNotAllowed: '/emailnotallowed',
}

// Authenticated
const AUTH_ROUTES = {
  admin: '/admin',
  homeoffice: `/homeoffice`,
  dashboard: '/',
  courses: `/courses`,
  assignmentBase: '/assignment',
  assignment: (assignmentId: string) =>
    `${ROUTES.assignmentBase}/${assignmentId}`,
  assignmentExit: (assignmentId: string) =>
    `${ROUTES.assignmentBase}/${assignmentId}/exit`,

  courseLibrary: `/course-library`,

  video: (playlistId?: string, id?: string) =>
    `${ROUTES.videos}/${playlistId ? `${playlistId}/${id}` : id}`,
  videos: `/videos`,

  articles: `/articles`,
  article: (slug?: string) => `${ROUTES.articles}/${slug}`,

  whitepapers: `/whitepapers`,
  whitepaper: (slug?: string) => `${ROUTES.whitepapers}/${slug}`,

  funds: `/funds`,
  fund: (id?: string) => `${ROUTES.funds}?fundId=${id}`,

  guides: `/guides`,
  guide: (slug?: string) => `${ROUTES.guides}/${slug}`,

  profile: `/profile`,

  // Interstitials
  ceCredits: `/ce-credits`,
  ceCourseCredits: (courseId?: string) => `${ROUTES.ceCredits}/${courseId}`,
  ceCourseCreditsSuccess: (courseId?: string) =>
    `${ROUTES.ceCredits}/${courseId}/ok`,

  // Manage
  manage: '/manage',
  manageCourses: `/manage/courses`,
  manageFirms: `/manage/firms`,
  manageEditFirm: (firmId: string) => `${ROUTES.manageFirms}/edit/${firmId}`,
  manageExperiences: `/manage/experiences`,
  manageCreateExperience: () => `${ROUTES.manageExperiences}/create`,
  manageEditExperience: (experienceId: string) =>
    `${ROUTES.manageExperiences}/edit/${experienceId}`,
  enableCaisIq: (firmId: string) => `${ROUTES.manageFirms}/enable/${firmId}`,
}

const addAuthenticatedBaseHandler = <T = unknown>(target: T, prop: keyof T) => {
  const addBaseUnlessAlreadyAdded = (path: string) =>
    /[/]authenticated[/]/.test(path) ? path : `/authenticated${path}`

  const fn = target[prop] as (...props: unknown[]) => string
  if (isFunction(fn)) {
    return (...props: unknown[]) => {
      return addBaseUnlessAlreadyAdded(fn(...props))
    }
  }

  return addBaseUnlessAlreadyAdded(target[prop] as string)
}

const PROXIED_AUTH_ROUTES = new Proxy(AUTH_ROUTES, {
  get: addAuthenticatedBaseHandler,
})

const addMFEPathHandler = <T>(target: T, prop: keyof T) => {
  const fn = target[prop]
  if (isFunction(fn)) {
    return (...props: unknown[]) => getLoneOrMfePath(fn(...props))
  }

  return getLoneOrMfePath(target[prop] as To)
}

export const ROUTES = new Proxy(
  { ...NONAUTH_ROUTES, ...PROXIED_AUTH_ROUTES },
  { get: addMFEPathHandler }
)
