import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields } from './common'

const callToActionClicked = (buttonText: string) => {
  trackingService.track(
    /view reporting/i.test(buttonText)
      ? trackIds.viewReporting
      : trackIds.viewLearningCenter,
    { ...commonFields(), buttonText }
  )
}

export const actionTracking = {
  callToActionClicked,
}
