import { trackIds } from '@cais-group/caisiq/domain/segment'
import { User } from '@cais-group/shared/util/type/caisiq-be'

import { trackingService } from '..'

import { commonFields, segment } from './common'

const USER_SESSION_KEY = 'trackingService.user'

const handleUserFetched = (user: User) => {
  if (user.switchedUser) {
    return
  }
  if (sessionStorage.getItem(USER_SESSION_KEY) !== user.sub) {
    sessionStorage.setItem(USER_SESSION_KEY, user.sub)
    segment.identify(user.sub, {
      name: user.fullname,
      email: user.email,
      experience: user.experience.label,
    })
    segment.group(String(user.firmId), { name: user.firm })
  }
}

const handleLogout = () => {
  sessionStorage.removeItem(USER_SESSION_KEY)
}

const logoutClicked = () => {
  trackingService.track(trackIds.logoutClicked, {
    ...commonFields(),
  })
}
export const userTracking = {
  handleUserFetched,
  handleLogout,
  logoutClicked,
}
