import { useMedia } from 'react-use'

import { Skeleton, SkeletonAnimate } from '@cais-group/homepage/ui/skeleton'
import { BREAKPOINTS } from '@cais-group/shared/ui/design-tokens'

export const FeaturedSkeleton = ({
  compact,
  flip,
  testId,
}: {
  compact?: boolean
  flip?: 'horizontal' | 'vertical'
  testId?: string
}) => {
  const isNotMobile = useMedia(`(min-width: ${BREAKPOINTS.breakpointSm})`)
  let height = 192
  if (isNotMobile) {
    height = compact ? 240 : 300
  }
  return (
    <SkeletonAnimate
      className="bg-neutral-0 flex w-full min-w-[var(--min-width)] flex-col gap-y-16 md:flex-row md:gap-x-24"
      data-testid={`featured-content-${testId}-skeleton`}
    >
      <div
        className={`w-full ${
          flip === 'horizontal' ? 'order-last md:basis-2/5' : 'md:basis-1/2'
        }`}
      >
        <Skeleton variant="rectangular" height={height} />
      </div>
      <div
        className={`flex flex-col content-center gap-16 ${
          flip === 'horizontal' ? 'order-first md:basis-3/5' : 'md:basis-1/2'
        }`}
      >
        <Skeleton width={80} height={8} variant="rectangular" />
        <Skeleton height={48} variant="rectangular" />
        <Skeleton height={24} variant="rectangular" />
        <Skeleton height={24} variant="rectangular" />
        <Skeleton height={48} width={120} variant="rectangular" />
      </div>
    </SkeletonAnimate>
  )
}
