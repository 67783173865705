import type { ComponentType } from 'react'
import React from 'react'
import { createBrowserRouter, Navigate } from 'react-router-dom'

import { trackingLabels } from '@cais-group/homepage/util/tracking'
import { APP_IDS, APPS } from '@cais-group/shared/domain/apps'
import {
  Root,
  getRedirectOnNotFoundRouterPath,
} from '@cais-group/shared/ui/app-wrappers'
import { PageErrorSplash } from '@cais-group/shared/ui/contentful/components'
import { logWarning } from '@cais-group/shared/util/logging'

import { HomepageErrorBoundary, HomepageLayout } from './components/layout'
import { ROUTES } from './routes'
import { DISCLOSURE_PAGE_SLUG } from './screens/legal'
import { NotFound } from './screens/not-found'

const reloadIfMissing = () => {
  if (sessionStorage.getItem('reloadedDueToMissingRemote') === 'true') {
    console.error('Could not read the remote, reloading did not help')
    sessionStorage.removeItem('reloadedDueToMissingRemote')
  } else {
    logWarning({ message: 'Could not read the remote, reloading...' })
    sessionStorage.setItem('reloadedDueToMissingRemote', 'true')
    window.location.reload()
  }
  return React.Fragment
}

type Props<C extends ComponentType, K extends string> = {
  loader: () => Promise<{ [key in K]: C }>
  key: K
}

async function lazyLoader<C extends ComponentType, K extends string>(
  props: Props<C, K>
) {
  return props
    .loader()
    .then((module) => module[props.key])
    .catch(reloadIfMissing)
}

export const router = createBrowserRouter([
  {
    path: `${APP_IDS.HOMEPAGE}/${ROUTES.NOT_FOUND}`,
    element: <NotFound />,
  },
  {
    path: '/',
    element: <Root appName={APPS.HOMEPAGE} appBasePath={APP_IDS.HOMEPAGE} />,
    children: [
      {
        path: ROUTES.HOME,
        ErrorBoundary: HomepageErrorBoundary,
        element: <HomepageLayout />,
        children: [
          {
            index: true,
            lazy: async () => {
              const Home = await lazyLoader({
                loader: async () => import('./screens/home'),
                key: 'HomeIndex',
              })
              return {
                element: <Home />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Homepage,
              },
            },
          },
          {
            path: ROUTES.ARTICLES,
            lazy: async () => {
              const Articles = await lazyLoader({
                loader: async () => import('./screens/articles'),
                key: 'Articles',
              })
              return {
                element: <Articles />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Articles,
              },
            },
          },
          {
            path: ROUTES.VIDEOS,
            lazy: async () => {
              const Videos = await lazyLoader({
                loader: async () => import('./screens/videos'),
                key: 'Videos',
              })
              return {
                element: <Videos />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Videos,
              },
            },
          },
          {
            path: ROUTES.WHITEPAPERS,
            lazy: async () => {
              const Whitepapers = await lazyLoader({
                loader: async () => import('./screens/whitepapers'),
                key: 'Whitepapers',
              })
              return {
                element: <Whitepapers />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Whitepapers,
              },
            },
          },
          {
            path: ROUTES.ARTICLES_BFF,
            lazy: async () => {
              const Articles = await lazyLoader({
                loader: async () => import('./screens/articles-bff'),
                key: 'Articles',
              })
              return {
                element: <Articles />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Articles,
              },
            },
          },
          {
            path: ROUTES.HELP,
            lazy: async () => {
              const Help = await lazyLoader({
                loader: async () => import('./screens/help/layout'),
                key: 'HelpLayout',
              })
              return {
                element: <Help />,
              }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Help,
              },
            },
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.TUTORIALS} replace={true} />,
              },
              {
                path: ROUTES.TUTORIALS,
                lazy: async () => {
                  const Tutorials = await lazyLoader({
                    loader: async () => import('./screens/help/tutorials'),
                    key: 'TutorialsIndex',
                  })
                  return { element: <Tutorials /> }
                },
                handle: {
                  tracking: {
                    section: trackingLabels.section.Tutorials,
                  },
                },
              },
              {
                path: ROUTES.GLOSSARY,
                lazy: async () => {
                  const Glossary = await lazyLoader({
                    loader: async () => import('./feature/glossary'),
                    key: 'Glossary',
                  })
                  return { element: <Glossary /> }
                },
                handle: {
                  tracking: {
                    sub_section: trackingLabels.subSection.Glossary,
                  },
                },
              },
              {
                path: ROUTES.FAQS,
                lazy: async () => {
                  const Faqs = await lazyLoader({
                    loader: async () => import('./screens/help/faqs'),
                    key: 'Faqs',
                  })
                  return { element: <Faqs /> }
                },
                handle: {
                  tracking: {
                    sub_section: trackingLabels.subSection.FAQ,
                  },
                },
                children: [
                  {
                    path: ROUTES.FAQ_CATEGORY,
                    lazy: async () => {
                      const FaqCategory = await lazyLoader({
                        loader: async () =>
                          import('./screens/help/faqs/category'),
                        key: 'FaqCategory',
                      })
                      return { element: <FaqCategory /> }
                    },
                  },
                ],
              },
            ],
          },
          {
            path: DISCLOSURE_PAGE_SLUG,
            lazy: async () => {
              const DisclosurePageContainer = await lazyLoader({
                loader: async () => import('./screens/legal'),
                key: 'DisclosurePageContainer',
              })
              return { element: <DisclosurePageContainer /> }
            },
            handle: {
              tracking: {
                section: trackingLabels.section.Disclosure,
              },
            },
          },
          {
            path: ROUTES.EVENTS,
            lazy: async () => {
              const Events = await lazyLoader({
                loader: async () => await import('./feature/events/index'),
                key: 'Events',
              })

              return {
                element: <Events />,
              }
            },
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.IN_PERSON} replace />,
              },
              {
                path: ROUTES.WEBINARS,
                lazy: async () => {
                  const Webinars = await lazyLoader({
                    loader: async () => import('./screens/learn/webinars'),
                    key: 'WebinarsIndex',
                  })

                  return {
                    element: <Webinars />,
                  }
                },
                handle: {
                  tracking: {
                    section: trackingLabels.section.Webinars,
                  },
                },
              },
              {
                path: ROUTES.IN_PERSON,
                lazy: async () => {
                  const InPerson = await lazyLoader({
                    loader: async () =>
                      await import('./feature/events/in-person'),
                    key: 'InPerson',
                  })

                  return {
                    element: <InPerson />,
                  }
                },
                handle: {
                  tracking: {
                    section: trackingLabels.subSection.InPersonEvents,
                  },
                },
              },
            ],
          },
          {
            path: ROUTES.RESEARCH,
            lazy: async () => {
              const ResearchLayout = await lazyLoader({
                loader: async () => import('./screens/research/layout'),
                key: 'ResearchLayout',
              })

              return {
                element: <ResearchLayout />,
              }
            },
            children: [
              {
                index: true,
                element: <Navigate to={ROUTES.FEATURED} replace />,
              },
              {
                path: ROUTES.FEATURED,
                lazy: async () => {
                  const FeaturedResearch = await lazyLoader({
                    loader: async () => import('./screens/research/featured'),
                    key: 'FeaturedResearchIndex',
                  })

                  return {
                    element: <FeaturedResearch />,
                  }
                },
                handle: {
                  tracking: {
                    section: trackingLabels.section.FeaturedResearch,
                  },
                },
              },
              {
                path: ROUTES.ALL_RESEARCH,
                lazy: async () => {
                  const AllResearch = await lazyLoader({
                    loader: async () => import('./screens/research'),
                    key: 'AllResearchIndex',
                  })
                  return {
                    element: <AllResearch />,
                  }
                },
                handle: {
                  tracking: {
                    section: trackingLabels.section.AllResearch,
                  },
                },
              },
            ],
          },
          {
            path: ROUTES.STRUCTURED_INVESTMENTS,
            children: [
              {
                index: true,
                element: <PageErrorSplash.NotReached />,
              },
              {
                path: `${ROUTES.CHILD_SLUG}`,
                index: true,
                lazy: async () => {
                  const LandingPage = await lazyLoader({
                    loader: async () => import('./feature/landing-page'),
                    key: 'LandingPage',
                  })
                  return {
                    element: <LandingPage />,
                  }
                },
              },
            ],
          },
          {
            path: ROUTES.RESOURCES,
            children: [
              {
                index: true,
                element: <PageErrorSplash.NotReached />,
              },
              {
                path: `${ROUTES.PARENT_SLUG}`,
                lazy: async () => {
                  const LandingPage = await lazyLoader({
                    loader: async () => import('./feature/landing-page'),
                    key: 'LandingPage',
                  })
                  return {
                    element: <LandingPage />,
                  }
                },
              },
              {
                path: `${ROUTES.PARENT_SLUG}/${ROUTES.CHILD_SLUG}`,
                lazy: async () => {
                  const PageResourcesContainer = await lazyLoader({
                    loader: async () => import('./screens/resources'),
                    key: 'PageResourcesContainer',
                  })
                  return {
                    element: <PageResourcesContainer />,
                  }
                },
                handle: {
                  tracking: {
                    section:
                      trackingLabels.subSection.StructuredInvestmentsResources,
                  },
                },
              },
            ],
          },
          {
            path: '*',
            element: <PageErrorSplash.NotReached />,
          },
        ],
      },
      getRedirectOnNotFoundRouterPath(APPS.HOMEPAGE),
    ],
  },
])
