import {
  AsyncSubject,
  from,
  lastValueFrom,
  throwError,
  timer,
  type Subscription,
} from 'rxjs'
import { catchError, map, retry, tap } from 'rxjs/operators'

import type { UserProfileFirmV1 } from '@cais-group/access-manager/domain/api'
import {
  getFundProductIdsAndNames,
  type ErrorResponse,
} from '@cais-group/funds-pre-trade/domain/api'
import type { ErrorType } from '@cais-group/shared/util/fetch'
import type { UserProfileValue } from '@cais-group/shared/util/hook/use-user-profile'
import { logWarning } from '@cais-group/shared/util/logging'

import type { ContentPermissionsData } from '../shared-domain-contentful-api'

export type UserContentPermissionsState = {
  data: ContentPermissionsData
  isError: boolean
  isLoading: boolean
}

export const defaultState: UserContentPermissionsState = {
  data: {},
  isError: false,
  isLoading: true,
}

export class UserContentPermissionsService {
  private static instance: UserContentPermissionsService

  private contentPermissions$ = new AsyncSubject<UserContentPermissionsState>()
  private fundProductIds$ = new AsyncSubject<string[]>()
  private subscription: Subscription | null = null
  private userProfile: UserProfileValue | null = null
  private userProfileFirms: UserProfileFirmV1[] = []

  public static getInstance(): UserContentPermissionsService {
    if (!UserContentPermissionsService.instance) {
      UserContentPermissionsService.instance =
        new UserContentPermissionsService()
    }
    return UserContentPermissionsService.instance
  }

  public init(userProfile: UserProfileValue, hasPermission?: boolean) {
    this.userProfile = userProfile
    if (hasPermission) {
      this.fetchAndStoreContentPermissions()
    } else {
      this.contentPermissions$.next({
        ...defaultState,
        isLoading: false,
        data: {
          firmIds: this.getFirmIds(),
        },
      })
      this.contentPermissions$.complete()
    }
  }

  private async fetchAndStoreContentPermissions(): Promise<void> {
    const firmIds = this.getFirmIds()
    await this.fetchFundProductIds()

    this.subscription = this.fundProductIds$
      .pipe(
        map((fundProductIds) => {
          const finalState = {
            data: {
              fundProductIds,
              firmIds: firmIds,
            },
            isError: false,
            isLoading: false,
          }
          return finalState
        })
      )
      .subscribe({
        next: (permissions) => {
          this.contentPermissions$.next(permissions)
          this.contentPermissions$.complete()
        },
        error: () => {
          const errorState = {
            data: {
              fundProductIds: [],
              firmIds: firmIds,
            },
            isError: true,
            isLoading: false,
          }
          this.contentPermissions$.next(errorState)
          this.contentPermissions$.complete()
        },
      })
  }

  private errorFunction(error: ErrorType<ErrorResponse>) {
    this.fundProductIds$.next([])
    this.fundProductIds$.error(error)
    this.fundProductIds$.complete()
    logWarning({
      message: 'There was an error loading the allowed funds',
      error,
    })
    return throwError(() => error)
  }

  private async fetchFundProductIds() {
    from(getFundProductIdsAndNames())
      .pipe(
        retry({
          count: 2,
          resetOnSuccess: true,
          delay: (error: ErrorType<ErrorResponse>, retryCount) => {
            if (error?.type?.status === 403) {
              return this.errorFunction(error)
            }
            return timer(retryCount * 1000)
          },
        }),
        catchError((error: ErrorType<ErrorResponse>) => {
          return this.errorFunction(error)
        }),
        map((response) => {
          return response.fundProducts?.map((fund) => fund.fundProductId) ?? []
        }),
        tap((fundProductIds) => {
          if (!fundProductIds.length) {
            logWarning({
              message: 'User had no allowed funds returned from API',
              error: new Error('User had no allowed funds returned from API'),
            })
          }
        })
      )
      .subscribe({
        next: (fundProductIds) => {
          this.fundProductIds$.next(fundProductIds)
          this.fundProductIds$.complete()
        },
        error: (error) => {
          this.fundProductIds$.error(error)
        },
        complete: () => {
          this.fundProductIds$.unsubscribe()
        },
      })
  }

  private getFirmIds() {
    this.userProfileFirms =
      this.userProfile?.userProfileState.userProfile?.allParentFirms || []
    return this.userProfileFirms?.map((firm) => firm?.id as string)
  }

  public get contentPermissionsAsync() {
    return lastValueFrom(this.contentPermissions$)
  }

  public getAllowedFirms() {
    const isLoading = this.userProfile?.userProfileState.status === 'loading'
    const isError = this.userProfile?.userProfileState.status === 'error'

    return {
      data: this.userProfileFirms,
      isLoading,
      isError,
    }
  }

  public cleanup() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}

export const userContentPermissionsService =
  UserContentPermissionsService.getInstance()
