import type { Node } from '@contentful/rich-text-types'
import { ReactNode } from 'react'
import { Link as RRLink } from 'react-router-dom'

import { Link } from '@cais-group/shared/ui/link'
import { TypeArticleData } from '@cais-group/shared/util/type/article-data'
import {
  BlockEntry,
  EntryMap,
} from '@cais-group/shared/util/type/rich-text-json'
import { WhitepaperData } from '@cais-group/shared/util/type/whitepaper-data'

// TODO HP-102 trackingService is specific to CAIS IQ although some could be shared i.e. move Segment track + page handlers to shared lib
const trackingService = {
  externalLinkClicked: (screen: string, text: string, url: string) => {},
}

function externalLink(node: Node, children: ReactNode, segmentPageId?: string) {
  return (
    <Link
      href={node.data['uri']}
      onClick={() => {
        trackingService.externalLinkClicked(
          segmentPageId || '',
          String(children),
          node.data['uri']
        )
      }}
    >
      {children}
    </Link>
  )
}

function footnoteLink(props: { to: string; children: React.ReactNode }) {
  return (
    <a href={props.to}>
      <sup style={{ lineHeight: '1' }}>{props.children}</sup>
    </a>
  )
}

function inlineLink(node: Node, children: ReactNode, segmentPageId?: string) {
  const footnotesId = `#footnotes`
  const { pathname, search } = window.location
  // Check if link is a footnote
  if (node.data['uri'].indexOf(footnotesId) === 0) {
    return footnoteLink({ to: `${pathname}${search}${footnotesId}`, children })
  }

  return externalLink(node, children, segmentPageId)
}

function isCaisiqArticle(entry?: BlockEntry) {
  return entry !== undefined && entry.__typename === 'CaisiqArticle'
}

function isWhitepaper(entry?: BlockEntry) {
  return entry !== undefined && entry.__typename === 'Whitepaper'
}

export function getResourceParentPath(entry: BlockEntry) {
  if (isCaisiqArticle(entry)) {
    return 'articles'
  }

  if (isWhitepaper(entry)) {
    return 'whitepapers'
  }

  return undefined
}

// NOTE HP- "authenticated/" path prefix removed since that's only relevant to cais iq
function embeddedEntryLink(
  node: Node,
  children: ReactNode,
  entryMap: EntryMap,
  urlPrefix = ''
) {
  const id = node.data['target'].sys.id
  const entry = entryMap.get(id) as WhitepaperData | TypeArticleData

  if (!entry) {
    return children
  }

  return (
    <RRLink
      to={`${urlPrefix}/${getResourceParentPath(entry)}/${entry.slug}`}
      state={{ from: `${urlPrefix}/${getResourceParentPath(entry)}` }}
    >
      {children}
    </RRLink>
  )
}

export { footnoteLink, inlineLink, embeddedEntryLink }
