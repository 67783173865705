import cx from 'classnames'
import { HTMLAttributes } from 'react'

import { Icon } from '@cais-group/equity/atoms/icon'
import { mapTrackingKeysToDataAttributes } from '@cais-group/equity/util/tracking-utils'
import { capitalizeFirstLetter } from '@cais-group/equity/utilitarian'

export type CarouselButtonProps = {
  direction: 'prev' | 'next'
  disabled: boolean
  handleClick?: () => void
} & HTMLAttributes<HTMLButtonElement>

export function CarouselButton(props: CarouselButtonProps) {
  const { disabled, direction, handleClick, ...rest } = props
  return (
    <button
      {...(disabled && { disabled: true })}
      {...rest}
      tabIndex={disabled ? -1 : 0}
      type="button"
      onClick={handleClick}
      aria-label={`${direction} items`}
      className={cx(
        'cais-not-touch duration-medium-2 group absolute top-1/2 -translate-y-1/2 transition-opacity',
        {
          'left-0 -translate-x-1/2 rotate-180': direction === 'prev',
        },
        {
          'right-0 translate-x-1/2': direction === 'next',
        },
        {
          'pointer-events-none opacity-0': disabled,
        }
      )}
      {...mapTrackingKeysToDataAttributes({
        click_type: 'Scroll',
        item_name: capitalizeFirstLetter(direction),
      })}
    >
      <span
        className="grid aspect-square w-[32px] place-items-center rounded-full bg-neutral-300 text-neutral-900 group-hover:bg-neutral-400 group-hover:text-neutral-700 group-focus:bg-neutral-300"
        aria-hidden="true"
      >
        <Icon type="KeyboardArrowRight" />
      </span>
    </button>
  )
}
