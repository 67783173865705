import { AnalyticsBrowser } from '@segment/analytics-next'

import { ROUTES } from '@cais-group/caisiq/feature/routes'
import { experienceService } from '@cais-group/caisiq/util/experience-service'
import { APPS } from '@cais-group/shared/domain/apps'
import { Course } from '@cais-group/shared/util/type/caisiq-be'

const defaultAnalyticsBrowser = {
  identify: () => {},
  track: () => {},
  page: () => {},
  group: () => {},
} as unknown as AnalyticsBrowser

/**
 * We set the default so that segment is not undefined in Storybook and tests without being
 * explicitly initialized.
 */
export let segment: AnalyticsBrowser = defaultAnalyticsBrowser
export let appName: APPS

export const getPageFromLocation = (location: Location) => {
  switch (true) {
    case location.pathname.includes('articles'):
      return 'Articles'
    case location.pathname.includes('courses'):
      return 'Courses'
    case location.pathname.includes('assignment'):
      return 'Assignment'
    case location.pathname.includes('course-library'):
      return 'Course Library'
    case location.pathname.includes('homeoffice'):
      return 'Home Office'
    case location.pathname.includes('videos'):
      return 'Videos'
    case location.pathname.includes('whitepapers'):
      return 'Whitepapers'
    case location.pathname.includes('funds'):
      return 'Funds'
    case location.pathname.includes('guides'):
      return 'Guides'
    case location.pathname.includes('profile'):
      return 'Profile'
    case location.pathname.includes('manage/experiences'):
      return 'Manage Experiences'
    case location.pathname.includes('manage/firms'):
      return 'Manage Firms'
    case location.pathname.includes(ROUTES.ceCredits):
      return 'CE Interstitials'
    default:
      return 'Unknown'
  }
}

export const commonCourseFields = (course: Course) => ({
  assessment_score: course?.score,
  course_id: course.id,
  course_name: course.name,
  progress: course?.progress,
  state: course?.type,
})

export const commonFields = () => ({
  app: appName,
  primary_tab: getPageFromLocation(window.location),
  experience_name: experienceService.getExperience(),
})

export const attachSegment = (apiKey: string, app: APPS) => {
  segment = AnalyticsBrowser.load({
    writeKey: apiKey,
  })
  appName = app
}
