import { To, Path, resolvePath } from 'react-router-dom'

function pathToString(path: Partial<Path>) {
  const { pathname, search, hash } = path
  return `${pathname}${search}${hash}`
}

/**
 * Prepends /cais-iq/ to the passed in path if the app is running in MFE and is absolute.
 */
export function getLoneOrMfePath(to: To) {
  const isMfe = window?.location?.pathname?.match(/^\/cais-iq/) !== null
  const url = typeof to === 'string' ? to : pathToString(to)
  const caisiqPath = url.startsWith('/')
    ? pathToString(resolvePath(url))
    : pathToString(resolvePath(url, window.location.pathname))

  return isMfe && !caisiqPath.startsWith(`/cais-iq`)
    ? `/cais-iq${caisiqPath}`
    : caisiqPath
}
