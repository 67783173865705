import type { AnnouncementProps } from '@cais-group/equity/labs/announcement'
import type { AllColor } from '@cais-group/equity/particles/colors'
import { parseFirstFromCollection } from '@cais-group/shared/domain/contentful/api'
import { prepareLinkProps } from '@cais-group/shared/util/contentful/content-helpers'
import type {
  AnnouncementFragment,
  GetAnnouncementsQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import {
  announcementsService,
  DATE_FORMAT,
  type DismissedAnnouncementsStorage,
} from './announcements-service'
import { Adornment } from './components/adornment'
import type { AnnouncementSet } from './components/types'

export function isValidUrl(
  url: string,
  excludedUrls?: AnnouncementSet['excludedUrls']
) {
  if (!excludedUrls) {
    return true
  }
  return !excludedUrls.some((excludedUrl) => url.startsWith(excludedUrl))
}

function isValidTime(start: string, end: string | null) {
  const now = today(DATE_FORMAT)
  return start <= now && (end === null || end >= now)
}
// TODO: Remove this once we move to BFF
function hasBeenDismissed<
  T extends keyof DismissedAnnouncementsStorage['announcement']
>(id: T) {
  const { announcementsDismissed } = announcementsService
  if (
    announcementsDismissed[id] &&
    announcementsDismissed[id].dateTimeEnd < today(DATE_FORMAT)
  ) {
    announcementsService.removeAnnouncementDismissed(id)
    return true
  }
  return announcementsDismissed[id]
}

// TODO: Remove this once we move to BFF
export function canShowAnnouncement(
  start: string,
  end: string | null,
  id: string
) {
  return isValidTime(start, end) && !hasBeenDismissed(id)
}
export function prepareImageProps(
  image?: {
    public_id: string
    width: number
    height: number
  } | null
) {
  if (!image) {
    return null
  }
  return {
    src: image.public_id,
    width: 48,
    aspect_ratio: image.width / image.height,
  }
}

export function prepareAnnouncementProps({
  announcement,
  dismissAnnouncement,
  isSwitched,
  appUrl,
}: {
  announcement: Omit<AnnouncementFragment, 'icon'> | null
  dismissAnnouncement: () => void
  isSwitched: boolean
  appUrl: string
}): AnnouncementProps | null {
  if (!announcement) {
    return null
  }

  return {
    title: announcement.title || '',
    description: announcement.description || '',
    action: {
      text: announcement.buttonText || '',
      handler: dismissAnnouncement,
      // TODO - remove this once MFE is removed
      href:
        announcement.destination?.internalLink ||
        prepareLinkProps(appUrl, announcement.destination)?.href,
      disabled: isSwitched,
    },
    onDismiss: dismissAnnouncement,
    adornment: (color: AllColor) => (
      <Adornment color={color} announcement={announcement} />
    ),
    disabled: isSwitched,
  }
}

export function selectAnnouncements(data: GetAnnouncementsQuery) {
  const announcementSet = parseFirstFromCollection<
    GetAnnouncementsQuery,
    NonNullable<GetAnnouncementsQuery['announcementSetCollection']>['items'][0]
  >(data, 'announcementSetCollection')

  const announcementDefault =
    announcementSet?.announcementDefault as AnnouncementFragment

  const announcementOptionsCollection = announcementSet
    ?.announcementOptionsCollection?.items as AnnouncementFragment[]

  const excludedUrls =
    announcementSet?.excludedUrls as AnnouncementSet['excludedUrls']

  return {
    announcementDefault,
    announcementOptionsCollection: announcementOptionsCollection,
    excludedUrls,
  }
}
