export enum Experience {
  CAIS_INTERNAL = 'cais-internal',
  BUCKINGHAM = 'buckingham',
  FOCUSFINANCIAL = 'focusfinancial',
  NORTHWESTERN = 'northwestern',
  CAIS_IQ_DIRECT = 'cais',
  ADVISOR_GROUP = 'ag',
  HILLTOP = 'hilltop',
  UNSPECIFIED = 'unspecified',
  FRANKLINTEMPLETON = 'franklintempleton',
  MONETA = 'moneta',
  WEG = 'weg',
  KINGSVIEW = 'kingsview',
  TREKFINANCIAL = 'trekfinancial',
  LEVELFOUR = 'levelfour',
  BORDEAUX = 'bordeaux',
  THE_COLONY_GROUP = 'thecolonygroup',
  GYL = 'gyl',
  VESTOR_CAPITAL = 'vestorcapital',
  PERSHING_DIRECT = 'pershing-direct',
}
