import {
  isData,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import {
  Disclaimer,
  DisclaimerBody,
  GetDisclaimerQuery,
  useGetDisclaimerQuery,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export const useGetDisclaimer = (variables: { id: string }) => {
  const response = useReactQueryResultAsApiState<
    GetDisclaimerQuery,
    Pick<Disclaimer, 'title' | 'body'>
  >(
    useGetDisclaimerQuery(
      {
        ...variables,
      },
      {
        enabled: Boolean(variables.id),
        refetchOnWindowFocus: false,
      }
    ),
    (data) => ({
      title: data?.disclaimer?.items?.[0]?.title as Disclaimer['title'],
      body: data?.disclaimer?.items?.[0]?.body as DisclaimerBody,
    }),
    'Could not load disclaimer query'
  )

  return isData(response) ? response : null
}
