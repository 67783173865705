import axios, {
  isAxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios'
import { ClientError } from 'graphql-request'
import { QueryObserverResult } from 'react-query'

import {
  HOMEPAGE_BFF,
  HOMEPAGE_BFF_PORT,
} from '@cais-group/homepage/util/common'
import { getEnvConfig } from '@cais-group/shared/ui/env'
import { authService } from '@cais-group/shared/util/auth-service'
import {
  FundProductId,
  MemberFirmCollection,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logError, logWarning } from '@cais-group/shared/util/logging'

import { userContentPermissionsService } from './content-permissions'

export class ApiError extends Error {
  originalError?: string | ApiError
  httpStatus?: number
  constructor(options: {
    message: string
    originalError?: string | ApiError
    httpStatus?: number
  }) {
    super(options.message)
    this.name = this.constructor.name
    this.originalError = options.originalError
    this.httpStatus = options.httpStatus
  }
}

export enum ApiStateEnum {
  LOADING = 'LOADING',
  INIT = 'INIT',
}

export type ApiState<T> = T | ApiError | ApiStateEnum

export const isApiState = <T>(
  maybeApiState: ApiState<T> | unknown
): maybeApiState is ApiState<T> => {
  return (
    maybeApiState === ApiStateEnum.LOADING ||
    maybeApiState === ApiStateEnum.INIT ||
    maybeApiState instanceof ApiError
  )
}

export const isQueryObserverResult = (
  maybeQueryObserverResult: unknown
): maybeQueryObserverResult is QueryObserverResult<unknown, unknown> => {
  return (
    typeof maybeQueryObserverResult === 'object' &&
    maybeQueryObserverResult !== null &&
    'status' in maybeQueryObserverResult
  )
}

export const isData = <T>(apiState: ApiState<T>): apiState is T => {
  return (
    apiState !== ApiStateEnum.LOADING &&
    apiState !== ApiStateEnum.INIT &&
    !(apiState instanceof ApiError)
  )
}

type GQLCollection<TData> = {
  items: Array<TData>
  total: number
}

export function parseFromCollection<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
) {
  if (!collection) {
    return []
  }

  const collectionObject = collection[name] as GQLCollection<TData>

  if (!collectionObject || !('items' in collectionObject)) {
    return []
  }
  return collectionObject['items']
}

export function parseFirstFromCollection<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
) {
  const all = parseFromCollection<TCollection, TData>(collection, name)
  return all.length ? all[0] : undefined
}

export const isError = (apiState: ApiState<unknown>): apiState is ApiError =>
  apiState instanceof ApiError

export function useReactQueryResultAsApiState<TQuery, TData>(
  result: QueryObserverResult<TQuery, ClientError>,
  extractData: (data: TQuery) => TData,
  errorMessage: string
) {
  const { data, isFetching, error } = result

  if (isFetching) {
    return ApiStateEnum.LOADING
  } else if (error) {
    const apiError = new ApiError({
      message: errorMessage,
      originalError: error,
    })
    logError({
      message: errorMessage,
      error: apiError,
    })
    return apiError
  } else if (data) {
    return extractData(data)
  }
  return ApiStateEnum.INIT
}

type ContentAccessKeys = {
  fundProductIds?: (string | null)[]
  firmIds?: string[]
}
export type ContentPermissionsData = {
  [key in keyof ContentAccessKeys]: ContentAccessKeys[key]
}

/**
 * Generic and easily extendable function that checks if the user has access to the content they are trying to access based on the permissions they have by comparing the allowed permissions with the content permissions.
 * If useGetAllowedPermissions' allowedPermissionsData is ever extended, this function will still work when ContentAccessKeys is updated.
 * @param allowedPermissionsData
 * @param contentPermissionsData
 * @returns boolean
 */
export function checkContentAccess<
  TData extends Record<string, Array<unknown>>
>(
  allowedPermissionsData: TData,
  contentPermissionsData?: Partial<TData>
): boolean {
  if (!contentPermissionsData) {
    return true
  }
  const keys = Object.keys(allowedPermissionsData)
  return keys.every((key) => {
    const contentPermissionIds = contentPermissionsData?.[key]
    const allowedPermissionIds = allowedPermissionsData[key]
    return Array.isArray(contentPermissionIds) &&
      contentPermissionIds.length > 0
      ? allowedPermissionIds.some((c) => contentPermissionIds?.includes(c))
      : true
  })
}

type PermissionsData = {
  fundProductIds?: FundProductId | null
  firmsCollection?: MemberFirmCollection | null
}
export function transformContentPermissionsData<TData>({
  data,
  name,
}: {
  name: keyof TData
  data?: TData
}): ContentPermissionsData & {
  notFound?: boolean
} {
  const contentPermissions = parseFirstFromCollection<TData, PermissionsData>(
    data,
    name
  )

  const { fundProductIds, firmsCollection } = contentPermissions || {}

  return {
    fundProductIds: fundProductIds?.fundProductIds as string[] | undefined,
    firmIds: (firmsCollection?.items.map((firm) => firm?.id) ||
      []) as ContentPermissionsData['firmIds'],
    notFound: !contentExists(data, name),
  }
}

function contentExists<TCollection, TData>(
  collection: TCollection | undefined,
  name: keyof TCollection
): boolean {
  if (!collection) {
    return false
  }
  const collectionObject = collection[name] as GQLCollection<TData>
  return collectionObject.total > 0
}

let axiosInstance: AxiosInstance

type RequestConfig = AxiosRequestConfig & {
  url: string
}

export async function fetchInstance<T>(config: RequestConfig): Promise<T> {
  config.method = config.method || 'POST'

  try {
    const axios = axiosInstance || (await createAxiosInstance())
    const response = await axios(config)

    return response.data
  } catch (error) {
    if (isAxiosError(error)) {
      throw new ApiError(error)
    } else {
      throw error
    }
  }
}

async function createAxiosInstance() {
  const env = getEnvConfig()

  const [accessToken, permissions] = await Promise.all([
    authService.getAccessTokenSilently(),
    userContentPermissionsService.contentPermissionsAsync,
  ])

  let baseURL = HOMEPAGE_BFF

  if (env.ENVIRONMENT === 'localhost') {
    if (await isLocalHomepageBffRunning()) {
      baseURL = `http://localhost:${HOMEPAGE_BFF_PORT}${HOMEPAGE_BFF}`
    } else {
      baseURL = `https://members.dev.caisgroup.com${HOMEPAGE_BFF}`
    }
    logBrowserMessage(baseURL)
  }

  // ephemeral environments
  if ('HOMEPAGE_BFF_URL' in env) {
    baseURL = `${env.HOMEPAGE_BFF_URL}${HOMEPAGE_BFF}`
  }

  axiosInstance = axios.create({ baseURL })

  axiosInstance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${accessToken}`

  axiosInstance.interceptors.request.use(
    (config) => {
      config.data = {
        ...config.data,
        contentPermissions: permissions?.data,
      }
      return config
    },
    (error) => {
      logWarning({
        message: 'Failed to add content permissions to request',
        error,
      })
      return Promise.reject(error)
    }
  )

  return axiosInstance
}

async function isLocalHomepageBffRunning() {
  try {
    const response = await fetch(
      `http://localhost:${HOMEPAGE_BFF_PORT}${HOMEPAGE_BFF}`,
      { signal: AbortSignal.timeout(3000) }
    )
    return response.ok
  } catch {
    return false
  }
}

function logBrowserMessage(message: string) {
  console.info(
    `%c Info: homepage-bff is running on ${message}`,
    'color:green; font-weight:600;'
  )
}
