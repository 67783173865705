import {
  filterAllowedPermissionData,
  type ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { today } from '@cais-group/shared/util/time/date-time-contentful'

import type { AnnouncementSet } from './components/types'
import { canShowAnnouncement, isValidUrl } from './helpers'

export const HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE =
  'HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE'
export const HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION =
  'HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION'

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm'

export type DismissedAnnouncementsStorage = {
  announcement: Record<
    string,
    {
      dismissed: boolean
      dateTimeEnd: string
    }
  >
  allowSingleDismissalPerSession?: boolean
}
let _data: AnnouncementSet | undefined
let _permissions:
  | Pick<ContentPermissionsData, 'firmIds' | 'fundProductIds'>
  | undefined
let _userId: string

// TODO: When we move to BFF, we will only need the userId to initiate announcements
export const announcementsService = {
  initiateAnnouncements: (
    data?: AnnouncementSet,
    userId?: string,
    permissions?: Pick<ContentPermissionsData, 'firmIds' | 'fundProductIds'>
  ) => {
    _permissions = permissions
    _data = data
    _userId = userId || 'unknown-user'
  },
  get announcementsDismissed() {
    return JSON.parse(
      localStorage.getItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`
      ) || '{}'
    ) as DismissedAnnouncementsStorage['announcement']
  },
  // TODO: This is for BFF only at this moment
  get announcementsDismissedIds() {
    const { announcementsDismissed } = announcementsService
    return Object.entries(announcementsDismissed).reduce((acc, [id]) => {
      if (announcementsDismissed[id].dateTimeEnd < today(DATE_FORMAT)) {
        announcementsService.removeAnnouncementDismissed(id)
      } else {
        acc.push(id)
      }
      return acc
    }, [] as string[])
  },
  // TODO: When we move to BFF, this can be removed
  get announcementDefault() {
    return _data?.announcementDefault &&
      canShowAnnouncement(
        _data.announcementDefault.dateTimeStart,
        _data.announcementDefault.dateTimeEnd,
        _data.announcementDefault.sys.id
      )
      ? _data.announcementDefault
      : null
  },
  // TODO: When we move to BFF, this can be removed
  get announcementOptions() {
    return _data?.announcementOptionsCollection && _permissions
      ? filterAllowedPermissionData(
          _data.announcementOptionsCollection,
          _permissions
        )
      : []
  },
  // TODO: When we move to BFF, this can be removed
  get announcement() {
    if (
      !isValidUrl(window.location.pathname, _data?.excludedUrls) ||
      announcementsService.hasDismissedThisSession
    ) {
      return null
    }
    const { announcementDefault, announcementOptions } = announcementsService

    const optionalAnnouncement = announcementOptions.find(
      ({ dateTimeEnd, dateTimeStart, sys }) =>
        canShowAnnouncement(dateTimeStart, dateTimeEnd, sys.id)
    )

    return optionalAnnouncement || announcementDefault
  },
  get hasDismissedThisSession() {
    return (
      (JSON.parse(
        localStorage.getItem(
          `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`
        ) || 'false'
      ) as boolean) || false
    )
  },
  // TODO: For bff only
  canShowAnnouncement(
    pathname: string,
    excludedUrls?: AnnouncementSet['excludedUrls']
  ) {
    return (
      !announcementsService.hasDismissedThisSession &&
      isValidUrl(pathname, excludedUrls)
    )
  },
  setAnnouncementsDismissed({
    announcement,
    allowSingleDismissalPerSession,
  }: DismissedAnnouncementsStorage) {
    const { announcementsDismissed } = announcementsService
    localStorage.setItem(
      `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`,
      JSON.stringify({ ...announcementsDismissed, ...announcement })
    )
    if (allowSingleDismissalPerSession) {
      localStorage.setItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`,
        JSON.stringify(allowSingleDismissalPerSession)
      )
    }
  },
  removeAnnouncementDismissed(id: string) {
    const { announcementsDismissed } = announcementsService
    delete announcementsDismissed[id]
    // if empty, remove the item from local storage
    if (Object.keys(announcementsDismissed).length === 0) {
      localStorage.removeItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`
      )
    } else {
      localStorage.setItem(
        `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_STORAGE}-${_userId}`,
        JSON.stringify(announcementsDismissed)
      )
    }
  },
  clearHasDismissed() {
    localStorage.removeItem(
      `${HOMEPAGE_ANNOUNCEMENTS_DISMISSED_SESSION}-${_userId}`
    )
  },
}

export const __TEST__ = announcementsService
