import { iconSizes } from '../icons'

export const brandIconSizes = {
  small: iconSizes['small'],
  regular: iconSizes['regular'],
  large: iconSizes['large'],
} as const

export type BrandIconSize = keyof typeof brandIconSizes

export const brandIconTypes = {
  Accounts: 'Accounts',
  Alternatives: 'Alternatives',
  AssetManagers: 'AssetManagers',
  BankIssuers: 'BankIssuers',
  CaisIQ: 'CaisIQ',
  CaisSolutions: 'CaisSolutions',
  Compass: 'Compass',
  CustomFunds: 'CustomFunds',
  Dashboard: 'Dashboard',
  Enterprise: 'Enterprise',
  Marketplace: 'Marketplace',
  StructuredNotes: 'StructuredNotes',
  Trade: 'Trade',
  Tutorials: 'Tutorials',
  Webinars: 'Webinars',
} as const

export type BrandIconType = keyof typeof brandIconTypes
