import type { PropsWithChildren } from 'react'
import { Link as RRLink } from 'react-router-dom'

const httpRegex = /^(https?:)?\/\//i

const externalLinkAttributes = {
  target: '_blank',
  rel: 'noreferrer noopener',
}

export const isExternalLink = (link: string): boolean => httpRegex.test(link)

export function Link(
  props: PropsWithChildren<{
    href: string
    external?: boolean
    onClick?: () => void
    state?: Record<string, unknown>
  }>
) {
  const { children = '', external, href, state } = props
  const onClick = props.onClick ? { onClick: props.onClick } : undefined

  if (external || isExternalLink(href)) {
    return (
      <a href={href} {...externalLinkAttributes} {...onClick}>
        {children}
      </a>
    )
  }

  return (
    <RRLink to={href} {...onClick} state={state}>
      {children}
    </RRLink>
  )
}
