import * as React from 'react'
import { useLocation } from 'react-router-dom'

// NOTE: `yarn modernizr` script generates assets/modernizr.js
// file in this lib and it is injected into the shared-ui-app-wrappers.tsx

export const TestBrowserSupport = () => {
  const location = useLocation()
  const [projectName] = location.pathname.split('/').filter(Boolean)

  React.useEffect(() => {
    if (!window.Modernizr.es6string) {
      window.location.href = `/${projectName}/assets/unsupported.html`
    }
  }, [projectName])

  return null
}
