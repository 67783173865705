import { Skeleton, SkeletonAnimate } from '@cais-group/homepage/ui/skeleton'

export const SkeletonHeader = ({
  title,
  type,
}: {
  title: string
  type?: 'counter' | 'tabs'
}) => {
  let action = null
  if (type === 'counter') {
    action = <Skeleton variant="circular" height={32} width={32} />
  }
  if (type === 'tabs') {
    action = Array.from({ length: 5 }).map((_, i) => (
      <Skeleton key={i} variant="circular" height={42} width={100} />
    ))
  }

  return (
    <SkeletonAnimate className="flex flex-wrap items-center justify-start gap-16">
      <Skeleton>
        <h2 className="headline-m-strong">{title}</h2>
      </Skeleton>
      {action}
    </SkeletonAnimate>
  )
}
