import { type UserProfileValue } from '@cais-group/shared/util/hook/use-user-profile'

export const useFundProductsAdvisorReadPermission = (
  userProfile: UserProfileValue
) => {
  const permissions = userProfile?.userProfileState.userProfile?.permissions
  const hasFundProductsAdvisorReadPermission = permissions?.includes(
    'funds:products:advisor-read'
  )
  return { hasFundProductsAdvisorReadPermission }
}
