import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields } from './common'

const viewLearningCenterButtonClicked = (buttonText: string) => {
  trackingService.track(trackIds.viewLearningCenter, {
    ...commonFields(),
    button_text: buttonText,
  })
}

const viewReportingButtonClicked = (buttonText: string) => {
  trackingService.track(trackIds.viewReporting, {
    ...commonFields(),
    button_text: buttonText,
  })
}

const manageCaisiqButtonClicked = () => {
  trackingService.track(trackIds.manageCaisiqButtonClicked, {
    ...commonFields(),
  })
}

export const navigationTracking = {
  viewLearningCenterButtonClicked,
  viewReportingButtonClicked,
  manageCaisiqButtonClicked,
}
