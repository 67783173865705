import { MuxPlayerRefAttributes } from '@mux/mux-player-react'

import { trackIds } from '@cais-group/caisiq/domain/segment'

import { trackingService } from '..'

import { commonFields } from './common'

type Video = MuxPlayerRefAttributes
type Metadata = {
  video_id: string
  duration?: number
  video_title: string
  url: string
  watchId: string
}

const videoPlaybackLoaded = (metadata: Metadata) => {
  trackingService.track(trackIds.videoPlaybackLoaded, {
    ...commonFields(),
    url: metadata.url,
    video_title: metadata.video_title,
  })
}

const videoPlaybackStarted = (metadata: Metadata) => {
  trackingService.track(trackIds.videoPlaybackStarted, {
    ...commonFields(),
    total_length: metadata.duration,
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
  })
}

const videoPlaybackPaused = (video: Video, metadata: Metadata) => {
  trackingService.track(trackIds.videoPlaybackPaused, {
    ...commonFields(),
    time_elapsed: video.currentTime,
    total_length: metadata.duration,
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
  })
}

const videoPlaybackResumed = (video: Video, metadata: Metadata) => {
  trackingService.track(trackIds.videoPlaybackResumed, {
    ...commonFields(),
    time_elapsed: video.currentTime,
    total_length: metadata.duration,
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
  })
}
const videoPlaybackCompleted = (metadata: Metadata) => {
  trackingService.track(trackIds.videoPlaybackCompleted, {
    ...commonFields(),
    total_length: metadata.duration,
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
  })
}

const videoPlaybackCancelled = (
  metadata: Metadata,
  reason: string,
  timeElapsed: number,
  totalLength: number,
  percentWatched: string
) => {
  const percentWatchedSegment = (percentWatched: number): number => {
    const bands = [75, 50, 25, 0]
    for (const band of bands) {
      if (percentWatched >= band) {
        return band
      }
    }
    return 0
  }

  trackingService.track(trackIds.videoPlaybackCancelled, {
    cancellation_reason: reason,
    ...commonFields(),
    percent_watched: Number(percentWatched),
    percent_watched_segment: percentWatchedSegment(Number(percentWatched)),
    time_elapsed: timeElapsed,
    total_length: totalLength,
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
  })
}

export const videoTracking = {
  videoPlaybackLoaded,
  videoPlaybackStarted,
  videoPlaybackPaused,
  videoPlaybackResumed,
  videoPlaybackCompleted,
  videoPlaybackCancelled,
}
