import { actionTracking } from './events/actions'
import { articleTracking } from './events/articles'
import { ceTracking } from './events/ce-credits'
import { attachSegment } from './events/common'
import { conferenceTracking } from './events/conferences'
import { courseTracking } from './events/courses'
import { linkTracking } from './events/links'
import { manageTracking } from './events/manage'
import { navigationTracking } from './events/navigation'
import { signupTracking } from './events/signups'
import { userTracking } from './events/user'
import { videoTracking } from './events/videos'
import { segmentWrappers } from './utils/segmentWrappers'

export const trackingService = {
  ...segmentWrappers,
  initialise: attachSegment,
  endSession: userTracking.handleLogout,
  onUserFetched: userTracking.handleUserFetched,
  logoutClicked: userTracking.logoutClicked,
  ...actionTracking,
  ...linkTracking,
  ...courseTracking,
  ...articleTracking,
  ...videoTracking,
  ...conferenceTracking,
  ...signupTracking,
  ...manageTracking,
  ...navigationTracking,
  ...ceTracking,
}
