import { Theme as DefaultTheme } from '@emotion/react'

import { UserType } from '@cais-group/shared/util/graphql'
import {
  TUserRole,
  User,
  UserCapabilities,
  UserRole,
} from '@cais-group/shared/util/type/caisiq-be'
import { ExperienceData } from '@cais-group/shared/util/type/experience-data'

export type ExtendedUserCapabilities = UserCapabilities &
  Record<'hasCeGuidePage' | 'hasCustomLogin', boolean>

type TPersona =
  | {
      keys: string[]
      strategy: 'OR' | 'AND'
    }
  | {
      keys: string
      strategy: 'ONLY'
    }
let _user: User
let _theme: ExperienceData
let capabilities: ExtendedUserCapabilities

export const userSettingsService = {
  initiateUser: (user: User) => {
    _user = user
    capabilities = { ...capabilities, ..._user.experience.capabilities }
    // Please note the below shim is temporary until the new permissions/roles are distributed
    if (userSettingsService.hasRole(UserRole.CaisiqAdmin)) {
      const isSwitchUser = _user.switchedUser

      const roles = userSettingsService.isUsingLegacyRoles()
        ? (_user.roles as string[]) ?? []
        : _user.permissions ?? []

      if (isSwitchUser) {
        roles.push(UserRole.CaisiqManageRead)
      } else {
        roles.push(UserRole.CaisiqManageWrite, UserRole.CaisiqManageRead)
      }
    }
  },
  initiateFirm: (theme: ExperienceData) => {
    _theme = theme
    capabilities['hasCeGuidePage'] = _theme.ceGuidePage !== undefined
    capabilities['hasCustomLogin'] = _theme.loginScreen !== undefined
  },
  get user() {
    const fullName = _user?.fullname
    const [firstName, ...rest] = (fullName || '').split(' ') || []
    const lastName = rest.join(' ')
    return { ..._user, firstName, lastName }
  },
  getUserAsProfile: () => {
    if (!_user) {
      return null
    }
    const [firstName, ...rest] = _user.fullname.split(' ')
    const lastName = rest.join(' ')
    return {
      id: _user.sub,
      emailAddress: _user.email,
      firstName,
      lastName,
      firm: {
        id: '',
        name: '',
      },
      permissions: userSettingsService.permissions,
      features: [],
      personas: [],
      attributes: {},
      inheritedAttributes: [],
      capabilities: [],
      termsAndConditions: [],
      isSwitched: _user.switchedUser,
      allParentFirms: [],
    }
  },
  getUserRolesWithArchetypes: () => {
    const rolesOrPermissions = _user.roles || []
    if (rolesOrPermissions.length === 0) {
      return []
    }

    return userSettingsService.isUsingLegacyRoles()
      ? []
      : (rolesOrPermissions as Array<{
          name: string
          archetype: UserType
        }>)
  },
  isUsingLegacyRoles: () => {
    return _user?.roles && typeof _user.roles[0] === 'string'
  },
  hasCapability: (flag: keyof ExtendedUserCapabilities) => {
    return capabilities && capabilities[flag]
  },
  get permissions(): string[] {
    return userSettingsService.isUsingLegacyRoles()
      ? (_user?.roles as string[]) ?? []
      : _user?.permissions ?? []
  },
  get personas(): string[] {
    return _user?.personas ?? []
  },
  get companyName() {
    return _user.experience.name
  },
  get themeId() {
    return _theme.sys.id
  },
  get theme() {
    return _theme
  },
  getTheme: (key: keyof ExperienceData) => {
    return _theme[key]
  },
  hasRole: (key: TUserRole) => {
    return userSettingsService.permissions.includes(key) || false
  },
  hasPersona: ({ keys, strategy }: TPersona): boolean => {
    const userPersonas = userSettingsService.personas
    if (strategy === 'ONLY') {
      return userPersonas.length === 1 && userPersonas.includes(keys)
    }
    if (strategy === 'AND') {
      return keys.every((key) => userPersonas.includes(key))
    }
    return !!userPersonas?.some((persona) => keys.includes(persona))
  },
  generateTheme: (): Partial<DefaultTheme> => {
    // TODO: We should be able to remove setting this theme from Contentful once confirmed
    return {
      name: _theme.name,
      navHeaderWithHero: {
        backgroundColor: _theme.theme.navHeaderWithHero.backgroundColor,
        foregroundColor: _theme.theme.navHeaderWithHero.foregroundColor,
        underlineColor: _theme.theme.navHeaderWithHero.underlineColor,
        underlineOpacity: _theme.theme.navHeaderWithHero.underlineOpacity,
        caisIqLogo: _theme.theme.navHeaderWithHero.darkAppLogo
          ? 'dark'
          : 'light',
        clientLogoUrl:
          _theme.theme.navHeaderWithHero.clientLogo?.url || undefined,
        poweredBy: _theme.theme.navHeaderWithHero.poweredBy,
      },
      navHeaderWithIframe: {
        backgroundColor: _theme.theme.navHeaderWithIframe.backgroundColor,
        foregroundColor: _theme.theme.navHeaderWithIframe.foregroundColor,
        underlineColor: _theme.theme.navHeaderWithIframe.underlineColor,
        underlineOpacity: _theme.theme.navHeaderWithIframe.underlineOpacity,
        caisIqLogo: _theme.theme.navHeaderWithIframe.darkAppLogo
          ? 'dark'
          : 'light',
        clientLogoUrl:
          _theme.theme.navHeaderWithIframe.clientLogo?.url || undefined,
        poweredBy: _theme.theme.navHeaderWithIframe.poweredBy,
      },
      navHeaderForAdmin: {
        backgroundColor: _theme.theme.navHeaderForAdmin.backgroundColor,
        foregroundColor: _theme.theme.navHeaderForAdmin.foregroundColor,
        underlineColor: _theme.theme.navHeaderForAdmin.underlineColor,
        underlineOpacity: _theme.theme.navHeaderForAdmin.underlineOpacity,
        caisIqLogo: _theme.theme.navHeaderForAdmin.darkAppLogo
          ? 'dark'
          : 'light',
        clientLogoUrl:
          _theme.theme.navHeaderForAdmin.clientLogo?.url || undefined,
        poweredBy: _theme.theme.navHeaderForAdmin.poweredBy,
      },
    }
  },
}
