import { ButtonLink } from '@cais-group/equity/atoms/button'
import { isExternalLink } from '@cais-group/equity/atoms/link'
import { type Tracking } from '@cais-group/equity/util/tracking-utils'
import { ContentfulImage } from '@cais-group/shared/ui/contentful-image'

import { CardBase } from './card-base'
import { ExternalCardDescription } from './components/external-card-description'
import { DateTime } from './date-time'
import { FallbackImage } from './fallback-image'
import { getContentOwner, getPageType } from './helper'
import { OwnershipTag } from './ownership-tag'
import { TagAndTime } from './tag-and-time'
import type { CardProps } from './types'

export type FeaturedCardProps = CardProps &
  Tracking & {
    compact?: boolean
    dateTime?: string
    flip?: 'horizontal' | 'vertical'
  }

/**
 * The compact and flipped version was intended to be a new design of the featured card, but it was later decided to only use it in the 2-column layout
 */
export const CardFeatured = (props: FeaturedCardProps) => {
  const {
    callToAction,
    compact = false,
    date,
    description,
    externalContentDetails,
    flip,
    href,
    imageComponent,
    includeTime,
    thumbnail,
    timezone,
    title,
    type,
    testId,
    tracking,
    contentOwnershipTag,
  } = props
  const Image = imageComponent || ContentfulImage
  const withExternalContent = externalContentDetails && type

  return (
    <CardBase
      tracking={tracking}
      variant={compact ? 'featured-compact' : 'featured'}
      {...(testId && { testId: `card-${testId}` })}
    >
      <div
        className={`group space-y-8 ${
          flip === 'horizontal'
            ? 'basis-full lg:basis-3/5'
            : 'p-24 lg:basis-1/2'
        }`}
      >
        <hgroup className="flex flex-col gap-8">
          {compact && contentOwnershipTag?.name && (
            <OwnershipTag name={contentOwnershipTag.name} />
          )}
          <h2
            className={`group-hover:text-primary-700 line-clamp-2 ${
              compact ? 'headline-s-strong' : 'headline-l'
            }`}
          >
            {title}
          </h2>

          {compact ? (
            <DateTime
              date={date}
              includeTime={includeTime}
              timezone={timezone}
            />
          ) : (
            <TagAndTime
              contentOwnershipTag={contentOwnershipTag}
              date={date}
              includeTime={includeTime}
              timezone={timezone}
            />
          )}

          {description ? (
            <p
              className={`body line-clamp-3 text-neutral-600 ${
                withExternalContent ? 'mb-16' : ''
              } `}
            >
              {description}
            </p>
          ) : null}
          {withExternalContent ? (
            <ExternalCardDescription
              externalContentDetails={externalContentDetails}
              withVerticalBorder
            />
          ) : null}
          <div className={`${compact ? 'mt-16 lg:mt-24' : 'mt-24'}`}>
            <ButtonLink
              href={href}
              tracking={{
                click_type: 'Content',
                content_type: getPageType(props),
                content_owner: getContentOwner(props),
                item_name: title,
              }}
              {...(isExternalLink(href) && { endAdornment: 'Launch' })}
            >
              {callToAction}
            </ButtonLink>
          </div>
        </hgroup>
      </div>
      <div
        className={`relative order-first ${
          compact ? 'lg:basis-2/5' : 'basis-3/4 md:basis-2/3 lg:basis-1/2'
        } ${flip === 'horizontal' ? 'lg:order-last' : ''}`}
      >
        {thumbnail ? (
          <Image
            src={thumbnail.url}
            layout="fullWidth"
            loading="eager"
            alt={thumbnail.alt || thumbnail.title || ''}
          />
        ) : (
          <FallbackImage />
        )}
      </div>
    </CardBase>
  )
}
