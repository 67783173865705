import { Course } from '@cais-group/shared/util/type/caisiq-be'

import {
  AssessmentUnlocked,
  Learning,
  LearningComplete,
  Passed,
  Retry,
  Unstarted,
  LearningOrAssessing,
} from './type-course'

export const isCourse = (course: unknown): course is Course => {
  const c = course as Course

  switch (c.type) {
    case 'Unstarted':
      return isUnstarted(c)
    case 'Learning':
      return isLearning(c)
    case 'AssessmentUnlocked':
      return isAssessmentUnlocked(c)
    case 'Retry':
      return isRetry(c)
    case 'Passed':
      return isPassed(c)
    case 'LearningComplete':
      return isLearningComplete(c)
    case 'LearningOrAssessing':
      return isLearningOrAssessing(c)
    default:
    case undefined:
      return false
  }
}

export const isUnstarted = (course: unknown): course is Unstarted => {
  const c = course as Unstarted
  return isCommon(c) && c.type === 'Unstarted'
}

export const isLearning = (course: unknown): course is Learning => {
  const c = course as Learning
  return isCommon(c) && c.type === 'Learning' && c.progress !== undefined
}

export const isAssessmentUnlocked = (
  course: unknown
): course is AssessmentUnlocked => {
  const c = course as AssessmentUnlocked

  return (
    isCommon(c) && c.type === 'AssessmentUnlocked' && c.progress !== undefined
  )
}

export const isRetry = (course: unknown): course is Retry => {
  const c = course as Retry

  return (
    isCommon(c) &&
    c.type === 'Retry' &&
    c.progress !== undefined &&
    c.score !== undefined
  )
}

export const isPassed = (course: unknown): course is Passed => {
  const c = course as Passed

  return (
    isCommon(c) &&
    c.type === 'Passed' &&
    c.progress !== undefined &&
    c.score !== undefined
  )
}

export const isLearningComplete = (
  course: unknown
): course is LearningComplete => {
  const c = course as LearningComplete

  return (
    isCommon(c) && c.type === 'LearningComplete' && c.progress !== undefined
  )
}

export const isLearningOrAssessing = (
  course: unknown
): course is LearningOrAssessing => {
  const c = course as LearningOrAssessing

  return isCommon(c) && c.type === 'LearningOrAssessing'
}

const isCommon = (course: unknown): course is Course => {
  const c = course as Course

  return (
    c.id !== undefined && c.name !== undefined && c.description !== undefined
  )
}
