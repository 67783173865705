import { Footer } from '@cais-group/equity/molecules/footer'
import {
  PageErrorSplash,
  contentContainer,
} from '@cais-group/shared/ui/contentful/components'
import { ReactComponent as CAISLogo } from '@cais-group/shared/ui/main-nav'

export function NotFound() {
  return (
    <>
      <div
        className={`${contentContainer} bg-neutral-0 flex min-h-[calc(100vh-theme(constants.mainMenu.height)px)] flex-col`}
      >
        <nav className="min-h-88 py-32">
          <a href="/" aria-label="CAIS home">
            <CAISLogo />
          </a>
        </nav>
        <PageErrorSplash.NotFound />
      </div>
      <Footer />
    </>
  )
}
